import React, { useState, useContext } from 'react';

import {

  Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Snackbar, Alert

} from '@mui/material';

import { styled } from '@mui/material/styles';

import axios from 'axios';

import AuthContext from '../Auth/AuthContext';


const tealColor = '#3AAFA9';


const HeaderStyled = styled(Box)(({ theme }) => ({

  padding: theme.spacing(2),

  marginBottom: theme.spacing(2),

  display: 'flex',

  justifyContent: 'space-between',

  alignItems: 'center',

  backgroundColor: '#fff',

  boxShadow: theme.shadows[3],

}));


const CreateNewLead = ({ onClose, onSave }) => {

  const { user } = useContext(AuthContext);

  const [formState, setFormState] = useState({

    firstName: '',

    lastName: '',

    phone: '',

    email: '',

    state: '',

    source: '',

    loanAmount: '',

    propertyType: '',

    creditUtilization: '',

    ltv: '',

  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [snackbarSeverity, setSnackbarSeverity] = useState('success');


  const handleChange = (e) => {

    const { name, value } = e.target;

    setFormState({

      ...formState,

      [name]: value,

    });

  };


  const handleSubmit = async () => {

    try {

      await axios.post(`/api/leads/create-lead/${user._id}`, formState);

      setSnackbarMessage('Lead created successfully');

      setSnackbarSeverity('success');

      setSnackbarOpen(true);

      onSave(); // Callback to refresh leads list or close the dialog

    } catch (error) {

      console.error('Error creating lead:', error);

      setSnackbarMessage('Failed to create lead');

      setSnackbarSeverity('error');

      setSnackbarOpen(true);

    }

  };


  return (

    <Box>

      <HeaderStyled>

        <Typography variant="h4" sx={{ color: tealColor, textAlign: 'center' }}>

          Create New Lead

        </Typography>

      </HeaderStyled>

      <Box sx={{ padding: 2 }}>

        <TextField

          label="First Name"

          name="firstName"

          value={formState.firstName}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <TextField

          label="Last Name"

          name="lastName"

          value={formState.lastName}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <TextField

          label="Phone"

          name="phone"

          value={formState.phone}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <TextField

          label="Email"

          name="email"

          value={formState.email}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <TextField

          label="State"

          name="state"

          value={formState.state}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <FormControl fullWidth sx={{ marginBottom: 2 }}>

          <InputLabel>Source</InputLabel>

          <Select

            label="Source"

            name="source"

            value={formState.source}

            onChange={handleChange}

          >

            <MenuItem value="My Lead">My Lead</MenuItem>

            <MenuItem value="Inbound Call">Inbound Call</MenuItem>

            <MenuItem value="Other">Other</MenuItem>

          </Select>

        </FormControl>

        <TextField

          label="Loan Amount"

          name="loanAmount"

          value={formState.loanAmount}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <TextField

          label="Property Type"

          name="propertyType"

          value={formState.propertyType}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <TextField

          label="Credit Utilization"

          name="creditUtilization"

          value={formState.creditUtilization}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <TextField

          label="LTV"

          name="ltv"

          value={formState.ltv}

          onChange={handleChange}

          fullWidth

          sx={{ marginBottom: 2 }}

        />

        <Button

          variant="contained"

          color="primary"

          onClick={handleSubmit}

          sx={{ backgroundColor: tealColor }}

        >

          Create Lead

        </Button>

      </Box>

      <Snackbar

        open={snackbarOpen}

        autoHideDuration={6000}

        onClose={() => setSnackbarOpen(false)}

      >

        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>

          {snackbarMessage}

        </Alert>

      </Snackbar>

    </Box>

  );

};


export default CreateNewLead;

