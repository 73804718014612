import React, { useState, useEffect, useContext } from 'react';

import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';

import io from 'socket.io-client';

import axios from 'axios';

import AuthContext from '../Auth/AuthContext';

import Phone from './Phone';


const IncomingPhone = ({ setCurrentLead }) => {

  const { user } = useContext(AuthContext);

  const [socket, setSocket] = useState(null);

  const [incomingCall, setIncomingCall] = useState(null);

  const [showCallDialog, setShowCallDialog] = useState(false);

  const [lead, setLead] = useState(null);

  const [showPhoneDialer, setShowPhoneDialer] = useState(false);


  useEffect(() => {

    const newSocket = io('https://www.renewriff.com:5000');

    setSocket(newSocket);


    newSocket.on('connect', () => {

      console.log('Socket connected');

    });


    newSocket.on('incomingCall', async (callData) => {

      setIncomingCall(callData);

      setShowCallDialog(true);


      // Fetch the lead details if available

      try {

        if (callData.leadId) {

          const response = await axios.get(`/api/leads/${callData.leadId}`);

          setLead(response.data);

        } else {

          setLead(null); // Reset lead if no leadId is provided

        }

      } catch (error) {

        console.error('Error fetching lead details:', error);

        setLead(null); // Reset lead on error

      }

    });


    newSocket.on('disconnect', () => {

      console.log('Socket disconnected');

    });


    return () => newSocket.close();

  }, [user]);


  const handleAnswerCall = () => {

    setShowCallDialog(false);

    setShowPhoneDialer(true);

    if (lead) {

      setCurrentLead(lead);

    } else {

      console.log('Answering call without lead');

    }

  };


  const handleIgnoreCall = () => {

    setShowCallDialog(false);

    setIncomingCall(null);

    setLead(null);

  };


  const handleClosePhoneDialer = () => {

    setShowPhoneDialer(false);

  };


  return (

    <Box>

      <IconButton onClick={() => setShowPhoneDialer(true)} color="primary">

        <PhoneIcon />

      </IconButton>

      

      <Dialog

        open={showCallDialog}

        onClose={handleIgnoreCall}

        aria-labelledby="alert-dialog-title"

        aria-describedby="alert-dialog-description"

      >

        <DialogTitle id="alert-dialog-title">{"Incoming Call"}</DialogTitle>

        <DialogContent>

          <Typography variant="h6">Lead Name: {incomingCall?.leadName || 'Unknown'}</Typography>

          <Typography variant="body1">Phone: {incomingCall?.leadPhone}</Typography>

        </DialogContent>

        <DialogActions>

          <Button onClick={handleIgnoreCall} color="primary">

            Ignore

          </Button>

          <Button onClick={handleAnswerCall} color="primary" autoFocus>

            Answer

          </Button>

        </DialogActions>

      </Dialog>


      {showPhoneDialer && (

        <Dialog open={showPhoneDialer} onClose={handleClosePhoneDialer} fullWidth maxWidth="sm">

          <DialogTitle>Phone Dialer</DialogTitle>

          <Phone phoneNumber={incomingCall?.leadPhone} onCallCompleted={handleClosePhoneDialer} />

          <DialogActions>

            <Button onClick={handleClosePhoneDialer} color="primary">

              Close

            </Button>

          </DialogActions>

        </Dialog>

      )}

    </Box>

  );

};


export default IncomingPhone;

