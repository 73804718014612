import React from 'react';

import ReactDOM from 'react-dom/client'; // Correct import for React 18

import App from './App';

import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from './components/Auth/AuthContext';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';

import { GoogleOAuthProvider } from '@react-oauth/google'; 

import { SocketProvider } from './SocketContext'; 


const theme = createTheme();


const root = ReactDOM.createRoot(document.getElementById('root'));


const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;


root.render(

  <React.StrictMode>

    <GoogleOAuthProvider clientId={clientId}>

      <ThemeProvider theme={theme}>

        <CssBaseline />

        <Router>

          <AuthProvider>

            <SocketProvider>

              <App />

            </SocketProvider>

          </AuthProvider>

        </Router>

      </ThemeProvider>

    </GoogleOAuthProvider>

  </React.StrictMode>

);

