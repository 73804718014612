import React, { useState, useEffect } from 'react';

import {

  AppBar, Toolbar, Drawer, List, ListItem, ListItemText, CssBaseline, useMediaQuery, IconButton, Box

} from '@mui/material';

import { styled, useTheme } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';

import CallCenterMode from './CallCenterMode';

import Loading from './Loading';


const drawerWidth = 240;


const Root = styled('div')(({ theme }) => ({

  display: 'flex',

}));


const AppBarStyled = styled(AppBar)(({ theme }) => ({

  zIndex: theme.zIndex.drawer + 1,

}));


const DrawerStyled = styled(Drawer)(({ theme }) => ({

  [theme.breakpoints.up('sm')]: {

    width: drawerWidth,

    flexShrink: 0,

  },

}));


const DrawerContainer = styled('div')(({ theme }) => ({

  overflow: 'auto',

}));


const Content = styled('main')(({ theme }) => ({

  flexGrow: 1,

  padding: theme.spacing(3),

}));


const LoanOfficerDashboard = () => {

  const [view, setView] = useState('dashboard');

  const [loading, setLoading] = useState(true);

  const [userId, setUserId] = useState(null);

  const [mobileOpen, setMobileOpen] = useState(false);


  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleUserFetched = (id) => {

    setUserId(id);

    setLoading(false);

  };


  useEffect(() => {

    if (!userId) {

      setLoading(true);

    }

  }, [userId]);


  const handleDrawerToggle = () => {

    setMobileOpen(!mobileOpen);

  };


  const handleViewChange = (view) => {

    setView(view);

    if (isMobile) setMobileOpen(false);

  };


  const drawer = (

    <DrawerContainer>

      <List>

        <ListItem button onClick={() => handleViewChange('dashboard')}>

          <ListItemText primary="Dashboard" />

        </ListItem>

        <ListItem button onClick={() => handleViewChange('callCenterMode')}>

          <ListItemText primary="Call Center Mode" />

        </ListItem>

      </List>

    </DrawerContainer>

  );


  if (loading) {

    return <Loading checkWebSocket onUserFetched={handleUserFetched} />;

  }


  return (

    <Root>

      <CssBaseline />

      <AppBarStyled position="fixed">

        <Toolbar>

          {isMobile && (

            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>

              <MenuIcon />

            </IconButton>

          )}

          <h1>Loan Officer Dashboard</h1>

        </Toolbar>

      </AppBarStyled>

      <nav>

        {isMobile ? (

          <DrawerStyled

            variant="temporary"

            open={mobileOpen}

            onClose={handleDrawerToggle}

            ModalProps={{

              keepMounted: true,

            }}

          >

            {drawer}

          </DrawerStyled>

        ) : (

          <DrawerStyled variant="permanent" open>

            <Toolbar />

            {drawer}

          </DrawerStyled>

        )}

      </nav>

      <Content>

        <Toolbar />

        {view === 'dashboard' && <div>Dashboard Content</div>}

        {view === 'callCenterMode' && <CallCenterMode userId={userId} />}

      </Content>

      {isMobile && (

        <Box position="fixed" bottom={0} width="100%" bgcolor="background.paper" boxShadow={3}>

          <Toolbar>

            <IconButton color="inherit" onClick={() => handleViewChange('dashboard')}>

              <ListItemText primary="Dashboard" />

            </IconButton>

            <IconButton color="inherit" onClick={() => handleViewChange('callCenterMode')}>

              <ListItemText primary="Call Center Mode" />

            </IconButton>

          </Toolbar>

        </Box>

      )}

    </Root>

  );

};


export default LoanOfficerDashboard;

