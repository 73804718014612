import React, { useState } from 'react';

import { Box, TextField, Button, Typography } from '@mui/material';


const SignatureForm = ({ selectedDesign, onSubmit }) => {

  const [formData, setFormData] = useState({

    fullName: '',

    title: '',

    phone: '',

    email: '',

    website: '',

    address: '',

    nmls: '',

    secondPhone: '',

    secondEmail: '',

    specialRequests: '',

    picture: null,

  });


  const handleChange = (e) => {

    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

  };


  const handleFileChange = (e) => {

    setFormData((prevData) => ({ ...prevData, picture: e.target.files[0] }));

  };


  const handleSubmit = (e) => {

    e.preventDefault();

    onSubmit(formData);

  };


  return (

    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>

      <Typography variant="h6" gutterBottom>

        Enter Your Details

      </Typography>

      <TextField

        margin="normal"

        required

        fullWidth

        label="Full Name"

        name="fullName"

        value={formData.fullName}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        required

        fullWidth

        label="Title"

        name="title"

        value={formData.title}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        required

        fullWidth

        label="Phone"

        name="phone"

        value={formData.phone}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        required

        fullWidth

        label="Email"

        name="email"

        value={formData.email}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        required

        fullWidth

        label="Website"

        name="website"

        value={formData.website}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        required

        fullWidth

        label="Address"

        name="address"

        value={formData.address}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        required

        fullWidth

        label="NMLS"

        name="nmls"

        value={formData.nmls}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        fullWidth

        label="Second Phone"

        name="secondPhone"

        value={formData.secondPhone}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        fullWidth

        label="Second Email"

        name="secondEmail"

        value={formData.secondEmail}

        onChange={handleChange}

      />

      <TextField

        margin="normal"

        fullWidth

        label="Special Requests"

        name="specialRequests"

        value={formData.specialRequests}

        onChange={handleChange}

      />

      {selectedDesign.type.includes('pic') && (

        <TextField

          margin="normal"

          fullWidth

          type="file"

          label="Upload Picture"

          name="picture"

          onChange={handleFileChange}

          InputLabelProps={{ shrink: true }}

        />

      )}

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>

        Next

      </Button>

    </Box>

  );

};


export default SignatureForm;

