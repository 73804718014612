import React, { createContext, useContext, useEffect, useState } from 'react';

import io from 'socket.io-client';


const SocketContext = createContext();


export const useSocket = () => {

  return useContext(SocketContext);

};


export const SocketProvider = ({ children }) => {

  const [socket, setSocket] = useState(null);


  useEffect(() => {

    const newSocket = io('https://www.renewriff.com:5000/'); // Update with your backend URL

    setSocket(newSocket);


    return () => newSocket.close();

  }, []);


  return (

    <SocketContext.Provider value={socket}>

      {children}

    </SocketContext.Provider>

  );

};

