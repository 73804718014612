import React from 'react';

import { Box, Typography, Avatar } from '@mui/material';


const SignaturePreview = ({ formData, selectedDesign }) => {

  return (

    <Box sx={{ border: '1px solid #ddd', padding: 2, borderRadius: 1, textAlign: 'center' }}>

      {formData.picture && <Avatar src={URL.createObjectURL(formData.picture)} sx={{ width: 56, height: 56, margin: '0 auto' }} />}

      <Typography variant="h6">{formData.fullName}</Typography>

      <Typography variant="subtitle1">{formData.title}</Typography>

      <Typography>{formData.phone}</Typography>

      {formData.secondPhone && <Typography>{formData.secondPhone}</Typography>}

      <Typography>{formData.email}</Typography>

      {formData.secondEmail && <Typography>{formData.secondEmail}</Typography>}

      <Typography>{formData.website}</Typography>

      <Typography>{formData.address}</Typography>

      <Typography>NMLS: {formData.nmls}</Typography>

      {formData.specialRequests && <Typography variant="body2">Special Requests: {formData.specialRequests}</Typography>}

    </Box>

  );

};


export default SignaturePreview;

