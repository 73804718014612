import React, { useState, useEffect } from 'react';

import { Container, Typography, Button, Box, TextField, Paper } from '@mui/material';

import axios from 'axios';

import { useAuth } from '../Auth/AuthContext'; // Adjust the import path as necessary


const NewBranchForm = ({ onClose }) => {

    const { authStatus } = useAuth(); // Use the useAuth hook to access auth status


    const [formData, setFormData] = useState({

        branchName: '',

        branchAddress: '',

        branchAddress2: '', // Added branchAddress2

        branchCity: '', // Added branchCity

        branchState: '', // Added branchState

        branchZip: '', // Added branchZip

        branchPhone: '',

    });


    const [successMessage, setSuccessMessage] = useState('');


    useEffect(() => {

        if (!authStatus.isLoggedIn || authStatus.role !== 'Admin') {

            console.error('Unauthorized: Only Admin can create new branches');

        }

    }, [authStatus]);


    const handleChange = (e) => {

        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });

    };


    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const response = await axios.post('/api/branches', formData, { withCredentials: true });

            setSuccessMessage(`Branch created successfully. ID: ${response.data.branch._id}`);

            // Optionally reset form here or close form based on your UX choice

            // setFormData({ branchName: '', branchAddress: '', branchPhone: '' }); // Reset form

            if (onClose) onClose(); // Close form if onClose function is provided

        } catch (error) {

            console.error('Error creating branch:', error);

        }

    };


    if (!authStatus.isLoggedIn || authStatus.role !== 'Admin') {

        return <Typography variant="h6" align="center">Unauthorized</Typography>;

    }


    return (

        <Container component="main" maxWidth="xs">

            <Typography variant="h5" align="center" gutterBottom>Create New Branch</Typography>

            <Paper elevation={3}>

                <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>

                    <TextField

                        fullWidth

                        label="Branch Name"

                        name="branchName"

                        value={formData.branchName}

                        onChange={handleChange}

                        margin="normal"

                        required

                    />

                    <TextField

                        fullWidth

                        label="Branch Address"

                        name="branchAddress"

                        value={formData.branchAddress}

                        onChange={handleChange}

                        margin="normal"

                        required

                    />

                    <TextField

                        fullWidth

                        label="Branch Address 2"

                        name="branchAddress2"

                        value={formData.branchAddress2}

                        onChange={handleChange}

                        margin="normal"

                        required

                    />

                    <TextField

                        fullWidth

                        label="City"

                        name="branchCity"

                        value={formData.branchCity}

                        onChange={handleChange}

                        margin="normal"

                        required

                    />

                    <TextField

                        fullWidth

                        label="State"

                        name="branchState"

                        value={formData.branchState}

                        onChange={handleChange}

                        margin="normal"

                        required

                    />

                    <TextField

                        fullWidth

                        label="ZIP"

                        name="branchZip"

                        value={formData.branchZip}

                        onChange={handleChange}

                        margin="normal"

                        required

                    />

                    <TextField

                        fullWidth

                        label="Branch Phone"

                        name="branchPhone"

                        value={formData.branchPhone}

                        onChange={handleChange}

                        margin="normal"

                        required

                    />

                    <Button

                        type="submit"

                        variant="contained"

                        sx={{

                            mt: 2,

                            mb: 2,

                            backgroundColor: '#48acac',

                            '&:hover': {

                                backgroundColor: '#36a2a0',

                            }

                        }}

                    >

                        Create Branch

                    </Button>

                </Box>

            </Paper>

            {successMessage && (

                <Typography color="green" align="center" gutterBottom>

                    {successMessage}

                </Typography>

            )}

        </Container>

    );

};


export default NewBranchForm;

