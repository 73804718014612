import React, { useState, useEffect } from 'react';

import { Snackbar, Alert } from '@mui/material';

import io from 'socket.io-client';


const LeadAlert = ({ socket }) => {

  const [mrcLeadAlert, setMrcLeadAlert] = useState(false);


  useEffect(() => {

    if (socket) {

      socket.on('newMRCLead', () => {

        setMrcLeadAlert(true);

      });

    }


    return () => {

      if (socket) {

        socket.off('newMRCLead');

      }

    };

  }, [socket]);


  const handleClose = () => {

    setMrcLeadAlert(false);

  };


  return (

    <Snackbar open={mrcLeadAlert} autoHideDuration={6000} onClose={handleClose}>

      <Alert onClose={handleClose} severity="info">

        New MRC Lead received!

      </Alert>

    </Snackbar>

  );

};


export default LeadAlert;

