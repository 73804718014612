import React, { useState, useEffect } from 'react';

import {

  Box, Typography, Paper, Grid, Divider

} from '@mui/material';

import { teal } from '@mui/material/colors';

import PhoneIcon from '@mui/icons-material/Phone';

import EmailIcon from '@mui/icons-material/Email';

import HomeIcon from '@mui/icons-material/Home';

import MoneyIcon from '@mui/icons-material/Money';

import WorkIcon from '@mui/icons-material/Work';

import axios from 'axios';


const LeadDetails = ({ leadId }) => {

  const [details, setDetails] = useState({});


  useEffect(() => {

    const fetchLeadDetails = async () => {

      try {

        const response = await axios.get(`/api/leads/${leadId}/details`);

        setDetails(response.data);

      } catch (error) {

        console.error('Error fetching lead details:', error);

      }

    };


    if (leadId) {

      fetchLeadDetails();

    }

  }, [leadId]);


  const sectionStyle = {

    padding: 2,

    marginBottom: 2,

    boxShadow: 3,

  };


  const headerStyle = {

    marginBottom: 1,

    color: teal[700],

  };


  const fieldKeyStyle = {

    color: teal[700],

    fontWeight: 'bold',

  };


  const fieldValueStyle = {

    color: 'black',

  };


  return (

    <Box>

      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>

          <Paper sx={sectionStyle}>

            <Typography variant="h6" sx={headerStyle}>

              <PhoneIcon /> Contact Information

            </Typography>

            <Divider />

            <Typography variant="body1"><span style={fieldKeyStyle}>Phone:</span> <span style={fieldValueStyle}>{details.phone || details.phone_home || details.premium_phone}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Email:</span> <span style={fieldValueStyle}>{details.email || details.EMAIL}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Address:</span> <span style={fieldValueStyle}>{details.address || `${details.ADDRESS || ''} ${details.address2 || ''} ${details.city || details.CITY}, ${details.state || details.STATE} ${details.zip_code || details.ZIP}`}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Credit Score:</span> <span style={fieldValueStyle}>{details.credit_score || details.credit_rating}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Last Status Date:</span> <span style={fieldValueStyle}>{details.callAttempts && details.callAttempts.length > 0 ? new Date(details.callAttempts[details.callAttempts.length - 1].timestamp).toLocaleString() : 'N/A'}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Preferred Language:</span> <span style={fieldValueStyle}>{details.language || details.preferred_language}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Preferred Contact Method:</span> <span style={fieldValueStyle}>{details.preferred_contact_method}</span></Typography>

          </Paper>

        </Grid>

        <Grid item xs={12} md={6}>

          <Paper sx={sectionStyle}>

            <Typography variant="h6" sx={headerStyle}>

              <MoneyIcon /> Loan Information

            </Typography>

            <Divider />

            <Typography variant="body1"><span style={fieldKeyStyle}>Branch Assigned:</span> <span style={fieldValueStyle}>{details.branch_assigned}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Loan Number:</span> <span style={fieldValueStyle}>{details.loan_number}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Loan Status:</span> <span style={fieldValueStyle}>{details.loan_status}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Loan Application Status:</span> <span style={fieldValueStyle}>{details.loan_app_status}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Total Loan Amount:</span> <span style={fieldValueStyle}>${details.total_loan_amt}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Loan Amount:</span> <span style={fieldValueStyle}>${details.loan_amount}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Loan Type:</span> <span style={fieldValueStyle}>{details.loan_type}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Loan Term:</span> <span style={fieldValueStyle}>{details.loan_term}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Down Payment:</span> <span style={fieldValueStyle}>${details.down_payment}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Down Payment Percentage:</span> <span style={fieldValueStyle}>{details.down_payment_pct}%</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Cash Out Amount:</span> <span style={fieldValueStyle}>{details.cash_out_amt}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Current Rate:</span> <span style={fieldValueStyle}>{details.current_rate || details.rate}%</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>LTV:</span> <span style={fieldValueStyle}>{details.ltv}</span></Typography>

          </Paper>

        </Grid>

        <Grid item xs={12} md={6}>

          <Paper sx={sectionStyle}>

            <Typography variant="h6" sx={headerStyle}>

              <HomeIcon /> Property Information

            </Typography>

            <Divider />

            <Typography variant="body1"><span style={fieldKeyStyle}>Property Value:</span> <span style={fieldValueStyle}>${details.property_value}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Property Type:</span> <span style={fieldValueStyle}>{details.property_type}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Property Use:</span> <span style={fieldValueStyle}>{details.property_use}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Property Zip:</span> <span style={fieldValueStyle}>{details.property_zip}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Property City:</span> <span style={fieldValueStyle}>{details.property_city}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Property State:</span> <span style={fieldValueStyle}>{details.property_state}</span></Typography>

          </Paper>

        </Grid>

        <Grid item xs={12} md={6}>

          <Paper sx={sectionStyle}>

            <Typography variant="h6" sx={headerStyle}>

              <WorkIcon /> Employment Information

            </Typography>

            <Divider />

            <Typography variant="body1"><span style={fieldKeyStyle}>Employment Status:</span> <span style={fieldValueStyle}>{details.employment_info?.employment_status || details.employment_status}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Employment Type:</span> <span style={fieldValueStyle}>{details.employment_info?.employment_type}</span></Typography>

            <Typography variant="body1"><span style={fieldKeyStyle}>Monthly Income:</span> <span style={fieldValueStyle}>${details.employment_info?.monthly_income || details.gross_income}</span></Typography>

          </Paper>

        </Grid>

        <Grid item xs={12} md={6}>

          <Paper sx={sectionStyle}>

            <Typography variant="h6" sx={headerStyle}>

              <MoneyIcon /> Asset Information

            </Typography>

            <Divider />

            {details.assets && details.assets.map((asset, index) => (

              <Box key={index}>

                <Typography variant="body1"><span style={fieldKeyStyle}>Bank Name:</span> <span style={fieldValueStyle}>{asset.bank_name}</span></Typography>

                <Typography variant="body1"><span style={fieldKeyStyle}>Asset Type:</span> <span style={fieldValueStyle}>{asset.asset_type}</span></Typography>

                <Typography variant="body1"><span style={fieldKeyStyle}>Account Balance:</span> <span style={fieldValueStyle}>${asset.account_balance}</span></Typography>

                <Divider />

              </Box>

            ))}

          </Paper>

        </Grid>

      </Grid>

    </Box>

  );

};


export default LeadDetails;

