import React from 'react';

import IconButton from '@mui/material/IconButton';

// Importing placeholder icons

import HomeIcon from '@mui/icons-material/Home';

import SearchIcon from '@mui/icons-material/Search';

import NotificationsIcon from '@mui/icons-material/Notifications';

import SettingsIcon from '@mui/icons-material/Settings';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';


function FooterMenu() {

  return (

    <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '20px 0' }}>

      <IconButton aria-label="home" size="large" style={{ color: '#46A9AF' }}>

        <HomeIcon fontSize="large" />

      </IconButton>

      <IconButton aria-label="search" size="large" style={{ color: '#46A9AF' }}>

        <SearchIcon fontSize="large" />

      </IconButton>

      <IconButton aria-label="notifications" size="large" style={{ color: '#46A9AF' }}>

        <NotificationsIcon fontSize="large" />

      </IconButton>

      <IconButton aria-label="settings" size="large" style={{ color: '#46A9AF' }}>

        <SettingsIcon fontSize="large" />

      </IconButton>

      <IconButton aria-label="account" size="large" style={{ color: '#46A9AF' }}>

        <AccountCircleIcon fontSize="large" />

      </IconButton>

    </div>

  );

}


export default FooterMenu;

