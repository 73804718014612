// src/components/LeftLoanPanel.jsx

import React from 'react';


function LeftLoanPanel() {

  return (

    <div className="left-loan-panel">

      {/* Display loan info here */}

      <p>Loan Summary</p>

      {/* Add loan details */}

    </div>

  );

}


export default LeftLoanPanel;

