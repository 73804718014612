import React, { useState } from 'react';

import {

  Box, Typography, IconButton, Tooltip, Paper, Dialog, DialogActions, DialogContent,

  DialogContentText, DialogTitle, Snackbar, Alert, Button, Avatar

} from '@mui/material';

import { styled } from '@mui/material/styles';

import PhoneIcon from '@mui/icons-material/Phone';

import SmsIcon from '@mui/icons-material/Sms';

import EmailIcon from '@mui/icons-material/Email';

import AssignmentIcon from '@mui/icons-material/Assignment';

import InfoIcon from '@mui/icons-material/Info';

import CloseIcon from '@mui/icons-material/Close';

import StatusIcon from '@mui/icons-material/AssignmentTurnedIn';

import CreditScoreIcon from '@mui/icons-material/CreditScore';

import LocationOnIcon from '@mui/icons-material/LocationOn';

import CallIcon from '@mui/icons-material/Call';

import PhoneWindow from './PhoneWindow';

import EmailForm from './EmailForm';

import Calendar from './Calendar';

import DispositionHistory from './DispositionHistory';

import LeadDetails from './LeadDetails';

import SmsForm from './SMSForm';

import CreateNewLead from './CreateNewLead';

import axios from 'axios';


const tealColor = '#3AAFA9';


const HeaderStyled = styled(Paper)(({ theme }) => ({

  padding: theme.spacing(3),

  marginBottom: theme.spacing(3),

  display: 'flex',

  justifyContent: 'space-between',

  alignItems: 'center',

  backgroundColor: '#fff',

  boxShadow: theme.shadows[3],

}));


const NavigationButton = styled(IconButton)(({ theme }) => ({

  margin: theme.spacing(1),

  '&.active': {

    backgroundColor: tealColor,

    color: '#fff',

  },

}));


const ProfileBox = styled(Box)(({ theme }) => ({

  display: 'flex',

  alignItems: 'center',

  marginRight: theme.spacing(2),

}));


const MetricsBox = styled(Box)(({ theme }) => ({

  display: 'flex',

  alignItems: 'center',

  justifyContent: 'space-between',

  width: '100%',

  padding: theme.spacing(2),

}));


const Metric = styled(Box)(({ theme }) => ({

  display: 'flex',

  flexDirection: 'column',

  alignItems: 'center',

  justifyContent: 'center',

  margin: theme.spacing(1),

}));


const formatPhoneNumber = (phoneNumber) => {

  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {

    return `${match[1]}-${match[2]}-${match[3]}`;

  }

  return phoneNumber;

};


const ContactLead = ({ lead, onClose, activeTab, setActiveTab, isCreating }) => {

  const [dncDialogOpen, setDncDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [openEmailForm, setOpenEmailForm] = useState(false);


  const getLastDisposition = (callAttempts) => {

    if (callAttempts && callAttempts.length > 0) {

      const sortedAttempts = callAttempts.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      return sortedAttempts[0].disposition;

    }

    return 'New Lead';

  };


  const renderContent = () => {

    if (isCreating) {

      return <CreateNewLead onClose={onClose} onSave={() => {

        onClose();

        setSnackbarMessage('Lead created successfully');

        setSnackbarSeverity('success');

        setSnackbarOpen(true);

      }} />;

    }


    switch (activeTab) {

      case 'phone':

        return <PhoneWindow lead={lead} phoneNumber={lead.premium_phone || lead.phone_home || lead.phone_work || lead.phone_cell || lead.phone || ""} />;

      case 'email':

        return <EmailForm open={openEmailForm} handleClose={() => setOpenEmailForm(false)} leadId={lead._id} />;

      case 'sms':

        return <SmsForm lead={lead} />;

      case 'calendar':

        return <Calendar lead={lead} />;

      case 'disposition':

        return <DispositionHistory leadId={lead._id} />;

      case 'details':

        return <LeadDetails leadId={lead._id} />;

      default:

        return null;

    }

  };


  const handleDncToggle = async () => {

    setDncDialogOpen(false);

    try {

      await axios.post('/api/leads/add-dnc-list', { phoneNumber: lead.premium_phone || lead.phone_home || lead.phone_work || lead.phone_cell || lead.phone });

      setSnackbarMessage('Phone number added to DNC list successfully');

      setSnackbarSeverity('success');

      setSnackbarOpen(true);

    } catch (error) {

      console.error('Error adding phone number to DNC list:', error);

      setSnackbarMessage('Failed to add phone number to DNC list');

      setSnackbarSeverity('error');

      setSnackbarOpen(true);

    }

  };


  return (

    <Box>

      <HeaderStyled>

        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

          <Tooltip title="Phone">

            <NavigationButton

              onClick={() => setActiveTab('phone')}

              className={activeTab === 'phone' ? 'active' : ''}

              sx={{ fontSize: 30 }}

            >

              <PhoneIcon sx={{ fontSize: 30 }} />

            </NavigationButton>

          </Tooltip>

          <Tooltip title="Email">

            <NavigationButton

              onClick={() => {

                setOpenEmailForm(true);

                setActiveTab('email');

              }}

              className={activeTab === 'email' ? 'active' : ''}

              sx={{ fontSize: 30 }}

            >

              <EmailIcon sx={{ fontSize: 30 }} />

            </NavigationButton>

          </Tooltip>

          <Tooltip title="SMS">

            <NavigationButton

              onClick={() => setActiveTab('sms')}

              className={activeTab === 'sms' ? 'active' : ''}

              sx={{ fontSize: 30 }}

            >

              <SmsIcon sx={{ fontSize: 30 }} />

            </NavigationButton>

          </Tooltip>

          <Tooltip title="Disposition">

            <NavigationButton

              onClick={() => setActiveTab('disposition')}

              className={activeTab === 'disposition' ? 'active' : ''}

              sx={{ fontSize: 30 }}

            >

              <AssignmentIcon sx={{ fontSize: 30 }} />

            </NavigationButton>

          </Tooltip>

          <Tooltip title="Details">

            <NavigationButton

              onClick={() => setActiveTab('details')}

              className={activeTab === 'details' ? 'active' : ''}

              sx={{ fontSize: 30 }}

            >

              <InfoIcon sx={{ fontSize: 30 }} />

            </NavigationButton>

          </Tooltip>

          <Tooltip title="Set to DNC">

            <NavigationButton

              onClick={() => setDncDialogOpen(true)}

              sx={{ color: 'red', fontSize: 30 }}

              className={activeTab === 'dnc' ? 'active' : ''}

            >

              <AssignmentIcon sx={{ fontSize: 30 }} />

            </NavigationButton>

          </Tooltip>

          <Tooltip title="Close">

            <NavigationButton

              onClick={onClose}

              sx={{ color: tealColor, fontSize: 30 }}

            >

              <CloseIcon sx={{ fontSize: 30 }} />

            </NavigationButton>

          </Tooltip>

        </Box>

      </HeaderStyled>


      <HeaderStyled>

        <Box sx={{ textAlign: 'center', width: '100%' }}>

          <Typography variant="h3" sx={{ color: tealColor, fontSize: '2.5rem', fontWeight: 'bold' }}>

            {isCreating ? 'Create New Lead' : `${lead.FNAME || lead.first_name} ${lead.LNAME || lead.last_name}`}

          </Typography>

        </Box>

      </HeaderStyled>


      <MetricsBox>

        <Metric>

          <StatusIcon sx={{ fontSize: 50, color: tealColor }} />

          <Typography variant="h6" sx={{ color: tealColor, fontSize: '1.2rem' }}>Last Status</Typography>

          <Typography variant="body1" sx={{ fontSize: '1rem' }}>{getLastDisposition(lead.callAttempts)}</Typography>

        </Metric>

        <Metric>

          <CreditScoreIcon sx={{ fontSize: 50, color: tealColor }} />

          <Typography variant="h6" sx={{ color: tealColor, fontSize: '1.2rem' }}>Credit Score</Typography>

          <Typography variant="body1" sx={{ fontSize: '1rem' }}>{lead.credit_score}</Typography>

        </Metric>

        <Metric>

          <LocationOnIcon sx={{ fontSize: 50, color: tealColor }} />

          <Typography variant="h6" sx={{ color: tealColor, fontSize: '1.2rem' }}>State</Typography>

          <Typography variant="body1" sx={{ fontSize: '1rem' }}>{lead.STATE || lead.state}</Typography>

        </Metric>

        <Metric>

          <CallIcon sx={{ fontSize: 50, color: tealColor }} />

          <Typography variant="h6" sx={{ color: tealColor, fontSize: '1.2rem' }}>Phone</Typography>

          <Typography 

            variant="body1" 

            sx={{ fontSize: '1rem', color: tealColor, textDecoration: 'underline', cursor: 'pointer' }}

            onClick={() => setActiveTab('phone')}

          >

            {formatPhoneNumber(lead.premium_phone || lead.phone_home || lead.phone_work || lead.phone_cell || lead.phone)}

          </Typography>

        </Metric>

      </MetricsBox>


      <Box>

        {renderContent()}

      </Box>


      <Dialog

        open={dncDialogOpen}

        onClose={() => setDncDialogOpen(false)}

        aria-labelledby="alert-dialog-title"

        aria-describedby="alert-dialog-description"

      >

        <DialogTitle id="alert-dialog-title">{"Set to DNC"}</DialogTitle>

        <DialogContent>

          <DialogContentText id="alert-dialog-description">

            Are you sure you want to set this user to DNC? This action cannot be undone.

          </DialogContentText>

        </DialogContent>

        <DialogActions>

          <Button onClick={() => setDncDialogOpen(false)} color="primary">

            Cancel

          </Button>

          <Button onClick={handleDncToggle} color="primary" autoFocus>

            Confirm

          </Button>

        </DialogActions>

      </Dialog>


      <Snackbar

        open={snackbarOpen}

        autoHideDuration={6000}

        onClose={() => setSnackbarOpen(false)}

      >

        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>

          {snackbarMessage}

        </Alert>

      </Snackbar>

    </Box>

  );

};


export default ContactLead;

