import React from 'react';

import { Box, Grid, Paper, Typography } from '@mui/material';

import jaySignature2 from './images/Jay-Signature-2-Copy.png';

import jaySignature1 from './images/Jay-Signature-1-Copy.png';

import signature1 from './images/Signature-1-Copy.png';

import jayBasicSignature from './images/Jay-Basic-Email-Signature-Copy.png';

import jayAvatarSignature from './images/Jay-Avatar-Email-Signature-Copy.png';

import jahairaSignature from './images/Jahaira-Contreras-Email-Signature-500.jpg';


const signatureDesigns = [

  { id: 1, src: jaySignature2, type: 'white no pic' },

  { id: 2, src: jaySignature1, type: 'Avatar white' },

  { id: 3, src: signature1, type: 'white pic' },

  { id: 4, src: jayBasicSignature, type: 'dark no pic' },

  { id: 5, src: jayAvatarSignature, type: 'Avatar dark' },

  { id: 6, src: jahairaSignature, type: 'dark pic' },

];


const SignatureSelection = ({ onSelect }) => {

  return (

    <Box>

      <Typography variant="h6" gutterBottom>

        Select a Signature Design

      </Typography>

      <Grid container spacing={2}>

        {signatureDesigns.map((design) => (

          <Grid item xs={12} sm={6} md={4} key={design.id}>

            <Paper onClick={() => onSelect(design)} sx={{ cursor: 'pointer', padding: 2, textAlign: 'center' }}>

              <img src={design.src} alt={design.type} style={{ width: '100%', height: 'auto' }} onError={(e) => (e.target.src = 'https://via.placeholder.com/150')} />

              <Typography variant="body2" sx={{ mt: 1 }}>

                {design.type}

              </Typography>

            </Paper>

          </Grid>

        ))}

      </Grid>

    </Box>

  );

};


export default SignatureSelection;

