import React, { useState, useEffect } from 'react';

import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, IconButton } from '@mui/material';

import io from 'socket.io-client';

import AttachFileIcon from '@mui/icons-material/AttachFile';


const EmailForm = ({ open, handleClose, leadId }) => {

  const [to, setEmail] = useState('');

  const [subject, setSubject] = useState('');

  const [message, setMessage] = useState('');

  const [cc, setCc] = useState('');

  const [bcc, setBcc] = useState('');

  const [attachments, setAttachments] = useState([]);

  const [loading, setLoading] = useState(false);

  const [socket, setSocket] = useState(null);


  useEffect(() => {

    const newSocket = io('https://www.renewriff.com:5000'); // Correct backend URL

    setSocket(newSocket);

    return () => newSocket.close();

  }, []);


  const handleAttachmentChange = (event) => {

    setAttachments([...attachments, ...event.target.files]);

  };


  const handleSubmit = (e) => {

    e.preventDefault();

    setLoading(true);


    const data = {

      leadId,

      to,

      subject,

      body: message,

      cc,

      bcc,

      attachments: attachments.map(file => ({ filename: file.name, path: file })),

      useGoogle: true,

    };


    console.log('Sending email data:', data);


    if (socket) {

      socket.emit('sendEmail', data, (response) => {

        setLoading(false);

        if (response.success) {

          alert('Email sent successfully!');

          handleClose();

        } else {

          alert('Failed to send email: ' + response.message);

          console.error('Email sending error:', response);

        }

      });

    } else {

      console.error('Socket is not connected');

    }

  };


  return (

    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

      <DialogTitle id="form-dialog-title">Send Test Email</DialogTitle>

      <DialogContent>

        <TextField

          autoFocus

          margin="dense"

          label="To Email Address"

          type="email"

          fullWidth

          value={to}

          onChange={(e) => setEmail(e.target.value)}

        />

        <TextField

          margin="dense"

          label="Cc"

          type="text"

          fullWidth

          value={cc}

          onChange={(e) => setCc(e.target.value)}

        />

        <TextField

          margin="dense"

          label="Bcc"

          type="text"

          fullWidth

          value={bcc}

          onChange={(e) => setBcc(e.target.value)}

        />

        <TextField

          margin="dense"

          label="Subject"

          type="text"

          fullWidth

          value={subject}

          onChange={(e) => setSubject(e.target.value)}

        />

        <TextField

          margin="dense"

          label="Message"

          type="text"

          multiline

          rows={4}

          fullWidth

          value={message}

          onChange={(e) => setMessage(e.target.value)}

        />

        <input

          type="file"

          multiple

          onChange={handleAttachmentChange}

          id="attachment-input"

          style={{ display: 'none' }}

        />

        <label htmlFor="attachment-input">

          <IconButton component="span">

            <AttachFileIcon />

          </IconButton>

        </label>

      </DialogContent>

      <DialogActions>

        <Button onClick={handleClose} color="primary">

          Cancel

        </Button>

        <Button onClick={handleSubmit} color="primary" disabled={loading}>

          {loading ? <CircularProgress size={24} /> : 'Send Email'}

        </Button>

      </DialogActions>

    </Dialog>

  );

};


export default EmailForm;

