import React from 'react';

import IconButton from '@mui/material/IconButton';

import SettingsIcon from '@mui/icons-material/Settings';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import HelpIcon from '@mui/icons-material/Help';


function ThinLeftMenuBottom() {

  return (

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <IconButton aria-label="settings" style={{ color: '#46A9AF' }}>

        <SettingsIcon />

      </IconButton>

      <IconButton aria-label="account" style={{ color: '#46A9AF' }}>

        <AccountCircleIcon />

      </IconButton>

      <IconButton aria-label="help" style={{ color: '#46A9AF' }}>

        <HelpIcon />

      </IconButton>

    </div>

  );

}


export default ThinLeftMenuBottom;

