// LoanApp.jsx

import React from 'react';

import { Routes, Route } from 'react-router-dom';

import ContactLoanApp from './ContactLoanApp';

import LoanAndPropertyInfo from './LoanAndPropertyInfoForm';

import FinalScreen from './FinalScreen';


function LoanApp() {

    return (

        <Routes>

            <Route path="/" element={<ContactLoanApp />} />

            <Route path="/loan-and-property-info" element={<LoanAndPropertyInfo />} />

            <Route path="/final-screen" element={<FinalScreen />} />

        </Routes>

    );

}


export default LoanApp;

