import React, { useState, useEffect, useRef } from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import {

  ArrowBack as ArrowBackIcon,

  ArrowForward as ArrowForwardIcon,

  CheckCircle as CheckCircleIcon,

  Person as PersonIcon,

  House as HouseIcon,

  Description as DescriptionIcon,

} from '@mui/icons-material';


const stepsConfig = [

  { label: 'Select Design', icon: <PersonIcon /> },

  { label: 'Enter Details', icon: <HouseIcon /> },

  { label: 'Preview', icon: <DescriptionIcon /> },

];


const NavigationPanel = ({ currentStep, completedSteps, onNavigate }) => {

  const [navIndex, setNavIndex] = useState(0);

  const touchStartXRef = useRef(null);


  useEffect(() => {

    const halfVisibleSteps = Math.floor(5 / 2);

    let visibleIndex = Math.max(0, currentStep - halfVisibleSteps - 1);

    if (visibleIndex + 5 > stepsConfig.length) {

      visibleIndex = stepsConfig.length - 5;

    }

    setNavIndex(visibleIndex);

  }, [currentStep]);


  const visibleSteps = stepsConfig.slice(navIndex, navIndex + 5);


  const handleScrollLeft = () => {

    if (navIndex > 0) setNavIndex(navIndex - 1);

  };


  const handleScrollRight = () => {

    if (navIndex < stepsConfig.length - 5) setNavIndex(navIndex + 1);

  };


  const handleTouchStart = (e) => {

    touchStartXRef.current = e.touches[0].clientX;

  };


  const handleTouchMove = (e) => {

    const touchMoveX = e.touches[0].clientX;

    const touchStartX = touchStartXRef.current;

    if (touchStartX && touchMoveX) {

      const diffX = touchMoveX - touchStartX;

      if (diffX > 50) {

        handleScrollLeft();

      } else if (diffX < -50) {

        handleScrollRight();

      }

    }

  };


  return (

    <Box

      sx={{

        display: 'flex',

        alignItems: 'center',

        mt: 2,

        height: '100px',

        background: '#ffffff',

        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',

        borderRadius: '12px',

        overflow: 'hidden',

        transition: 'all 0.3s ease',

      }}

      onTouchStart={handleTouchStart}

      onTouchMove={handleTouchMove}

    >

      <IconButton onClick={handleScrollLeft} disabled={navIndex === 0}>

        <ArrowBackIcon sx={{ color: '#47a9af' }} />

      </IconButton>

      <Box

        sx={{

          display: 'flex',

          overflowX: 'hidden',

          scrollbarWidth: 'none',

          '&::-webkit-scrollbar': { display: 'none' },

          transition: 'all 0.3s ease',

        }}

      >

        {visibleSteps.map((step, index) => {

          const isCompleted = completedSteps.includes(navIndex + index + 1);

          const icon = isCompleted ? <CheckCircleIcon /> : step.icon;

          const color = currentStep === navIndex + index + 1 ? '#47a9af' : isCompleted ? 'green' : 'grey';

          return (

            <Box key={index} sx={{ textAlign: 'center', mx: 1 }}>

              <IconButton onClick={() => onNavigate(navIndex + index + 1)} sx={{ color, fontSize: '1.5rem' }}>

                {icon}

              </IconButton>

              <Typography variant="caption" color={color} sx={{ fontSize: '0.9rem' }}>

                {step.label}

              </Typography>

            </Box>

          );

        })}

      </Box>

      <IconButton onClick={handleScrollRight} disabled={navIndex >= stepsConfig.length - 5}>

        <ArrowForwardIcon sx={{ color: '#47a9af' }} />

      </IconButton>

    </Box>

  );

};


export default NavigationPanel;

