import React from 'react';

import './ThinLeftMenu.css'; // Ensure you have this for styling

import ThinLeftMenuTop from './ThinLeftMenuTop';

import ThinLeftMenuBottom from './ThinLeftMenuBottom';


function ThinLeftMenu({ isOpen, toggleMenu }) {

  return (

    <div className={`thin-left-menu ${isOpen ? 'open' : 'closed'}`} style={{ backgroundColor: '#333333' }}>

      <div className="menu-toggle" onClick={toggleMenu}>

        ☰

      </div>

      {isOpen && (

        <div className="menu-content">

          <ThinLeftMenuTop />

          <ThinLeftMenuBottom />

        </div>

      )}

    </div>

  );

}


export default ThinLeftMenu;

