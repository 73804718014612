import React, { createContext, useContext, useState, useEffect } from 'react';

import api from '../../api'; // Import the Axios instance

import { useGoogleLogin, googleLogout } from '@react-oauth/google';

import axios from 'axios';


const AuthContext = createContext();


export const useAuth = () => useContext(AuthContext);


export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);

  const [authError, setAuthError] = useState('');

  const [authStatus, setAuthStatus] = useState({ isLoggedIn: false, role: null });

  const [googleToken, setGoogleToken] = useState(null);


  useEffect(() => {

    // Load auth status from local storage

    const storedAuthStatus = JSON.parse(localStorage.getItem('authStatus'));

    if (storedAuthStatus) {

      setAuthStatus(storedAuthStatus);

      setUser(JSON.parse(localStorage.getItem('user')));

    } else {

      checkAuthStatus();

    }

  }, []);


  const checkAuthStatus = async () => {

    try {

      const response = await api.get('/auth/status');

      if (response.data.isAuthenticated) {

        setUser(response.data.user);

        setAuthStatus({ isLoggedIn: true, role: response.data.user.role });

        localStorage.setItem('authStatus', JSON.stringify({ isLoggedIn: true, role: response.data.user.role }));

        localStorage.setItem('user', JSON.stringify(response.data.user));

        setAuthError('');

      } else {

        throw new Error('Not authenticated');

      }

    } catch (error) {

      setUser(null);

      setAuthStatus({ isLoggedIn: false, role: null });

      localStorage.removeItem('authStatus');

      localStorage.removeItem('user');

      setAuthError('Please log in.');

    }

  };


  const login = async (username, password) => {

    try {

      const response = await api.post('/auth/login', { username, password });

      if (response.data.user) {

        await checkAuthStatus();

        setAuthError('');

      } else {

        setAuthError('Login failed. Please try again.');

      }

    } catch (error) {

      setAuthError(error.response?.data?.message || 'An error occurred during login.');

    }

  };


  const signup = async (firstName, lastName, email, cellphone) => {

    try {

      const response = await api.post('/auth/signup', { firstName, lastName, email, cellphone });

      if (response.data.user) {

        await checkAuthStatus();

        setAuthError('');

      } else {

        setAuthError('Signup failed. Please try again.');

      }

    } catch (error) {

      setAuthError(error.response?.data?.message || 'An error occurred during signup.');

    }

  };


  const logout = async () => {

    try {

      await api.get('/auth/logout');

      setUser(null);

      setAuthStatus({ isLoggedIn: false, role: null });

      googleLogout();

      localStorage.removeItem('authStatus');

      localStorage.removeItem('user');

    } catch (error) {

      console.error('Logout failed', error);

    }

  };


  const googleLogin = useGoogleLogin({

    onSuccess: async (response) => {

      setGoogleToken(response.access_token);

      try {

        const res = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {

          headers: {

            Authorization: `Bearer ${response.access_token}`,

          },

        });

        const user = res.data;

        setUser(user);

        setAuthStatus({ isLoggedIn: true, role: 'user' });

        localStorage.setItem('authStatus', JSON.stringify({ isLoggedIn: true, role: 'user' }));

        localStorage.setItem('user', JSON.stringify(user));

      } catch (error) {

        console.error('Google login failed:', error);

      }

    },

    onFailure: (error) => {

      console.error('Google login failed:', error);

    },

  });


  return (

    <AuthContext.Provider value={{

      user,

      login,

      signup,

      logout,

      googleLogin,

      googleToken,

      checkAuthStatus,

      authError,

      authStatus,

    }}>

      {children}

    </AuthContext.Provider>

  );

};


export default AuthContext;

