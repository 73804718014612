import React, { useState } from 'react';

import { Box, TextField, Button, Typography } from '@mui/material';

import axios from 'axios';


const SmsForm = ({ lead }) => {

  const [message, setMessage] = useState('');

  const [sending, setSending] = useState(false);

  const [error, setError] = useState(null);

  const [success, setSuccess] = useState(false);


  const handleSendSms = async () => {

    setSending(true);

    setError(null);

    setSuccess(false);


    try {

      const response = await axios.post('/api/call/sms', {

        to: lead.premium_phone || lead.phone_home,

        body: message, // Use 'body' instead of 'message'

      });


      if (response.data.success) {

        setSuccess(true);

      } else {

        setError('Failed to send SMS');

      }

    } catch (error) {

      setError('An error occurred while sending SMS');

    }


    setSending(false);

  };


  return (

    <Box>

      <Typography variant="h6">Send SMS</Typography>

      <TextField

        label="Message"

        multiline

        rows={4}

        value={message}

        onChange={(e) => setMessage(e.target.value)}

        fullWidth

        margin="normal"

      />

      <Button

        variant="contained"

        color="primary"

        onClick={handleSendSms}

        disabled={sending}

      >

        {sending ? 'Sending...' : 'Send SMS'}

      </Button>

      {error && <Typography color="error">{error}</Typography>}

      {success && <Typography color="primary">SMS sent successfully!</Typography>}

    </Box>

  );

};


export default SmsForm;

