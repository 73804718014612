import React, { useState } from 'react';

import Header from './Header';

import Footer from './Footer';

import ThinLeftMenu from './ThinLeftMenu';

import LeftLoanPanel from './LeftLoanPanel';

import RightMainView from './RightMainView';

import './AdminDashboard.css';


function AdminDashboard() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const toggleMenu = () => {

    setIsMenuOpen(!isMenuOpen);

  };


  return (

    <div className="admin-dashboard">

      <Header />

      <div className="dashboard-body">

        <ThinLeftMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />

        <LeftLoanPanel isMenuOpen={isMenuOpen} />

        <RightMainView isMenuOpen={isMenuOpen} />

      </div>

      <Footer />

    </div>

  );

}


export default AdminDashboard;

