import React, { useEffect, useState } from 'react';

import { CircularProgress, Box, Typography } from '@mui/material';

import io from 'socket.io-client';

import { useAuth } from '../Auth/AuthContext';


const Loading = ({ checkWebSocket, onUserFetched }) => {

  const { authStatus, user, checkAuthStatus } = useAuth();

  const [socketConnected, setSocketConnected] = useState(false);

  const [userLoading, setUserLoading] = useState(true);


  useEffect(() => {

    const fetchUser = async () => {

      await checkAuthStatus();

      if (user) {

        onUserFetched(user._id);

        setUserLoading(false);

      } else {

        console.error('User not found after checkAuthStatus');

        setUserLoading(false);

      }

    };


    fetchUser();

  }, [authStatus.isLoggedIn, user, onUserFetched, checkAuthStatus]);


  useEffect(() => {

    if (checkWebSocket) {

      const socket = io('https://www.renewriff.com:5000');

      socket.on('connect', () => {

        setSocketConnected(true);

      });

      socket.on('disconnect', () => {

        setSocketConnected(false);

      });


      return () => socket.close();

    }

  }, [checkWebSocket]);


  if (userLoading) {

    return (

      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">

        <CircularProgress />

        <Typography variant="h6" style={{ marginTop: 16 }}>

          Fetching user data...

        </Typography>

      </Box>

    );

  }


  return (

    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">

      <CircularProgress />

      <Typography variant="h6" style={{ marginTop: 16 }}>

        {checkWebSocket

          ? socketConnected

            ? 'Connected to the server...'

            : 'Connecting to the server...'

          : 'Loading...'}

      </Typography>

    </Box>

  );

};


export default Loading;

