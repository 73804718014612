import React, { useState, useEffect } from 'react';

import {

  Container, Typography, Button, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,

  MenuItem, Select, FormControl, InputLabel, Snackbar, Checkbox, TextField, Autocomplete, TableSortLabel,

  TablePagination

} from '@mui/material';

import axios from 'axios';

import { useAuth } from '../Auth/AuthContext';

import * as XLSX from 'xlsx';


const LeadManagement = () => {

  const { authStatus } = useAuth();


  const [batches, setBatches] = useState([]);

  const [mrcLeads, setMrcLeads] = useState([]);

  const [assignedLeads, setAssignedLeads] = useState([]);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [leadSource, setLeadSource] = useState('');

  const [leadSources, setLeadSources] = useState([]);

  const [users, setUsers] = useState([]);

  const [selectedUser, setSelectedUser] = useState('');

  const [selectedLeads, setSelectedLeads] = useState(new Set());

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [view, setView] = useState('batches');

  const [orderBy, setOrderBy] = useState('first_name');

  const [order, setOrder] = useState('asc');

  const [assignedDateFilter, setAssignedDateFilter] = useState('');

  const [file, setFile] = useState(null);

  const [leads, setLeads] = useState([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);


  useEffect(() => {

    if (authStatus.isLoggedIn && authStatus.role === 'Admin') {

      fetchBatches();

      fetchLeadSources();

      fetchUsers();

    } else {

      setErrorMessage('Unauthorized: Only Admin can access lead management');

    }

  }, [authStatus]);


  const fetchBatches = async () => {

    setLoading(true);

    try {

      const response = await axios.get('/api/leads/batches', { withCredentials: true });

      setBatches(response.data);

    } catch (error) {

      console.error('Error fetching batches:', error);

      setErrorMessage('Failed to fetch batches');

    } finally {

      setLoading(false);

    }

  };


  const fetchMrcLeads = async () => {

    setLoading(true);

    try {

      const response = await axios.get('/api/leads/mrc', { withCredentials: true, params: { page: page + 1, limit: rowsPerPage } });

      setMrcLeads(response.data);

    } catch (error) {

      console.error('Error fetching MRC leads:', error);

      setErrorMessage('Failed to fetch MRC leads');

    } finally {

      setLoading(false);

    }

  };


  const fetchLeadSources = async () => {

    try {

      const response = await axios.get('/api/leadsources', { withCredentials: true });

      setLeadSources(response.data);

    } catch (error) {

      console.error('Error fetching lead sources:', error);

    }

  };


  const fetchUsers = async () => {

    try {

      const response = await axios.get('/api/user', { withCredentials: true });

      setUsers(response.data);

    } catch (error) {

      console.error('Error fetching users:', error);

    }

  };


  const fetchAssignedLeads = async (userId) => {

    setLoading(true);

    try {

      const response = await axios.get(`/api/leads/assigned-leads/${userId}`, { withCredentials: true });

      console.log('Fetched assigned leads:', response.data);

      setAssignedLeads(response.data);

    } catch (error) {

      console.error('Error fetching assigned leads:', error);

      setErrorMessage('Failed to fetch assigned leads');

    } finally {

      setLoading(false);

    }

  };


  const handleFileUpload = async (file, endpoint, fieldName) => {

    if (!file) return;


    const formData = new FormData();

    formData.append(fieldName, file);

    formData.append('leadSource', leadSource);


    try {

      const response = await axios.post(endpoint, formData, {

        headers: { 'Content-Type': 'multipart/form-data' },

        withCredentials: true,

      });

      fetchBatches();

      alert('Upload successful');

    } catch (error) {

      console.error('Error uploading file:', error);

      setErrorMessage('Failed to upload batch');

    }

  };


  const handleAssign = async (batchId) => {

    const userId = selectedUser[batchId];

    if (!userId) {

      alert('Please select a user to assign the batch.');

      return;

    }


    try {

      await axios.post('/api/leads/mrc-assign-leads', { batchId, userId }, { withCredentials: true });

      setSnackbarOpen(true);

    } catch (error) {

      console.error('Error assigning leads:', error);

      setErrorMessage('Error assigning leads');

    }

  };


  const handleUserSelect = (event, leadId) => {

    const newSelectedUser = { ...selectedUser, [leadId]: event.target.value };

    setSelectedUser(newSelectedUser);


    // Update the lead assignment

    axios.post('/api/leads/mrc-assign-lead', { leadId, userId: event.target.value }, { withCredentials: true })

      .then(() => {

        setSnackbarOpen(true);

        fetchMrcLeads();

      })

      .catch((error) => {

        console.error('Error updating lead assignment:', error);

        setErrorMessage('Failed to update lead assignment');

      });

  };


  const handleSelectUserForLeads = async (event, value) => {

    if (value) {

      setAssignedLeads([]);

      await fetchAssignedLeads(value._id);

    } else {

      setAssignedLeads([]);

      setSelectedLeads(new Set());

    }

  };


  const handleSelectLead = (leadId) => {

    const newSelectedLeads = new Set(selectedLeads);

    if (newSelectedLeads.has(leadId)) {

      newSelectedLeads.delete(leadId);

    } else {

      newSelectedLeads.add(leadId);

    }

    setSelectedLeads(newSelectedLeads);

  };


  const handleSelectAllLeads = (event) => {

    if (event.target.checked) {

      const allLeads = new Set(mrcLeads.map((lead) => lead._id));

      setSelectedLeads(allLeads);

    } else {

      setSelectedLeads(new Set());

    }

  };


  const handleReassignLeads = async () => {

    if (!selectedUser) {

      alert('Please select a user to reassign the leads.');

      return;

    }


    const leadsToReassign = Array.from(selectedLeads);

    try {

      await axios.post('/api/leads/reassign-leads', { leads: leadsToReassign, userId: selectedUser }, { withCredentials: true });

      setSnackbarOpen(true);

      setSelectedLeads(new Set());

      fetchAssignedLeads(selectedUser);

    } catch (error) {

      console.error('Error reassigning leads:', error);

      setErrorMessage('Error reassigning leads');

    }

  };


  const handleFilterChange = (field, value) => {

    if (field === 'assignedDate') {

      setAssignedDateFilter(value);

    }

  };


  const handleFileChange = (e) => {

    setFile(e.target.files[0]);

  };


  const handleFileImport = () => {

    if (file) {

      const reader = new FileReader();

      reader.onload = (e) => {

        const data = new Uint8Array(e.target.result);

        const workbook = XLSX.read(data, { type: 'array' });

        const firstSheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[firstSheetName];

        const json = XLSX.utils.sheet_to_json(worksheet);

        setLeads(json);

      };

      reader.readAsArrayBuffer(file);

    }

  };


  const handleImportLeads = async () => {

    try {

      await axios.post('/api/leads/mrc/import', { leads });

      alert('Leads imported successfully!');

    } catch (error) {

      console.error('Error importing leads:', error);

      alert('Failed to import leads.');

    }

  };


  const handleResendLeadData = async (leadId) => {

    try {

      await axios.post(`/api/leads/${leadId}/resend-data`);

      alert('Lead data resent successfully!');

    } catch (error) {

      console.error('Error resending lead data:', error);

      alert('Failed to resend lead data.');

    }

  };


  const renderBatchesView = () => (

    <>

      <Typography variant="h5" align="center" gutterBottom>Lead Management</Typography>

      <Box textAlign="center" my={2}>

        <FormControl sx={{ minWidth: 200 }}>

          <InputLabel>Lead Source</InputLabel>

          <Select value={leadSource} onChange={(e) => setLeadSource(e.target.value)} displayEmpty>

            <MenuItem value="">

              <em>None</em>

            </MenuItem>

            {leadSources.map((source) => (

              <MenuItem key={source._id} value={source.source}>

                {source.source}

              </MenuItem>

            ))}

          </Select>

        </FormControl>


        <Button variant="contained" component="label" sx={{ backgroundColor: '#46a9af', ml: 2 }}>

          Upload Lead Batch

          <input type="file" hidden onChange={(e) => handleFileUpload(e.target.files[0], '/api/leads/upload-leads', 'leadFile')} />

        </Button>


        <Button variant="contained" component="label" sx={{ ml: 2, backgroundColor: '#46a9af' }}>

          Upload DNC List

          <input type="file" hidden onChange={(e) => handleFileUpload(e.target.files[0], '/api/leads/upload-dnc', 'dncFile')} />

        </Button>


        <Button variant="contained" onClick={() => { setView('mrcLeads'); fetchMrcLeads(); }} sx={{ ml: 2, backgroundColor: '#46a9af' }}>

          View MRC Leads

        </Button>


        <Button variant="contained" onClick={() => setView('leadsByUser')} sx={{ ml: 2, backgroundColor: '#46a9af' }}>

          Leads by User

        </Button>


        <Button variant="contained" onClick={() => { /* Open Lead Source Management Dialog */ }} sx={{ ml: 2, backgroundColor: '#46a9af' }}>

          Manage Lead Sources

        </Button>

      </Box>


      {loading ? <Typography variant="body1" align="center">Loading...</Typography> : (

        <TableContainer component={Paper}>

          <Table>

            <TableHead>

              <TableRow>

                <TableCell>Batch ID</TableCell>

                <TableCell>Upload Date</TableCell>

                <TableCell>User</TableCell>

                <TableCell>Action</TableCell>

              </TableRow>

            </TableHead>

            <TableBody>

              {batches.map((batch) => (

                <TableRow key={batch._id}>

                  <TableCell>{batch._id}</TableCell>

                  <TableCell>{new Date(batch.createdAt).toLocaleDateString()}</TableCell>

                  <TableCell>

                    <FormControl fullWidth variant="outlined">

                      <InputLabel>Select User</InputLabel>

                      <Select

                        value={selectedUser[batch._id] || ''}

                        onChange={(e) => handleUserSelect(e, batch._id)}

                        label="Select User"

                        sx={{ minWidth: 200 }}

                      >

                        <MenuItem value="">

                          <em>None</em>

                        </MenuItem>

                        {users.map((user) => (

                          <MenuItem key={user._id} value={user._id}>

                            {user.firstName} {user.lastName}

                          </MenuItem>

                        ))}

                      </Select>

                    </FormControl>

                  </TableCell>

                  <TableCell>

                    <Button onClick={() => handleAssign(batch._id)} sx={{ backgroundColor: '#46a9af' }}>

                      Assign

                    </Button>

                  </TableCell>

                </TableRow>

              ))}

            </TableBody>

          </Table>

        </TableContainer>

      )}


      <Snackbar

        open={snackbarOpen}

        onClose={() => setSnackbarOpen(false)}

        message="Batch assigned successfully"

        autoHideDuration={3000}

      />


      {errorMessage && (

        <Typography color="error" align="center">{errorMessage}</Typography>

      )}

    </>

  );


  const renderMrcLeadsView = () => (

    <>

      <Typography variant="h5" align="center" gutterBottom>MRC Leads</Typography>

      <Box textAlign="center" my={2}>

        <Button variant="contained" onClick={() => setView('batches')} sx={{ backgroundColor: '#46a9af' }}>

          Back to Lead Batches

        </Button>

      </Box>


      <Box textAlign="center" my={2}>

        <Button variant="contained" component="label" sx={{ backgroundColor: '#46a9af', ml: 2 }}>

          Import MRC Leads

          <input type="file" accept=".xlsx, .xls" hidden onChange={handleFileChange} />

        </Button>

        <Button variant="contained" onClick={handleFileImport} sx={{ backgroundColor: '#46a9af', ml: 2 }}>

          Upload

        </Button>

        <Button variant="contained" onClick={handleImportLeads} sx={{ backgroundColor: '#46a9af', ml: 2 }}>

          Import

        </Button>

      </Box>


      {loading ? <Typography variant="body1" align="center">Loading...</Typography> : (

        <TableContainer component={Paper}>

          <Table>

            <TableHead>

              <TableRow>

                <TableCell padding="checkbox">

                  <Checkbox

                    indeterminate={selectedLeads.size > 0 && selectedLeads.size < mrcLeads.length}

                    checked={selectedLeads.size === mrcLeads.length}

                    onChange={handleSelectAllLeads}

                    color="primary"

                  />

                </TableCell>

                <TableCell>Lead ID</TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'first_name'}

                    direction={orderBy === 'first_name' ? order : 'asc'}

                    onClick={() => handleSortRequest('first_name')}

                  >

                    Name

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'email'}

                    direction={orderBy === 'email' ? order : 'asc'}

                    onClick={() => handleSortRequest('email')}

                  >

                    Email

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'phone'}

                    direction={orderBy === 'phone' ? order : 'asc'}

                    onClick={() => handleSortRequest('phone')}

                  >

                    Phone

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'property_state'}

                    direction={orderBy === 'property_state' ? order : 'asc'}

                    onClick={() => handleSortRequest('property_state')}

                  >

                    State

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'assignedDate'}

                    direction={orderBy === 'assignedDate' ? order : 'asc'}

                    onClick={() => handleSortRequest('assignedDate')}

                  >

                    Assigned Date

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'leadSource'}

                    direction={orderBy === 'leadSource' ? order : 'asc'}

                    onClick={() => handleSortRequest('leadSource')}

                  >

                    Lead Source

                  </TableSortLabel>

                </TableCell>

                <TableCell>User</TableCell>

                <TableCell>Action</TableCell>

                <TableCell>Resend Data</TableCell> {/* Added Resend Data column */}

              </TableRow>

            </TableHead>

            <TableBody>

              {applySortFilter(mrcLeads).map((lead) => (

                <TableRow key={lead._id}>

                  <TableCell padding="checkbox">

                    <Checkbox

                      checked={selectedLeads.has(lead._id)}

                      onChange={() => handleSelectLead(lead._id)}

                      color="primary"

                    />

                  </TableCell>

                  <TableCell>{lead._id}</TableCell>

                  <TableCell>{`${lead.first_name || ''} ${lead.last_name || ''}`}</TableCell>

                  <TableCell>{lead.email}</TableCell>

                  <TableCell>{lead.phone_home || lead.phone_cell || lead.phone_work}</TableCell>

                  <TableCell>{lead.property_state}</TableCell>

                  <TableCell>{new Date(lead.createdAt).toLocaleDateString()}</TableCell>

                  <TableCell>{lead.lead_source}</TableCell>

                  <TableCell>

                    <FormControl fullWidth variant="outlined">

                      <InputLabel>User</InputLabel>

                      <Select

                        value={lead.assigned_loan_officer || ''}

                        onChange={(e) => handleUserSelect(e, lead._id)}

                        label="User"

                        sx={{ minWidth: 200 }}

                      >

                        <MenuItem value="">

                          <em>None</em>

                        </MenuItem>

                        {users.map((user) => (

                          <MenuItem key={user._id} value={user._id}>

                            {user.firstName} {user.lastName}

                          </MenuItem>

                        ))}

                      </Select>

                    </FormControl>

                  </TableCell>

                  <TableCell>

                    <Button onClick={() => handleAssign(lead._id)} sx={{ backgroundColor: '#46a9af' }}>

                      Assign

                    </Button>

                  </TableCell>

                  <TableCell>

                    <Button onClick={() => handleResendLeadData(lead._id)} sx={{ backgroundColor: '#46a9af' }}>

                      Resend Data

                    </Button>

                  </TableCell>

                </TableRow>

              ))}

            </TableBody>

          </Table>

          <TablePagination

            component="div"

            count={mrcLeads.length}

            page={page}

            onPageChange={(event, newPage) => setPage(newPage)}

            rowsPerPage={rowsPerPage}

            onRowsPerPageChange={(event) => {

              setRowsPerPage(parseInt(event.target.value, 10));

              setPage(0);

            }}

          />

        </TableContainer>

      )}


      <Snackbar

        open={snackbarOpen}

        onClose={() => setSnackbarOpen(false)}

        message="Leads reassigned successfully"

        autoHideDuration={3000}

      />


      {errorMessage && (

        <Typography color="error" align="center">{errorMessage}</Typography>

      )}

    </>

  );


  const renderLeadsByUserView = () => (

    <>

      <Typography variant="h5" align="center" gutterBottom>Leads by User</Typography>

      <Box textAlign="center" my={2}>

        <Autocomplete

          options={users}

          getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}

          renderInput={(params) => <TextField {...params} label="Search User" variant="outlined" />}

          onChange={handleSelectUserForLeads}

          sx={{ width: 300, marginBottom: 2 }}

        />


        <FormControl sx={{ minWidth: 200, ml: 2 }}>

          <InputLabel>Reassign to</InputLabel>

          <Select

            value={selectedUser}

            onChange={(e) => setSelectedUser(e.target.value)}

            label="Reassign to"

          >

            <MenuItem value="">

              <em>None</em>

            </MenuItem>

            {users.map((user) => (

              <MenuItem key={user._id} value={user._id}>

                {user.firstName} {user.lastName}

              </MenuItem>

            ))}

          </Select>

        </FormControl>


        <Button variant="contained" onClick={handleReassignLeads} sx={{ ml: 2, backgroundColor: '#46a9af' }}>

          Reassign Leads

        </Button>


        <FormControl sx={{ minWidth: 200, ml: 2 }}>

          <InputLabel>Filter by Assigned Date</InputLabel>

          <Select value={assignedDateFilter} onChange={(e) => handleFilterChange('assignedDate', e.target.value)}>

            <MenuItem value="">

              <em>None</em>

            </MenuItem>

            <MenuItem value="last24hrs">Last 24 hours</MenuItem>

            <MenuItem value="last7days">Last 7 days</MenuItem>

            <MenuItem value="last30days">Last 30 days</MenuItem>

          </Select>

        </FormControl>


        <FormControl sx={{ minWidth: 200, ml: 2 }}>

          <InputLabel>Filter by Lead Source</InputLabel>

          <Select value={leadSource} onChange={(e) => setLeadSource(e.target.value)}>

            <MenuItem value="">

              <em>All</em>

            </MenuItem>

            <MenuItem value="MRC">MRC</MenuItem>

          </Select>

        </FormControl>

      </Box>


      {loading ? <Typography variant="body1" align="center">Loading...</Typography> : (

        <TableContainer component={Paper}>

          <Table>

            <TableHead>

              <TableRow>

                <TableCell padding="checkbox">

                  <Checkbox

                    indeterminate={selectedLeads.size > 0 && selectedLeads.size < assignedLeads.length}

                    checked={selectedLeads.size === assignedLeads.length}

                    onChange={handleSelectAllLeads}

                    color="primary"

                  />

                </TableCell>

                <TableCell>Lead ID</TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'first_name'}

                    direction={orderBy === 'first_name' ? order : 'asc'}

                    onClick={() => handleSortRequest('first_name')}

                  >

                    Name

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'email'}

                    direction={orderBy === 'email' ? order : 'asc'}

                    onClick={() => handleSortRequest('email')}

                  >

                    Email

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'phone'}

                    direction={orderBy === 'phone' ? order : 'asc'}

                    onClick={() => handleSortRequest('phone')}

                  >

                    Phone

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'property_state'}

                    direction={orderBy === 'property_state' ? order : 'asc'}

                    onClick={() => handleSortRequest('property_state')}

                  >

                    State

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'assignedDate'}

                    direction={orderBy === 'assignedDate' ? order : 'asc'}

                    onClick={() => handleSortRequest('assignedDate')}

                  >

                    Assigned Date

                  </TableSortLabel>

                </TableCell>

                <TableCell>

                  <TableSortLabel

                    active={orderBy === 'leadSource'}

                    direction={orderBy === 'leadSource' ? order : 'asc'}

                    onClick={() => handleSortRequest('leadSource')}

                  >

                    Lead Source

                  </TableSortLabel>

                </TableCell>

              </TableRow>

            </TableHead>

            <TableBody>

              {applySortFilter(assignedLeads).map((lead) => (

                <TableRow key={lead.leadId._id}>

                  <TableCell padding="checkbox">

                    <Checkbox

                      checked={selectedLeads.has(lead.leadId._id)}

                      onChange={() => handleSelectLead(lead.leadId._id)}

                      color="primary"

                    />

                  </TableCell>

                  <TableCell>{lead.leadId._id}</TableCell>

                  <TableCell>{`${lead.leadId.FNAME || ''} ${lead.leadId.LNAME || ''}`}</TableCell>

                  <TableCell>{lead.leadId.email}</TableCell>

                  <TableCell>{lead.leadId.premium_phone}</TableCell>

                  <TableCell>{lead.leadId.property_state}</TableCell>

                  <TableCell>{new Date(lead.assignedDate).toLocaleDateString()}</TableCell>

                  <TableCell>{lead.leadSource}</TableCell>

                </TableRow>

              ))}

            </TableBody>

          </Table>

        </TableContainer>

      )}


      <Snackbar

        open={snackbarOpen}

        onClose={() => setSnackbarOpen(false)}

        message="Leads reassigned successfully"

        autoHideDuration={3000}

      />


      {errorMessage && (

        <Typography color="error" align="center">{errorMessage}</Typography>

      )}

    </>

  );


  const applySortFilter = (data) => {

    const sortedData = data.sort((a, b) => {

      if (orderBy === 'first_name') {

        return order === 'asc'

          ? (a.first_name || '').localeCompare(b.first_name || '')

          : (b.first_name || '').localeCompare(a.first_name || '');

      }

      if (orderBy === 'email') {

        return order === 'asc'

          ? (a.email || '').localeCompare(b.email || '')

          : (b.email || '').localeCompare(a.email || '');

      }

      if (orderBy === 'phone') {

        return order === 'asc'

          ? (a.phone || '').localeCompare(b.phone || '')

          : (b.phone || '').localeCompare(a.phone || '');

      }

      if (orderBy === 'property_state') {

        return order === 'asc'

          ? (a.property_state || '').localeCompare(b.property_state || '')

          : (b.property_state || '').localeCompare(a.property_state || '');

      }

      if (orderBy === 'assignedDate') {

        return order === 'asc'

          ? new Date(a.assignedDate) - new Date(b.assignedDate)

          : new Date(b.assignedDate) - new Date(a.assignedDate);

      }

      if (orderBy === 'leadSource') {

        return order === 'asc'

          ? (a.leadSource || '').localeCompare(b.leadSource || '')

          : (b.leadSource || '').localeCompare(a.leadSource || '');

      }

      return 0;

    });


    if (assignedDateFilter === 'last24hrs') {

      const now = new Date();

      return sortedData.filter(

        (lead) => new Date(lead.assignedDate) >= now.setDate(now.getDate() - 1)

      );

    }


    if (assignedDateFilter === 'last7days') {

      const now = new Date();

      return sortedData.filter(

        (lead) => new Date(lead.assignedDate) >= now.setDate(now.getDate() - 7)

      );

    }


    if (assignedDateFilter === 'last30days') {

      const now = new Date();

      return sortedData.filter(

        (lead) => new Date(lead.assignedDate) >= now.setDate(now.getDate() - 30)

      );

    }


    return sortedData;

  };


  const handleSortRequest = (property) => {

    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');

    setOrderBy(property);

  };


  if (!authStatus.isLoggedIn || authStatus.role !== 'Admin') {

    return <Typography variant="h6" align="center">Unauthorized</Typography>;

  }


  return (

    <Container component="main" maxWidth="lg">

      {view === 'batches' ? renderBatchesView() :

        view === 'mrcLeads' ? renderMrcLeadsView() :

          renderLeadsByUserView()}

    </Container>

  );

};


export default LeadManagement;

