import React, { useEffect, useState, useCallback } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Box, useMediaQuery, Switch, FormControlLabel, Typography } from '@mui/material';

import { ResizableBox } from 'react-resizable';

import 'react-resizable/css/styles.css';

import axios from 'axios';

import Phone from './Phone';

import CallLog from './CallLog';

import DispositionHistory from './DispositionHistory';

import LeadDetails from './LeadDetails';


const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;

};


const PhoneWindow = ({ lead }) => {

  const isMobile = useMediaQuery('(max-width:600px)');

  const [saveLayout, setSaveLayout] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const initialItems = [

    { id: 'phone', content: <Phone lead={lead} onCallCompleted={() => setRefresh(!refresh)} />, width: 316, height: 525 },

    { id: 'callLog', content: <CallLog leadId={lead._id} refresh={refresh} />, width: 255, height: 287 },

    { id: 'dispositionHistory', content: <DispositionHistory leadId={lead._id} />, width: 434, height: 770 },

    { id: 'leadDetails', content: <LeadDetails leadId={lead._id} />, width: 1007, height: 767 },

  ];


  const [items, setItems] = useState(initialItems);

  const [userId, setUserId] = useState(null);

  const [extension, setExtension] = useState('No Extension');


  const fetchUserData = useCallback(async () => {

    try {

      const userResponse = await axios.get('/api/auth/status'); // Change this endpoint as needed

      const user = userResponse.data.user;

      setUserId(user._id);


      // Fetch user extension

      if (user.extension) {

        setExtension(user.extension);

      } else {

        setExtension('No Extension');

      }


      // Fetch layout settings

      const layoutResponse = await axios.get('/api/user/layout-settings');

      const layoutSettings = layoutResponse.data;


      if (layoutSettings) {

        const savedItems = initialItems.map(item => ({

          ...item,

          width: layoutSettings[item.id]?.width || item.width,

          height: layoutSettings[item.id]?.height || item.height,

        }));

        setItems(savedItems);

      }

    } catch (error) {

      console.error('Error fetching user data', error);

    }

  }, [initialItems]);


  useEffect(() => {

    fetchUserData();

  }, [fetchUserData]);


  const onDragEnd = (result) => {

    if (!result.destination) {

      return;

    }

    const reorderedItems = reorder(items, result.source.index, result.destination.index);

    setItems(reorderedItems);

  };


  const handleResizeStop = (index, data) => {

    const newItems = [...items];

    newItems[index].width = data.size.width;

    newItems[index].height = data.size.height;

    setItems(newItems);

  };


  const handleToggleSaveLayout = async () => {

    setSaveLayout(!saveLayout);

    if (!saveLayout && userId) {

      try {

        const layoutSettings = {};

        items.forEach(item => {

          layoutSettings[item.id] = { width: item.width, height: item.height };

        });

        await axios.post('/api/user/save-layout-settings', { userId, layoutSettings });

        console.log('Layout settings saved successfully');

      } catch (error) {

        console.error('Error saving layout settings', error);

      }

    }

  };


  const renderItem = (item, index) => (

    <Draggable key={item.id} draggableId={item.id} index={index}>

      {(provided) => (

        <ResizableBox

          width={item.width}

          height={item.height}

          minConstraints={[100, 100]}

          onResizeStop={(e, data) => handleResizeStop(index, data)}

        >

          <Box

            ref={provided.innerRef}

            {...provided.draggableProps}

            {...provided.dragHandleProps}

            sx={{

              padding: 1,

              backgroundColor: '#fff',

              boxShadow: 3,

              borderRadius: 1,

              display: 'flex',

              flexDirection: 'column',

              justifyContent: 'flex-start',

              alignItems: 'center',

              height: '100%', // Ensure height fits the resizable container

              width: '100%', // Ensure width fits the resizable container

              margin: 1,

              overflow: 'hidden', // Ensure content doesn't overflow

            }}

          >

            {item.content}

          </Box>

        </ResizableBox>

      )}

    </Draggable>

  );


  return (

    <>

      <Typography variant="h6">User Extension: {extension}</Typography>

      <FormControlLabel

        control={<Switch checked={saveLayout} onChange={handleToggleSaveLayout} />}

        label="Save screen for all leads"

        sx={{ marginBottom: 2 }}

      />

      <DragDropContext onDragEnd={onDragEnd}>

        <Droppable droppableId="droppable" direction={isMobile ? 'vertical' : 'horizontal'}>

          {(provided) => (

            <Box

              {...provided.droppableProps}

              ref={provided.innerRef}

              sx={{

                display: 'flex',

                flexWrap: 'wrap',

                gap: 2,

                padding: 2,

                height: '100vh',

                overflow: 'auto', // Allow scrolling if content overflows

              }}

            >

              {items.map((item, index) => renderItem(item, index))}

              {provided.placeholder}

            </Box>

          )}

        </Droppable>

      </DragDropContext>

    </>

  );

};


export default PhoneWindow;

