import React, { useEffect, useState } from 'react';

import {

  Box, Typography, Paper, Divider, CircularProgress, Link

} from '@mui/material';

import { teal } from '@mui/material/colors';

import axios from 'axios';


const CallLog = ({ leadId, refresh }) => {

  const [callAttempts, setCallAttempts] = useState([]);

  const [callRecordings, setCallRecordings] = useState([]);

  const [loading, setLoading] = useState(false);


  useEffect(() => {

    const fetchCallData = async () => {

      setLoading(true);

      try {

        const callLogResponse = await axios.get(`/api/leads/${leadId}/call-logs`);

        const recordingsResponse = await axios.get(`/api/leads/${leadId}/recordings`);


        console.log('Fetched call logs:', callLogResponse.data);

        console.log('Fetched recordings:', recordingsResponse.data);


        setCallAttempts(callLogResponse.data || []);

        setCallRecordings(recordingsResponse.data || []);

      } catch (error) {

        console.error('Error fetching call data:', error);

      }

      setLoading(false);

    };


    if (leadId) {

      fetchCallData();

    }

  }, [leadId, refresh]); // Add refresh as a dependency to trigger re-fetching


  const sectionStyle = {

    padding: 2,

    marginBottom: 2,

    boxShadow: 3,

  };


  const headerStyle = {

    marginBottom: 1,

    color: teal[700],

  };


  const fieldKeyStyle = {

    color: teal[700],

    fontWeight: 'bold',

  };


  const fieldValueStyle = {

    color: 'black',

  };


  // Function to find matching recording for a given call attempt

  const findMatchingRecording = (timestamp) => {

    return callRecordings.find(recording => 

      new Date(recording.timestamp).toLocaleString() === new Date(timestamp).toLocaleString()

    );

  };


  // Sort call attempts by timestamp in descending order

  const sortedCallAttempts = callAttempts.slice().sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));


  return (

    <Box>

      <Paper sx={sectionStyle}>

        <Typography variant="h6" sx={headerStyle}>

          Call Log

        </Typography>

        <Divider />

        {loading ? (

          <CircularProgress />

        ) : (

          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>

            {sortedCallAttempts.map((attempt, index) => {

              const matchingRecording = findMatchingRecording(attempt.timestamp);

              return (

                <Box key={attempt._id} sx={{ marginBottom: 2 }}>

                  <Typography variant="body1">

                    <span style={fieldKeyStyle}>Outgoing Call {index + 1}:</span>{' '}

                    <span style={fieldValueStyle}>{new Date(attempt.timestamp).toLocaleString()}</span>

                  </Typography>

                  {matchingRecording && (

                    <Typography variant="body1">

                      <span style={fieldKeyStyle}>Recording:</span>{' '}

                      <Link href={matchingRecording.recordingUrl} target="_blank" rel="noopener" style={fieldValueStyle}>

                        Listen

                      </Link>

                    </Typography>

                  )}

                  <Divider />

                </Box>

              );

            })}

          </Box>

        )}

      </Paper>

    </Box>

  );

};


export default CallLog;

