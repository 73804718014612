import React, { useState } from 'react';

import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, IconButton } from '@mui/material';

import axios from 'axios';

import AttachFileIcon from '@mui/icons-material/AttachFile';


const EmailForm = ({ open, handleClose, leadId }) => {

  const [to, setEmail] = useState('');

  const [subject, setSubject] = useState('');

  const [message, setMessage] = useState('');

  const [cc, setCc] = useState('');

  const [bcc, setBcc] = useState('');

  const [attachments, setAttachments] = useState([]);

  const [loading, setLoading] = useState(false);


  const handleAttachmentChange = (event) => {

    setAttachments([...attachments, ...event.target.files]);

  };


  const handleSubmit = async (e) => {

    e.preventDefault();

    setLoading(true);


    const formData = new FormData();

    formData.append('to', to);

    formData.append('subject', subject);

    formData.append('body', message);

    formData.append('cc', cc);

    formData.append('bcc', bcc);

    formData.append('userId', leadId);


    attachments.forEach(file => {

      formData.append('attachments', file);

    });


    try {

      const response = await axios.post('/api/email/send', formData, {

        headers: {

          'Content-Type': 'multipart/form-data'

        }

      });


      if (response.data.message === 'Email sent successfully.') {

        alert('Email sent successfully!');

        handleClose();

      } else {

        alert('Failed to send email: ' + response.data.error);

      }

    } catch (error) {

      console.error('Email sending error:', error);

      alert('Failed to send email: ' + error.message);

    } finally {

      setLoading(false);

    }

  };


  return (

    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

      <DialogTitle id="form-dialog-title">Send Email</DialogTitle>

      <DialogContent>

        <TextField

          autoFocus

          margin="dense"

          label="To Email Address"

          type="email"

          fullWidth

          value={to}

          onChange={(e) => setEmail(e.target.value)}

        />

        <TextField

          margin="dense"

          label="Cc"

          type="text"

          fullWidth

          value={cc}

          onChange={(e) => setCc(e.target.value)}

        />

        <TextField

          margin="dense"

          label="Bcc"

          type="text"

          fullWidth

          value={bcc}

          onChange={(e) => setBcc(e.target.value)}

        />

        <TextField

          margin="dense"

          label="Subject"

          type="text"

          fullWidth

          value={subject}

          onChange={(e) => setSubject(e.target.value)}

        />

        <TextField

          margin="dense"

          label="Message"

          type="text"

          multiline

          rows={4}

          fullWidth

          value={message}

          onChange={(e) => setMessage(e.target.value)}

        />

        <input

          type="file"

          multiple

          onChange={handleAttachmentChange}

          id="attachment-input"

          style={{ display: 'none' }}

        />

        <label htmlFor="attachment-input">

          <IconButton component="span">

            <AttachFileIcon />

          </IconButton>

        </label>

      </DialogContent>

      <DialogActions>

        <Button onClick={handleClose} color="primary">

          Cancel

        </Button>

        <Button onClick={handleSubmit} color="primary" disabled={loading}>

          {loading ? <CircularProgress size={24} /> : 'Send Email'}

        </Button>

      </DialogActions>

    </Dialog>

  );

};


export default EmailForm;

