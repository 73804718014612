// src/components/RightMainView.jsx

import React from 'react';


function RightMainView() {

  return (

    <div className="right-main-view">

      {/* Detailed loan info and charts */}

      <p>Detailed Loan Information</p>

      {/* Implement charts and detailed views */}

    </div>

  );

}


export default RightMainView;

