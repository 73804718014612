// FinalScreen.jsx

import React from 'react';

import { Typography, Box, Container, Button } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';


function FinalScreen() {

    const location = useLocation();

    const navigate = useNavigate();

    const { contactInfo, loanDetails } = location.state || {};


    const handleBack = () => {

        navigate('/');

    };


    return (

        <Container component="main" maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>

            <Typography component="h1" variant="h5">

                Loan Application Review

            </Typography>

            <Box sx={{ mt: 1, width: '100%', maxWidth: 360, bgcolor: 'background.paper', boxShadow: 3, padding: 4, borderRadius: 2 }}>

                <Typography variant="h6">Contact Information</Typography>

                <Typography>First Name: {contactInfo?.firstName}</Typography>

                <Typography>Last Name: {contactInfo?.lastName}</Typography>

                <Typography>Email: {contactInfo?.email}</Typography>

                <Typography>Cellphone: {contactInfo?.cellphone}</Typography>

                <Typography variant="h6" sx={{ mt: 2 }}>Loan and Property Information</Typography>

                <Typography>Loan Purpose: {loanDetails?.loanPurpose}</Typography>

                <Typography>Purchase Price: {loanDetails?.purchasePrice}</Typography>

                <Typography>Down Payment: {loanDetails?.downPayment}</Typography>

                <Typography>Mortgage Amount: {loanDetails?.mortgageAmount}</Typography>

                <Typography>Occupancy: {loanDetails?.occupancy}</Typography>

                <Typography>Property Type: {loanDetails?.propertyType}</Typography>

                <Typography>Property Address: {loanDetails?.propertyAddress}</Typography>

                <Button

                    onClick={handleBack}

                    variant="contained"

                    sx={{ mt: 3, mb: 2 }}

                >

                    Back to Start

                </Button>

            </Box>

        </Container>

    );

}


export default FinalScreen;

