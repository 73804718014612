import React, { useState, useEffect } from 'react';

import {

  Container, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,

  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText, OutlinedInput, IconButton, InputAdornment

} from '@mui/material';

import { Visibility, VisibilityOff, ContentCopy } from '@mui/icons-material';

import axios from 'axios';

import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme();


const statesList = [

  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",

  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",

  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",

  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",

  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"

];


const UserManagement = () => {

  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [selectedUser, setSelectedUser] = useState(null);

  const [newPassword, setNewPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [editedUser, setEditedUser] = useState({

    firstName: '',

    lastName: '',

    username: '',

    email: '',

    password: '', // Added password field for new user creation

    cell: '',

    role: '',

    nmls: '',

    branch: '',

    extension: '',

    directLine: '',

    licensedStates: [],

    zapierUrl: '',

    woosenderId: ''

  });


  useEffect(() => {

    fetchUsers();

  }, []);


  const fetchUsers = async () => {

    setLoading(true);

    try {

      const response = await axios.get('/api/user', { withCredentials: true });

      setUsers(response.data);

      setLoading(false);

    } catch (error) {

      console.error('Error fetching users:', error);

      setErrorMessage('Failed to fetch users');

      setLoading(false);

    }

  };


  const handleDeleteUser = async (userId) => {

    try {

      await axios.delete(`/api/user/${userId}`, { withCredentials: true });

      fetchUsers();

      setSnackbarMessage('User deleted successfully');

      setSnackbarOpen(true);

    } catch (error) {

      console.error('Error deleting user:', error);

      setErrorMessage('Error deleting user');

    }

  };


  const handleOpenResetDialog = (user) => {

    setSelectedUser(user);

    setResetDialogOpen(true);

  };


  const handleCloseResetDialog = () => {

    setSelectedUser(null);

    setNewPassword('');

    setResetDialogOpen(false);

  };


  const handleResetPassword = async () => {

    if (!selectedUser) return;


    const password = newPassword || generateTempPassword();


    try {

      await axios.post(`/api/user/${selectedUser._id}/reset-password`, { password }, { withCredentials: true });

      setSnackbarMessage('Password reset successfully');

      setSnackbarOpen(true);

      handleCloseResetDialog();

    } catch (error) {

      console.error('Error resetting password:', error);

      setErrorMessage('Error resetting password');

    }

  };


  const generateTempPassword = () => {

    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    let password = '';


    for (let i = 0; i < 8; i++) {

      password += chars.charAt(Math.floor(Math.random() * chars.length));

    }


    setNewPassword(password);

    return password;

  };


  const handleSendResetEmail = async () => {

    if (!selectedUser) return;


    try {

      await axios.post('/api/user/send-reset', { email: selectedUser.email }, { withCredentials: true });

      setSnackbarMessage('Password reset email sent');

      setSnackbarOpen(true);

      handleCloseResetDialog();

    } catch (error) {

      console.error('Error sending reset email:', error);

      setErrorMessage('Error sending reset email');

    }

  };


  const handleClickShowPassword = () => {

    setShowPassword(!showPassword);

  };


  const handleCopyPassword = () => {

    navigator.clipboard.writeText(newPassword);

    setSnackbarMessage('Password copied to clipboard');

    setSnackbarOpen(true);

  };


  const handleOpenEditDialog = (user) => {

    setEditedUser({

      firstName: user.firstName,

      lastName: user.lastName,

      username: user.username,

      email: user.email,

      cell: user.cell,

      role: user.role,

      nmls: user.nmls,

      branch: user.branch,

      extension: user.extension || '',

      directLine: user.directLine || '',

      licensedStates: user.licensedStates || [],

      zapierUrl: user.zapierUrl || '',

      woosenderId: user.woosenderId || ''

    });

    setSelectedUser(user);

    setEditDialogOpen(true);

  };


  const handleCloseEditDialog = () => {

    setEditedUser({

      firstName: '',

      lastName: '',

      username: '',

      email: '',

      password: '',

      cell: '',

      role: '',

      nmls: '',

      branch: '',

      extension: '',

      directLine: '',

      licensedStates: [],

      zapierUrl: '',

      woosenderId: ''

    });

    setSelectedUser(null);

    setEditDialogOpen(false);

  };


  const handleEditUser = async () => {

    if (!selectedUser) return;


    try {

      await axios.patch(`/api/user/${selectedUser._id}`, editedUser, { withCredentials: true });

      setSnackbarMessage('User updated successfully');

      setSnackbarOpen(true);

      handleCloseEditDialog();

      fetchUsers();

    } catch (error) {

      if (error.response && error.response.data && error.response.data.message.includes('E11000')) {

        setErrorMessage('Duplicate direct line detected. Please use a unique direct line.');

      } else {

        console.error('Error updating user:', error);

        setErrorMessage('Error updating user');

      }

    }

  };


  const handleEditInputChange = (e) => {

    const { name, value } = e.target;

    setEditedUser({ ...editedUser, [name]: value });

  };


  const handleLicensedStatesChange = (event) => {

    const {

      target: { value },

    } = event;

    setEditedUser({ ...editedUser, licensedStates: typeof value === 'string' ? value.split(',') : value });

  };


  const handleOpenCreateDialog = () => {

    setEditedUser({

      firstName: '',

      lastName: '',

      username: '',

      email: '',

      password: '', // Added password field for new user creation

      cell: '',

      role: '',

      nmls: '',

      branch: '',

      extension: '',

      directLine: '',

      licensedStates: [],

      zapierUrl: '',

      woosenderId: ''

    });

    setCreateDialogOpen(true);

  };


  const handleCloseCreateDialog = () => {

    setEditedUser({

      firstName: '',

      lastName: '',

      username: '',

      email: '',

      password: '', // Added password field for new user creation

      cell: '',

      role: '',

      nmls: '',

      branch: '',

      extension: '',

      directLine: '',

      licensedStates: [],

      zapierUrl: '',

      woosenderId: ''

    });

    setCreateDialogOpen(false);

  };


  const handleCreateUser = async () => {

    try {

      await axios.post('/api/user', editedUser, { withCredentials: true });

      setSnackbarMessage('User created successfully');

      setSnackbarOpen(true);

      handleCloseCreateDialog();

      fetchUsers();

    } catch (error) {

      console.error('Error creating user:', error);

      setErrorMessage('Error creating user');

    }

  };


  return (

    <ThemeProvider theme={theme}>

      <Container component="main" maxWidth="lg">

        <Typography variant="h5" align="center" gutterBottom>User Management</Typography>

        {loading ? <Typography variant="body1" align="center">Loading...</Typography> : (

          <TableContainer component={Paper}>

            <Table>

              <TableHead>

                <TableRow>

                  <TableCell>First Name</TableCell>

                  <TableCell>Last Name</TableCell>

                  <TableCell>Username</TableCell>

                  <TableCell>Email</TableCell>

                  <TableCell>Role</TableCell>

                  <TableCell>Actions</TableCell>

                </TableRow>

              </TableHead>

              <TableBody>

                {users.map((user) => (

                  <TableRow key={user._id}>

                    <TableCell>{user.firstName}</TableCell>

                    <TableCell>{user.lastName}</TableCell>

                    <TableCell>{user.username}</TableCell>

                    <TableCell>{user.email}</TableCell>

                    <TableCell>{user.role}</TableCell>

                    <TableCell>

                      <Button onClick={() => handleOpenEditDialog(user)} color="primary">Edit</Button>

                      <Button onClick={() => handleDeleteUser(user._id)} color="secondary">Delete</Button>

                      <Button onClick={() => handleOpenResetDialog(user)} color="primary">Reset Password</Button>

                    </TableCell>

                  </TableRow>

                ))}

              </TableBody>

            </Table>

          </TableContainer>

        )}

        <Button onClick={handleOpenCreateDialog} color="primary" variant="contained" style={{ marginTop: 20 }}>Create User</Button>

        <Snackbar

          open={snackbarOpen}

          onClose={() => setSnackbarOpen(false)}

          message={snackbarMessage}

          autoHideDuration={3000}

        />

        {errorMessage && (

          <Typography color="error" align="center">{errorMessage}</Typography>

        )}

        <Dialog open={resetDialogOpen} onClose={handleCloseResetDialog}>

          <DialogTitle>Reset Password for {selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : ''}</DialogTitle>

          <DialogContent>

            <TextField

              margin="dense"

              label="New Password"

              type={showPassword ? 'text' : 'password'}

              fullWidth

              value={newPassword}

              onChange={(e) => setNewPassword(e.target.value)}

              InputProps={{

                endAdornment: (

                  <InputAdornment position="end">

                    <IconButton

                      aria-label="toggle password visibility"

                      onClick={handleClickShowPassword}

                      edge="end"

                    >

                      {showPassword ? <VisibilityOff /> : <Visibility />}

                    </IconButton>

                    <IconButton

                      aria-label="copy password"

                      onClick={handleCopyPassword}

                      edge="end"

                    >

                      <ContentCopy />

                    </IconButton>

                  </InputAdornment>

                ),

              }}

            />

            <Button onClick={generateTempPassword} color="primary">Generate Password</Button>

          </DialogContent>

          <DialogActions>

            <Button onClick={handleCloseResetDialog} color="primary">Cancel</Button>

            <Button onClick={handleResetPassword} color="primary">Reset</Button>

            <Button onClick={handleSendResetEmail} color="primary">Send Reset Email</Button>

          </DialogActions>

        </Dialog>


        <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>

          <DialogTitle>Edit User Details</DialogTitle>

          <DialogContent>

            <TextField

              margin="dense"

              label="First Name"

              name="firstName"

              fullWidth

              value={editedUser.firstName}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Last Name"

              name="lastName"

              fullWidth

              value={editedUser.lastName}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Username"

              name="username"

              fullWidth

              value={editedUser.username}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Email"

              name="email"

              fullWidth

              value={editedUser.email}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Cell"

              name="cell"

              fullWidth

              value={editedUser.cell}

              onChange={handleEditInputChange}

            />

            <FormControl fullWidth margin="dense">

              <InputLabel>Role</InputLabel>

              <Select

                name="role"

                value={editedUser.role}

                onChange={handleEditInputChange}

              >

                <MenuItem value="Borrower">Borrower</MenuItem>

                <MenuItem value="LoanOfficer">Loan Officer</MenuItem>

                <MenuItem value="Agent">Agent</MenuItem>

                <MenuItem value="Manager">Manager</MenuItem>

                <MenuItem value="Processor">Processor</MenuItem>

                <MenuItem value="Assistant">Assistant</MenuItem>

                <MenuItem value="Admin">Admin</MenuItem>

              </Select>

            </FormControl>

            <TextField

              margin="dense"

              label="NMLS"

              name="nmls"

              fullWidth

              value={editedUser.nmls}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Branch"

              name="branch"

              fullWidth

              value={editedUser.branch}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Extension"

              name="extension"

              fullWidth

              value={editedUser.extension}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Direct Line"

              name="directLine"

              fullWidth

              value={editedUser.directLine}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Zapier URL"

              name="zapierUrl"

              fullWidth

              value={editedUser.zapierUrl}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Woosender ID"

              name="woosenderId"

              fullWidth

              value={editedUser.woosenderId}

              onChange={handleEditInputChange}

            />

            <FormControl fullWidth margin="dense">

              <InputLabel>Licensed States</InputLabel>

              <Select

                multiple

                name="licensedStates"

                value={editedUser.licensedStates}

                onChange={handleLicensedStatesChange}

                input={<OutlinedInput label="Licensed States" />}

                renderValue={(selected) => selected.join(', ')}

              >

                {statesList.map((state) => (

                  <MenuItem key={state} value={state}>

                    <Checkbox checked={editedUser.licensedStates.indexOf(state) > -1} />

                    <ListItemText primary={state} />

                  </MenuItem>

                ))}

              </Select>

            </FormControl>

          </DialogContent>

          <DialogActions>

            <Button onClick={handleCloseEditDialog} color="primary">Cancel</Button>

            <Button onClick={handleEditUser} color="primary">Save</Button>

          </DialogActions>

        </Dialog>


        <Dialog open={createDialogOpen} onClose={handleCloseCreateDialog}>

          <DialogTitle>Create New User</DialogTitle>

          <DialogContent>

            <TextField

              margin="dense"

              label="First Name"

              name="firstName"

              fullWidth

              value={editedUser.firstName}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Last Name"

              name="lastName"

              fullWidth

              value={editedUser.lastName}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Username"

              name="username"

              fullWidth

              value={editedUser.username}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Email"

              name="email"

              fullWidth

              value={editedUser.email}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Password"

              name="password"

              type={showPassword ? 'text' : 'password'}

              fullWidth

              value={editedUser.password}

              onChange={handleEditInputChange}

              InputProps={{

                endAdornment: (

                  <InputAdornment position="end">

                    <IconButton

                      aria-label="toggle password visibility"

                      onClick={handleClickShowPassword}

                      edge="end"

                    >

                      {showPassword ? <VisibilityOff /> : <Visibility />}

                    </IconButton>

                  </InputAdornment>

                ),

              }}

            />

            <TextField

              margin="dense"

              label="Cell"

              name="cell"

              fullWidth

              value={editedUser.cell}

              onChange={handleEditInputChange}

            />

            <FormControl fullWidth margin="dense">

              <InputLabel>Role</InputLabel>

              <Select

                name="role"

                value={editedUser.role}

                onChange={handleEditInputChange}

              >

                <MenuItem value="Borrower">Borrower</MenuItem>

                <MenuItem value="LoanOfficer">Loan Officer</MenuItem>

                <MenuItem value="Agent">Agent</MenuItem>

                <MenuItem value="Manager">Manager</MenuItem>

                <MenuItem value="Processor">Processor</MenuItem>

                <MenuItem value="Assistant">Assistant</MenuItem>

                <MenuItem value="Admin">Admin</MenuItem>

              </Select>

            </FormControl>

            <TextField

              margin="dense"

              label="NMLS"

              name="nmls"

              fullWidth

              value={editedUser.nmls}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Branch"

              name="branch"

              fullWidth

              value={editedUser.branch}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Extension"

              name="extension"

              fullWidth

              value={editedUser.extension}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Direct Line"

              name="directLine"

              fullWidth

              value={editedUser.directLine}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Zapier URL"

              name="zapierUrl"

              fullWidth

              value={editedUser.zapierUrl}

              onChange={handleEditInputChange}

            />

            <TextField

              margin="dense"

              label="Woosender ID"

              name="woosenderId"

              fullWidth

              value={editedUser.woosenderId}

              onChange={handleEditInputChange}

            />

            <FormControl fullWidth margin="dense">

              <InputLabel>Licensed States</InputLabel>

              <Select

                multiple

                name="licensedStates"

                value={editedUser.licensedStates}

                onChange={handleLicensedStatesChange}

                input={<OutlinedInput label="Licensed States" />}

                renderValue={(selected) => selected.join(', ')}

              >

                {statesList.map((state) => (

                  <MenuItem key={state} value={state}>

                    <Checkbox checked={editedUser.licensedStates.indexOf(state) > -1} />

                    <ListItemText primary={state} />

                  </MenuItem>

                ))}

              </Select>

            </FormControl>

          </DialogContent>

          <DialogActions>

            <Button onClick={handleCloseCreateDialog} color="primary">Cancel</Button>

            <Button onClick={handleCreateUser} color="primary">Create</Button>

          </DialogActions>

        </Dialog>

      </Container>

    </ThemeProvider>

  );

};


export default UserManagement;

