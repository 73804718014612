import axios from 'axios';


const api = axios.create({

  baseURL: process.env.REACT_APP_API_URL, // Adjust the base URL as needed

  withCredentials: true // Include cookies in cross-origin requests

});


export default api;

