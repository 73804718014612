import React from 'react';

import RenewIcon from '../Auth/RenewIcon.gif';

import HeaderSearch from './HeaderSearch';

import SettingsMenu from './SettingsMenu';

import './Header.css'; // Make sure this import points to the correct path of your CSS file


function Header() {

  return (

    <div className="header-container">

      <div className="logo-section">

        <img src={RenewIcon} alt="Company Logo" style={{ maxWidth: '25px', maxHeight: '25px' }} />

      </div>

      <HeaderSearch />

      {/* TopHeaderMenu component removed */}

      <SettingsMenu />

    </div>

  );

}


export default Header;

