import axios from "axios";


const Instance = axios.create({

  baseURL: "https://www.renewriff.com/api/call",

});


const getToken = () => Instance({ method: "POST", url: "/token" });


export { getToken };
