import React from 'react';

import FooterMenu from './FooterMenu';

import './Footer.css'; // Assuming you'll add CSS styles


function Footer() {

  return (

    <footer className="footer-container">

      <FooterMenu />

      <div style={{ textAlign: 'center', marginTop: '10px' }}>

        © {new Date().getFullYear()} Renew Lending. All rights reserved.

      </div>

    </footer>

  );

}


export default Footer;

