import React from 'react';

import { Box, Typography, Button, Grid, Avatar } from '@mui/material';


const SubmissionConfirmation = ({ formData, selectedDesign, onEdit, onConfirm }) => {

  return (

    <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 1, textAlign: 'center' }}>

      <Typography variant="h6" gutterBottom>

        Confirm Your Email Signature Details

      </Typography>

      {formData.picture && <Avatar src={URL.createObjectURL(formData.picture)} sx={{ width: 56, height: 56, margin: '0 auto' }} />}

      <Typography variant="h6">{formData.fullName}</Typography>

      <Typography variant="subtitle1">{formData.title}</Typography>

      <Typography>{formData.phone}</Typography>

      {formData.secondPhone && <Typography>{formData.secondPhone}</Typography>}

      <Typography>{formData.email}</Typography>

      {formData.secondEmail && <Typography>{formData.secondEmail}</Typography>}

      <Typography>{formData.website}</Typography>

      <Typography>{formData.address}</Typography>

      <Typography>NMLS: {formData.nmls}</Typography>

      {formData.specialRequests && <Typography variant="body2">Special Requests: {formData.specialRequests}</Typography>}


      <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 2 }}>

        <Grid item>

          <Button variant="outlined" color="primary" onClick={onEdit}>

            Edit

          </Button>

        </Grid>

        <Grid item>

          <Button variant="contained" color="primary" onClick={onConfirm}>

            Confirm

          </Button>

        </Grid>

      </Grid>

    </Box>

  );

};


export default SubmissionConfirmation;

