import React, { useState, useEffect, useContext, useRef } from 'react';

import {

  Paper, Typography, TextField, Box, InputAdornment, IconButton,

  Collapse, Snackbar, Alert, MenuItem, Table, TableBody, TableCell, TableContainer,

  TableHead, TableRow, TablePagination, Link, Tooltip, Checkbox, TableSortLabel, Tabs, Tab, Button

} from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';

import SmsIcon from '@mui/icons-material/Sms';

import EmailIcon from '@mui/icons-material/Email';

import EventIcon from '@mui/icons-material/Event';

import AssignmentIcon from '@mui/icons-material/Assignment';

import InfoIcon from '@mui/icons-material/Info';

import SearchIcon from '@mui/icons-material/Search';

import io from 'socket.io-client';

import axios from 'axios';

import PhoneWindow from './PhoneWindow';

import Loading from './Loading';

import AuthContext from '../Auth/AuthContext';

import ContactLead from './ContactLead';

import AdvancedFilter from './AdvancedFilter';

import LeadAlert from './LeadAlert';

import NewLeadNotification from './NewLeadNotification';

import CallsForToday from './CallsForToday';

import Manager from './Manager';

import SettingsMenu from './SettingsMenu';

import IncomingPhone from './IncomingPhone';

import './Phone.css';


const tealColor = '#3AAFA9';


const CallCenterMode = () => {

  const { user } = useContext(AuthContext);

  const leadsPerPage = 25;

  const [mrcLeads, setMrcLeads] = useState([]);

  const [otherLeads, setOtherLeads] = useState([]);

  const [totalLeads, setTotalLeads] = useState(0);

  const [leadStatuses, setLeadStatuses] = useState({});

  const [expandedLeadId, setExpandedLeadId] = useState(null);

  const [notes, setNotes] = useState({});

  const [newNote, setNewNote] = useState({});

  const [saving, setSaving] = useState({});

  const [saved, setSaved] = useState({});

  const [socket, setSocket] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');

  const [sortConfig, setSortConfig] = useState({ field: 'createdAt', direction: 'desc' });

  const [currentPage, setCurrentPage] = useState(0);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [currentLead, setCurrentLead] = useState(null);

  const [currentLeadIndex, setCurrentLeadIndex] = useState(0);

  const [loading, setLoading] = useState(true);

  const [filter, setFilter] = useState({

    leadSource: '',

    disposition: '',

    lastStatus: '',

    createdTime: '',

    state: '',

    propertyType: '',

    creditUtilization: '',

    ltv: '',

    callAttempts: '',

  });

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const [selectedLeads, setSelectedLeads] = useState([]);

  const intervalRef = useRef(null);

  const [newLead, setNewLead] = useState(null);

  const [isCreating, setIsCreating] = useState(false);

  const [showSettingsMenu, setShowSettingsMenu] = useState(false);


  useEffect(() => {

    const fetchInitialData = async () => {

      if (!user || !user._id) {

        console.error('User not authenticated');

        return;

      }


      try {

        const mrcResponse = await axios.get('/api/leads/mrc', { withCredentials: true });

        const mrcLeadsData = await Promise.all(mrcResponse.data.map(async lead => {

          if (lead.assigned_loan_officer) {

            const officer = await axios.get(`/api/user/${lead.assigned_loan_officer}`, { withCredentials: true });

            return {

              ...lead,

              lead_source: 'MRC',

              assignedLoanOfficerName: `${officer.data.firstName} ${officer.data.lastName}`,

            };

          }

          return {

            ...lead,

            lead_source: 'MRC',

            assignedLoanOfficerName: 'Unassigned',

          };

        }));

        setMrcLeads(mrcLeadsData);


        let otherLeadsResponse;

        if (user.role === 'Admin') {

          otherLeadsResponse = await axios.get('/api/leads/all', { withCredentials: true });

        } else {

          otherLeadsResponse = await axios.get(`/api/leads/assigned-leads/${user._id}`, { withCredentials: true });

        }


        const otherLeadsData = await Promise.all(otherLeadsResponse.data.map(async lead => {

          if (lead.assigned_loan_officer) {

            const officer = await axios.get(`/api/user/${lead.assigned_loan_officer}`, { withCredentials: true });

            return {

              ...lead,

              lead_source: lead.lead_source || 'Assigned',

              assignedLoanOfficerName: `${officer.data.firstName} ${officer.data.lastName}`,

            };

          }

          return {

            ...lead,

            lead_source: lead.lead_source || 'Assigned',

            assignedLoanOfficerName: 'Unassigned',

          };

        }));

        setOtherLeads(otherLeadsData);

        setTotalLeads(mrcLeadsData.length + otherLeadsData.length);

        setLoading(false);

      } catch (error) {

        console.error('Error fetching leads:', error);

      }

    };


    const setupWebSocket = () => {

      const newSocket = io('https://www.renewriff.com:5000', { withCredentials: true });

      setSocket(newSocket);


      newSocket.on('connect', () => {

        console.log('Socket connected');

        setLoading(false);


        if (user && user._id) {

          if (user.role === 'Admin') {

            newSocket.emit('fetchAllLeads');

          } else {

            newSocket.emit('fetchAssignedLeads', user._id);

          }

        }


        if (intervalRef.current) clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {

          if (newSocket.connected && user && user._id) {

            if (user.role === 'Admin') {

              newSocket.emit('fetchAllLeads');

            } else {

              newSocket.emit('fetchAssignedLeads', user._id);

            }

          }

        }, 180000); // 3 minutes interval

      });


      newSocket.on('newMRCLead', (newLead) => {

        setMrcLeads((prevLeads) => [newLead, ...prevLeads]);

        setTotalLeads((prevTotal) => prevTotal + 1);

        setNewLead(newLead);

      });


      newSocket.on('newLeadAssigned', (newLead) => {

        setOtherLeads((prevLeads) => [newLead, ...prevLeads]);

        setTotalLeads((prevTotal) => prevTotal + 1);

        setNewLead(newLead);

      });


      newSocket.on('leadUpdated', (updatedLead) => {

        setNotes((prevNotes) => ({

          ...prevNotes,

          [updatedLead._id]: updatedLead.callAttempts || [],

        }));

      });


      newSocket.on('callMade', (response) => {

        if (response.success) {

          alert('Call started successfully');

        } else {

          alert('Failed to start call');

        }

      });


      newSocket.on('smsSent', (response) => {

        if (response.success) {

          alert('SMS sent successfully');

        } else {

          alert('Failed to send SMS');

        }

      });


      newSocket.on('error', (message) => {

        console.error(message);

      });


      newSocket.on('disconnect', () => {

        console.log('Socket disconnected');

        setLoading(false);

        if (intervalRef.current) clearInterval(intervalRef.current);

      });


      return () => {

        newSocket.close();

        if (intervalRef.current) clearInterval(intervalRef.current);

      };

    };


    fetchInitialData();

    setupWebSocket();

  }, [user]);


  const updateStatus = (leadId, status) => {

    setLeadStatuses({

      ...leadStatuses,

      [leadId]: status,

    });

    setSaved((prevSaved) => ({

      ...prevSaved,

      [leadId]: false,

    }));

    setSaving((prevSaving) => ({

      ...prevSaving,

      [leadId]: false,

    }));

  };


  const handleToggleExpand = (leadId) => {

    setExpandedLeadId(expandedLeadId === leadId ? null : leadId);

  };


  const handleAddNote = (leadId, note) => {

    setNewNote((prevNewNote) => ({

      ...prevNewNote,

      [leadId]: note,

    }));

  };


  const saveCallAttempt = (leadId) => {

    let disposition = leadStatuses[leadId];

    const note = newNote[leadId] || 'No notes entered';

    const timestamp = new Date();


    if (socket && disposition) {

      socket.emit('updateLead', { leadId, disposition, note, timestamp }, (response) => {

        if (response && response.success) {

          setSaving((prevSaving) => ({

            ...prevSaving,

            [leadId]: true,

          }));


          setNotes((prevNotes) => {

            const updatedNotes = prevNotes[leadId] ? [...prevNotes[leadId], { disposition, note, timestamp }] : [{ disposition, note, timestamp }];

            return {

              ...prevNotes,

              [leadId]: updatedNotes,

            };

          });


          setNewNote((prevNewNote) => ({

            ...prevNewNote,

            [leadId]: '',

          }));


          setSaved((prevSaved) => ({

            ...prevSaved,

            [leadId]: true,

          }));

          setSnackbarOpen(true);

        } else {

          console.error('Error saving note:', response ? response.message : 'No response from server');

        }

      });

    } else {

      console.warn('Missing required data to save call attempt');

    }

  };


  const openContactLead = (lead, tab) => {

    setCurrentLead(lead);

    setActiveTab(tab);

  };


  const handleSearchChange = (event) => {

    setSearchQuery(event.target.value);

  };


  const handleFilterChange = (event) => {

    const { name, value } = event.target;

    setFilter({

      ...filter,

      [name]: value,

    });

  };


  const handleSortChange = (field) => {

    setSortConfig((prevConfig) => ({

      field,

      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'

    }));

  };


  const handleResetFilters = () => {

    setFilter({

      leadSource: '',

      disposition: '',

      lastStatus: '',

      createdTime: '',

      state: '',

      propertyType: '',

      creditUtilization: '',

      ltv: '',

      callAttempts: '',

    });

    setSearchQuery('');

    setSortConfig({ field: 'createdAt', direction: 'desc' });

  };


  const handleSelectLead = (leadId) => {

    setSelectedLeads((prevSelected) =>

      prevSelected.includes(leadId) ? prevSelected.filter((id) => id !== leadId) : [...prevSelected, leadId]

    );

  };


  const handleSelectAllLeads = () => {

    const leads = activeTab === 0 ? mrcLeads : otherLeads;

    if (selectedLeads.length === leads.length) {

      setSelectedLeads([]);

    } else {

      setSelectedLeads(leads.map((lead) => lead._id));

    }

  };


  const handleCreateLead = () => {

    setIsCreating(true);

  };


  const getLastCallAttempt = (lead) => {

    if (lead.callAttempts && lead.callAttempts.length > 0) {

      return new Date(lead.callAttempts[lead.callAttempts.length - 1].timestamp);

    }

    return null;

  };


  const filteredLeads = (activeTab === 0 ? mrcLeads : otherLeads).filter((lead) => {

    const matchesSearch = (lead.FNAME?.toLowerCase().includes(searchQuery.toLowerCase()) || lead.LNAME?.toLowerCase().includes(searchQuery.toLowerCase()) || lead.first_name?.toLowerCase().includes(searchQuery.toLowerCase()) || lead.last_name?.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesLeadSource = filter.leadSource ? lead.lead_source === filter.leadSource : true;

    const matchesDisposition = filter.disposition === 'New Lead'

      ? !lead.callAttempts || lead.callAttempts.length === 0

      : filter.disposition

        ? lead.callAttempts && lead.callAttempts.some((attempt) => attempt.disposition.includes(filter.disposition))

        : true;

    const matchesLastStatus = filter.lastStatus ? lead.callAttempts && lead.callAttempts.some((attempt) => attempt.disposition === filter.lastStatus) : true;

    const matchesCreatedTime = filter.createdTime ? new Date(lead.createdAt) >= new Date(Date.now() - filter.createdTime * 60 * 60 * 1000) : true;

    const matchesState = filter.state ? lead.property_state === filter.state || lead.state === filter.state : true; // Use property_state

    const matchesPropertyType = filter.property_type ? lead.property_type === filter.property_type : true;

    const matchesCreditUtilization = filter.credit_utilization ? lead.credit_utilization === filter.credit_utilization : true;

    const matchesLtv = filter.ltv ? lead.ltv === filter.ltv : true;

    const matchesCallAttempts = filter.callAttempts === 'yes' ? lead.callAttempts && lead.callAttempts.length > 0 : filter.callAttempts === 'no' ? !lead.callAttempts || lead.callAttempts.length === 0 : true;


    return matchesSearch && matchesLeadSource && matchesDisposition && matchesLastStatus && matchesCreatedTime && matchesState && matchesPropertyType && matchesCreditUtilization && matchesLtv && matchesCallAttempts;

  });


  const sortedLeads = filteredLeads.sort((a, b) => {

    let fieldA = a[sortConfig.field] ?? '';

    let fieldB = b[sortConfig.field] ?? '';


    if (sortConfig.field === 'lastCallAttempt') {

      fieldA = getLastCallAttempt(a) ?? '';

      fieldB = getLastCallAttempt(b) ?? '';

    } else if (sortConfig.field === 'lastDisposition') {

      fieldA = a.callAttempts && a.callAttempts.length > 0 ? a.callAttempts[a.callAttempts.length - 1].disposition : '';

      fieldB = b.callAttempts && b.callAttempts.length > 0 ? b.callAttempts[b.callAttempts.length - 1].disposition : '';

    } else if (sortConfig.field === 'first_name') {

      fieldA = a.first_name || a.FNAME || '';

      fieldB = b.first_name || b.FNAME || '';

    } else if (sortConfig.field === 'last_name') {

      fieldA = a.last_name || a.LNAME || '';

      fieldB = b.last_name || b.LNAME || '';

    }


    if (fieldA < fieldB) return sortConfig.direction === 'asc' ? -1 : 1;

    if (fieldA > fieldB) return sortConfig.direction === 'asc' ? 1 : -1;

    return 0;

  });


  const paginatedLeads = sortedLeads.slice(currentPage * leadsPerPage, (currentPage + 1) * leadsPerPage);


  if (loading) {

    return <Loading checkWebSocket />;

  }


  const currentLeads = activeTab === 0 ? mrcLeads : otherLeads;


  const tabs = [

    { label: 'MRC Leads' },

    { label: 'All Leads' },

    { label: 'Calls for Today' },

    { label: 'Scheduled Calls' },

    { label: 'Charts and Reports' },

  ];


  if (user.role === 'Manager' || user.role === 'Admin') {

    tabs.push({ label: 'Manager' });

  }


  if (user.role === 'Admin') {

    tabs.push({ label: 'Admin' });

  }


  return (

    <Box>

      <LeadAlert socket={socket} />

      {!currentLead && !isCreating && (

        <>

          <Tabs value={activeTab} onChange={(event, newValue) => {

            if (tabs[newValue].label === 'Admin') {

              setShowSettingsMenu(true);

            } else {

              setShowSettingsMenu(false);

              setActiveTab(newValue);

            }

          }} aria-label="lead tabs" sx={{ '& .Mui-selected': { color: tealColor } }}>

            {tabs.map((tab, index) => (

              <Tab key={index} label={tab.label} sx={{ color: activeTab === index ? tealColor : 'default' }} />

            ))}

          </Tabs>

          {showSettingsMenu && <SettingsMenu />}

        </>

      )}


      {currentLead ? (

        <ContactLead lead={currentLead} onClose={() => setCurrentLead(null)} activeTab={activeTab} setActiveTab={setActiveTab} />

      ) : isCreating ? (

        <ContactLead lead={{}} onClose={() => setIsCreating(false)} activeTab={activeTab} setActiveTab={setActiveTab} isCreating />

      ) : activeTab === 2 ? (

        <CallsForToday onLeadSelect={openContactLead} />

      ) : activeTab === 5 && user.role === 'Manager' ? (

        <Manager />

      ) : activeTab === 6 && user.role === 'Admin' ? (

        <Manager />

      ) : (

        <>

          <Typography variant="h4" gutterBottom>Call Center Mode</Typography>

          <AdvancedFilter

            filter={filter}

            handleFilterChange={handleFilterChange}

            handleResetFilters={handleResetFilters}

            handleToggleAdvancedFilters={() => setShowAdvancedFilters(!showAdvancedFilters)}

            showAdvancedFilters={showAdvancedFilters}

            searchQuery={searchQuery}

            handleSearchChange={handleSearchChange}

            sortConfig={sortConfig}

            handleSortChange={handleSortChange}

            handleCreateLead={handleCreateLead}

          />


          <TableContainer component={Paper}>

            <Table>

              <TableHead>

                <TableRow>

                  <TableCell padding="checkbox">

                    <Checkbox

                      indeterminate={selectedLeads.length > 0 && selectedLeads.length < currentLeads.length}

                      checked={currentLeads.length > 0 && selectedLeads.length === currentLeads.length}

                      onChange={handleSelectAllLeads}

                      inputProps={{ 'aria-label': 'select all leads' }}

                    />

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'first_name'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('first_name')}

                    >

                      First Name

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'last_name'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('last_name')}

                    >

                      Last Name

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'premium_phone'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('premium_phone')}

                    >

                      Phone Number

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'lastDisposition'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('lastDisposition')}

                    >

                      Status

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'lastCallAttempt'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('lastCallAttempt')}

                    >

                      Last Call Attempt

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'state'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('state')}

                    >

                      State

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'lead_source'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('lead_source')}

                    >

                      Lead Source

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'createdAt'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('createdAt')}

                    >

                      Created Date

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'isDNC'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('isDNC')}

                    >

                      DNC Status

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel

                      active={sortConfig.field === 'assignedLoanOfficerName'}

                      direction={sortConfig.direction}

                      onClick={() => handleSortChange('assignedLoanOfficerName')}

                    >

                      Assigned Loan Officer

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>Actions</TableCell>

                </TableRow>

              </TableHead>

              <TableBody>

                {paginatedLeads.map((lead) => (

                  <TableRow key={lead._id}>

                    <TableCell padding="checkbox">

                      <Checkbox

                        checked={selectedLeads.includes(lead._id)}

                        onChange={() => handleSelectLead(lead._id)}

                        inputProps={{ 'aria-labelledby': `lead-checkbox-${lead._id}` }}

                      />

                    </TableCell>

                    <TableCell>

                      <Link href="#" onClick={() => openContactLead(lead, 'details')} sx={{ color: tealColor }}>

                        {lead.first_name || lead.FNAME}

                      </Link>

                    </TableCell>

                    <TableCell>

                      <Link href="#" onClick={() => openContactLead(lead, 'details')} sx={{ color: tealColor }}>

                        {lead.last_name || lead.LNAME}

                      </Link>

                    </TableCell>

                    <TableCell>

                      <Link href="#" onClick={() => openContactLead(lead, 'phone')} sx={{ color: tealColor }}>

                        {lead.premium_phone || lead.phone_home || lead.phone_work || lead.phone_cell}

                      </Link>

                    </TableCell>

                    <TableCell>{lead.callAttempts && lead.callAttempts.length > 0 ? lead.callAttempts[lead.callAttempts.length - 1].disposition : 'New Lead'}</TableCell>

                    <TableCell>{lead.callAttempts && lead.callAttempts.length > 0 ? new Date(lead.callAttempts[lead.callAttempts.length - 1].timestamp).toLocaleString() : 'No Calls'}</TableCell>

                    <TableCell>{lead.property_state || lead.STATE || lead.state}</TableCell> {/* Display property_state */}

                    <TableCell>{lead.lead_source || lead.source}</TableCell>

                    <TableCell>{new Date(lead.createdAt).toLocaleString()}</TableCell>

                    <TableCell>

                      <Typography color={lead.isDNC ? 'error' : 'primary'}>

                        {lead.isDNC ? 'DNC' : <span style={{ color: 'green' }}>Clear</span>}

                      </Typography>

                    </TableCell>

                    <TableCell>{lead.assignedLoanOfficerName}</TableCell>

                    <TableCell>

                      <Tooltip title="Phone">

                        <IconButton onClick={() => openContactLead(lead, 'phone')} sx={{ color: tealColor }}>

                          <PhoneIcon />

                        </IconButton>

                      </Tooltip>

                      <Tooltip title="Email">

                        <IconButton onClick={() => openContactLead(lead, 'email')} sx={{ color: tealColor }}>

                          <EmailIcon />

                        </IconButton>

                      </Tooltip>

                      <Tooltip title="SMS">

                        <IconButton onClick={() => openContactLead(lead, 'sms')} sx={{ color: tealColor }}>

                          <SmsIcon />

                        </IconButton>

                      </Tooltip>

                      <Tooltip title="Calendar">

                        <IconButton onClick={() => openContactLead(lead, 'calendar')} sx={{ color: tealColor }}>

                          <EventIcon />

                        </IconButton>

                      </Tooltip>

                      <Tooltip title="Disposition">

                        <IconButton onClick={() => openContactLead(lead, 'disposition')} sx={{ color: tealColor }}>

                          <AssignmentIcon />

                        </IconButton>

                      </Tooltip>

                      <Tooltip title="Details">

                        <IconButton onClick={() => openContactLead(lead, 'details')} sx={{ color: tealColor }}>

                          <InfoIcon />

                        </IconButton>

                      </Tooltip>

                    </TableCell>

                  </TableRow>

                ))}

              </TableBody>

            </Table>

          </TableContainer>


          <TablePagination

            component="div"

            count={totalLeads}

            page={currentPage}

            onPageChange={(event, newPage) => setCurrentPage(newPage)}

            rowsPerPage={leadsPerPage}

            rowsPerPageOptions={[leadsPerPage]}

          />


          <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={() => setSnackbarOpen(false)}>

            <Alert severity="success" onClose={() => setSnackbarOpen(false)}>

              Note saved successfully!

            </Alert>

          </Snackbar>

        </>

      )}


      {newLead && (

        <NewLeadNotification lead={newLead} onClose={() => setNewLead(null)} />

      )}


      <IncomingPhone setCurrentLead={setCurrentLead} />

    </Box>

  );

};


export default CallCenterMode;

