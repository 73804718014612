import React from 'react';

import IconButton from '@mui/material/IconButton';

import HomeIcon from '@mui/icons-material/Home';

import SearchIcon from '@mui/icons-material/Search';

import NotificationIcon from '@mui/icons-material/Notifications';


function ThinLeftMenuTop() {

  return (

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <IconButton aria-label="home" style={{ color: '#46A9AF' }}>

        <HomeIcon />

      </IconButton>

      <IconButton aria-label="search" style={{ color: '#46A9AF' }}>

        <SearchIcon />

      </IconButton>

      <IconButton aria-label="notifications" style={{ color: '#46A9AF' }}>

        <NotificationIcon />

      </IconButton>

    </div>

  );

}


export default ThinLeftMenuTop;

