import React, { useState } from 'react';

import { Box, TextField, Button, Typography } from '@mui/material';


const Calendar = ({ lead }) => {

  const [events, setEvents] = useState([]);

  const [newEvent, setNewEvent] = useState({

    title: '',

    date: '',

    description: '',

  });


  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setNewEvent({ ...newEvent, [name]: value });

  };


  const addEvent = () => {

    setEvents([...events, newEvent]);

    setNewEvent({ title: '', date: '', description: '' });

  };


  return (

    <Box>

      <Typography variant="h4">Calendar</Typography>

      <Box sx={{ marginBottom: 2 }}>

        <TextField

          label="Title"

          name="title"

          value={newEvent.title}

          onChange={handleInputChange}

          fullWidth

        />

        <TextField

          label="Date"

          name="date"

          type="date"

          value={newEvent.date}

          onChange={handleInputChange}

          fullWidth

          sx={{ marginTop: 2 }}

        />

        <TextField

          label="Description"

          name="description"

          value={newEvent.description}

          onChange={handleInputChange}

          fullWidth

          multiline

          rows={4}

          sx={{ marginTop: 2 }}

        />

        <Button variant="contained" onClick={addEvent} sx={{ marginTop: 2 }}>

          Add Event

        </Button>

      </Box>

      {events.map((event, index) => (

        <Box key={index} sx={{ marginBottom: 2 }}>

          <Typography variant="body1">{event.title}</Typography>

          <Typography variant="body2">{event.date}</Typography>

          <Typography variant="body2">{event.description}</Typography>

        </Box>

      ))}

    </Box>

  );

};


export default Calendar;

