// ContactLoanApp.jsx

import React, { useState } from 'react';

import { TextField, Button, Box, Typography, Container } from '@mui/material';

import { useNavigate } from 'react-router-dom';


function ContactLoanApp() {

    const [contactInfo, setContactInfo] = useState({

        firstName: '',

        lastName: '',

        email: '',

        cellphone: ''

    });

    const navigate = useNavigate();


    const handleChange = (e) => {

        const { name, value } = e.target;

        setContactInfo((prev) => ({

            ...prev,

            [name]: value

        }));

    };


    const handleNext = (e) => {

        e.preventDefault();

        navigate('/loan-and-property-info', { state: { contactInfo } });

    };


    return (

        <Container component="main" maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>

            <Typography component="h1" variant="h5">

                Enter Your Contact Information

            </Typography>

            <Box component="form" onSubmit={handleNext} sx={{ mt: 1, width: '100%', maxWidth: 360, bgcolor: 'background.paper', boxShadow: 3, padding: 4, borderRadius: 2 }}>

                <TextField

                    margin="normal"

                    required

                    fullWidth

                    id="firstName"

                    label="First Name"

                    name="firstName"

                    value={contactInfo.firstName}

                    onChange={handleChange}

                    autoComplete="given-name"

                />

                <TextField

                    margin="normal"

                    required

                    fullWidth

                    id="lastName"

                    label="Last Name"

                    name="lastName"

                    value={contactInfo.lastName}

                    onChange={handleChange}

                    autoComplete="family-name"

                />

                <TextField

                    margin="normal"

                    required

                    fullWidth

                    id="email"

                    label="Email Address"

                    name="email"

                    value={contactInfo.email}

                    onChange={handleChange}

                    autoComplete="email"

                />

                <TextField

                    margin="normal"

                    required

                    fullWidth

                    id="cellphone"

                    label="Cellphone"

                    name="cellphone"

                    value={contactInfo.cellphone}

                    onChange={handleChange}

                    autoComplete="tel"

                />

                <Button

                    type="submit"

                    fullWidth

                    variant="contained"

                    sx={{ mt: 3, mb: 2 }}

                >

                    Next

                </Button>

            </Box>

        </Container>

    );

}


export default ContactLoanApp;

