import React, { useEffect, useState } from "react";

import { Device } from "@twilio/voice-sdk";

import { Box, Typography, Dialog, DialogTitle, DialogActions, IconButton, Button } from "@mui/material";

import CallIcon from '@mui/icons-material/Call';

import MicOffIcon from '@mui/icons-material/MicOff';

import CallEndIcon from '@mui/icons-material/CallEnd';

import axios from 'axios';

import { getToken } from './services'; // Import the getToken function


const USER_STATE = {

  CONNECTING: "Connecting",

  READY: "Ready",

  ON_CALL: "On call",

  OFFLINE: "Offline",

  RINGING: "Ringing",

};


const numberList = [1, 2, 3, 4, 5, 6, 7, 8, 9, "+", 0, "<<"];


const Timer = () => {

  const [timer, setTimer] = useState({ mins: 0, sec: 0 });


  const getTime = () => {

    setTimer((state) => ({

      mins: state.sec === 60 ? state.mins + 1 : state.mins,

      sec: state.sec === 60 ? 0 : state.sec + 1,

    }));

  };


  useEffect(() => {

    const interval = setInterval(getTime, 1000);

    return () => clearInterval(interval);

  }, []);


  return (

    <div className="timer">

      {`${timer.mins < 10 ? "0" + timer.mins : timer.mins} : ${timer.sec < 10 ? "0" + timer.sec : timer.sec}`}

    </div>

  );

};


const Phone = ({ lead, phoneNumber, onCallCompleted }) => {

  const [userState, setUserState] = useState(USER_STATE.OFFLINE);

  const [phone, setPhone] = useState(phoneNumber || "");

  const [connection, setConnection] = useState(null);

  const [callDevice, setDevice] = useState(undefined);

  const [mute, setMute] = useState(false);

  const [confirmHangup, setConfirmHangup] = useState(false);

  const [token, setToken] = useState("");

  const [loading, setLoading] = useState(true);

  const [callAttemptLogged, setCallAttemptLogged] = useState(false);


  useEffect(() => {

    if (lead) {

      setPhone(lead.premium_phone || lead.phone_home || lead.phone_work || lead.phone_cell || "");

    }

  }, [lead]);


  useEffect(() => {

    const fetchToken = async () => {

      try {

        const response = await getToken(); // Your function to fetch the token

        setToken(response.data.token);

        setLoading(false);

      } catch (error) {

        console.error('Error fetching token:', error);

        setLoading(false);

      }

    };


    fetchToken();

  }, []);


  useEffect(() => {

    if (!loading && token) {

      init();

    }

  }, [token, loading]);


  const init = async () => {

    if (token) {

      try {

        const device = new Device(token, {

          logLevel: 1,

          edge: "ashburn",

        });


        device.register();

        setDevice(device);


        device.on("registered", () => {

          setUserState(USER_STATE.READY);

        });


        device.on("connect", (conn) => {

          setConnection(conn);

          setUserState(USER_STATE.ON_CALL);

          setCallAttemptLogged(false); // Reset the flag when the call is connected

        });


        device.on("disconnect", () => {

          setUserState(USER_STATE.READY);

          setConnection(null);

          onCallCompleted(); // Notify parent component when the call is disconnected

        });


        device.on("error", (error) => {

          console.error("Twilio Device Error:", error);

        });


        return () => {

          device.destroy();

          setDevice(undefined);

          setUserState(USER_STATE.OFFLINE);

        };

      } catch (error) {

        console.log("Error", error);

      }

    }

  };


  const logCallAttempt = async (status) => {

    try {

      await axios.post(`/api/leads/${lead._id}/call-log`, {

        timestamp: new Date(),

        duration: 0, // Duration will be updated after the call ends

        recordingUrl: '', // Placeholder for now

        status: status,

      });

    } catch (error) {

      console.error('Error logging call attempt:', error);

    }

  };


  const handleCall = async () => {

    if (callDevice) {

      const params = { To: phone };

      setUserState(USER_STATE.RINGING);


      if (!callAttemptLogged) {

        logCallAttempt('Ringing'); // Log the call attempt

        setCallAttemptLogged(true); // Set the flag to true after logging the call attempt

      }


      callDevice.connect({ params }).then((call) => {

        call.on("accept", () => {

          setConnection(call);

          setUserState(USER_STATE.ON_CALL);

        });


        call.on("disconnect", () => {

          setUserState(USER_STATE.READY);

          setConnection(null);

          onCallCompleted(); // Notify parent component when the call is disconnected

        });


        call.on("reject", () => {

          console.log("The call was rejected.");

          setUserState(USER_STATE.READY);

        });


        call.on("error", (error) => {

          console.error("Call Error:", error);

          setUserState(USER_STATE.READY);

        });

      }).catch((error) => {

        console.error("Connection Error:", error);

        setUserState(USER_STATE.READY);

      });

    }

  };


  const handleHangup = () => {

    if (connection) {

      connection.disconnect();

      setUserState(USER_STATE.READY);

      setConnection(null);

      onCallCompleted(); // Notify parent component when the call is hung up

    }

  };


  const handleMute = () => {

    if (connection) {

      connection.mute(!mute);

      setMute(!mute);

    }

  };


  const handleConfirmHangup = () => {

    setConfirmHangup(true);

  };


  const confirmHangupAction = () => {

    handleHangup();

    setConfirmHangup(false);

  };


  const cancelHangupAction = () => {

    setConfirmHangup(false);

  };


  if (loading) {

    return (

      <Box className="phone" sx={{ width: '300px', margin: '0 auto', padding: '20px', borderRadius: '15px', backgroundColor: '#333', color: '#fff' }}>

        <Typography variant="h6" gutterBottom>Loading...</Typography>

      </Box>

    );

  }


  return (

    <Box className="phone" sx={{ width: '300px', margin: '0 auto', padding: '20px', borderRadius: '15px', backgroundColor: '#333', color: '#fff' }}>

      <Typography variant="h6" gutterBottom>

        {userState === USER_STATE.ON_CALL

          ? "On Call"

          : userState === USER_STATE.RINGING

            ? "Ringing"

            : "Dial"}

      </Typography>

      <input

        className="number-input"

        value={phone}

        onChange={(event) => setPhone(event.target.value)}

        style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px', border: 'none' }}

      />

      {userState === USER_STATE.ON_CALL ? (

        <Timer />

      ) : (

        <Box className="grid" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', marginBottom: '20px' }}>

          {numberList.map((value) => (

            <Box

              key={value}

              className="number"

              onClick={() => {

                if (value === "<<") {

                  setPhone(phone.substring(0, phone.length - 1));

                } else {

                  setPhone(phone + value);

                }

              }}

              sx={{ padding: '20px', backgroundColor: '#555', borderRadius: '5px', textAlign: 'center', cursor: 'pointer' }}

            >

              {value}

            </Box>

          ))}

        </Box>

      )}

      <Box className="buttons" sx={{ display: 'flex', justifyContent: 'space-around' }}>

        <IconButton onClick={handleCall} sx={{ color: '#3AAFA9' }}>

          <CallIcon />

        </IconButton>

        <IconButton onClick={handleMute} sx={{ color: mute ? 'gray' : '#3AAFA9' }}>

          <MicOffIcon />

        </IconButton>

        <IconButton onClick={handleConfirmHangup} sx={{ color: '#3AAFA9' }}>

          <CallEndIcon />

        </IconButton>

      </Box>

      <Dialog open={confirmHangup} onClose={cancelHangupAction}>

        <DialogTitle>Confirm Hangup</DialogTitle>

        <DialogActions>

          <Button onClick={confirmHangupAction} color="primary">Yes</Button>

          <Button onClick={cancelHangupAction} color="primary">No</Button>

        </DialogActions>

      </Dialog>

    </Box>

  );

};


export default Phone;

