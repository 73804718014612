import React, { useState, useEffect } from 'react';

import {

  Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,

  Link, Tooltip, IconButton, Box, Grid, Avatar, Switch, FormControlLabel, Checkbox

} from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';

import SmsIcon from '@mui/icons-material/Sms';

import EmailIcon from '@mui/icons-material/Email';

import EventIcon from '@mui/icons-material/Event';

import AssignmentIcon from '@mui/icons-material/Assignment';

import InfoIcon from '@mui/icons-material/Info';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import DoneAllIcon from '@mui/icons-material/DoneAll';

import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import io from 'socket.io-client';

import axios from 'axios';


const tealColor = '#3AAFA9';


const CallsForToday = ({ onLeadSelect }) => {

  const [leads, setLeads] = useState([]);

  const [hiddenLeads, setHiddenLeads] = useState([]);

  const [showHiddenLeads, setShowHiddenLeads] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const [calledLeadsCount, setCalledLeadsCount] = useState(0);

  const [averageWaitTime, setAverageWaitTime] = useState(0);

  const [sortLocked, setSortLocked] = useState(true);

  const [newLeadsCount, setNewLeadsCount] = useState(0);

  const [newLeads, setNewLeads] = useState([]);

  const [currentTime, setCurrentTime] = useState(new Date());

  const leadsPerPage = 25;


  useEffect(() => {

    const fetchLeads = async () => {

      try {

        const response = await axios.get('/api/leads/calls-for-today');

        const fetchedLeads = response.data;


        const calledLeads = fetchedLeads.filter(lead => lead.callAttempts && lead.callAttempts.length > 0);

        setCalledLeadsCount(calledLeads.length);


        const totalWaitTime = fetchedLeads.reduce((total, lead) => {

          const createdAt = new Date(lead.createdAt).getTime();

          const now = Date.now();

          return total + (now - createdAt);

        }, 0);


        setAverageWaitTime(totalWaitTime / fetchedLeads.length);


        const newLeadsToday = fetchedLeads.filter(lead => {

          const createdAt = new Date(lead.createdAt);

          return createdAt >= new Date().setHours(8, 0, 0) && createdAt <= new Date().setHours(18, 0, 0);

        }).length;

        setNewLeadsCount(newLeadsToday);


        setLeads(fetchedLeads);

      } catch (error) {

        console.error('Error fetching leads:', error);

      }

    };


    fetchLeads();


    // Setup socket connection

    const socket = io('https://www.renewriff.com:5000');


    // Handle new lead

    socket.on('newLead', (newLead) => {

      setLeads((prevLeads) => {

        const updatedLeads = [...prevLeads, newLead];

        updatedLeads.sort((a, b) => {

          if (a.lead_source === 'MRC' && b.lead_source !== 'MRC') return -1;

          if (a.lead_source !== 'MRC' && b.lead_source === 'MRC') return 1;

          return new Date(a.createdAt) - new Date(b.createdAt);

        });

        return updatedLeads;

      });

    });


    // Handle new MRC lead

    socket.on('newMRCLead', (newMRCLead) => {

      setLeads((prevLeads) => {

        const updatedLeads = [...prevLeads, newMRCLead];

        updatedLeads.sort((a, b) => {

          if (a.lead_source === 'MRC' && b.lead_source !== 'MRC') return -1;

          if (a.lead_source !== 'MRC' && b.lead_source === 'MRC') return 1;

          return new Date(a.createdAt) - new Date(b.createdAt);

        });

        return updatedLeads;

      });

    });


    // Update current time every second

    const timer = setInterval(() => {

      setCurrentTime(new Date());

    }, 1000);


    return () => {

      socket.disconnect();

      clearInterval(timer);

    };

  }, []);


  const handleOnDragEnd = (result) => {

    if (!result.destination) return;


    const items = Array.from(leads);

    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);


    setLeads(items);

  };


  const handleToggleSortLock = () => {

    setSortLocked(!sortLocked);

  };


  const handleToggleShowHiddenLeads = () => {

    setShowHiddenLeads(!showHiddenLeads);

  };


  const handleToggleHideLead = (leadId) => {

    setHiddenLeads(prevHiddenLeads =>

      prevHiddenLeads.includes(leadId) ? prevHiddenLeads.filter(id => id !== leadId) : [...prevHiddenLeads, leadId]

    );

  };


  const getLeadTimeZone = (state) => {

    const timeZones = {

      'AL': 'America/Chicago', 'AK': 'America/Anchorage', 'AZ': 'America/Phoenix',

      'AR': 'America/Chicago', 'CA': 'America/Los_Angeles', 'CO': 'America/Denver',

      'CT': 'America/New_York', 'DE': 'America/New_York', 'FL': 'America/New_York',

      'GA': 'America/New_York', 'HI': 'Pacific/Honolulu', 'ID': 'America/Boise',

      'IL': 'America/Chicago', 'IN': 'America/Indiana/Indianapolis', 'IA': 'America/Chicago',

      'KS': 'America/Chicago', 'KY': 'America/New_York', 'LA': 'America/Chicago',

      'ME': 'America/New_York', 'MD': 'America/New_York', 'MA': 'America/New_York',

      'MI': 'America/Detroit', 'MN': 'America/Chicago', 'MS': 'America/Chicago',

      'MO': 'America/Chicago', 'MT': 'America/Denver', 'NE': 'America/Chicago',

      'NV': 'America/Los_Angeles', 'NH': 'America/New_York', 'NJ': 'America/New_York',

      'NM': 'America/Denver', 'NY': 'America/New_York', 'NC': 'America/New_York',

      'ND': 'America/Chicago', 'OH': 'America/New_York', 'OK': 'America/Chicago',

      'OR': 'America/Los_Angeles', 'PA': 'America/New_York', 'RI': 'America/New_York',

      'SC': 'America/New_York', 'SD': 'America/Chicago', 'TN': 'America/Chicago',

      'TX': 'America/Chicago', 'UT': 'America/Denver', 'VT': 'America/New_York',

      'VA': 'America/New_York', 'WA': 'America/Los_Angeles', 'WV': 'America/New_York',

      'WI': 'America/Chicago', 'WY': 'America/Denver'

    };

    return timeZones[state] || 'America/New_York';

  };


  const getCurrentTimeForTimeZone = (timeZone) => {

    return new Date(new Date().toLocaleString('en-US', { timeZone }));

  };


  const paginatedLeads = leads.slice(currentPage * leadsPerPage, (currentPage + 1) * leadsPerPage);


  return (

    <Box>

      <Grid container spacing={3} alignItems="center" sx={{ mb: 3 }}>

        <Grid item xs={12} sm={6} md={3}>

          <Paper elevation={3} sx={{ p: 2, textAlign: 'center', backgroundColor: tealColor, color: '#fff' }}>

            <Avatar sx={{ bgcolor: '#fff', color: tealColor, mb: 1 }}>

              <DoneAllIcon />

            </Avatar>

            <Typography variant="h6">Leads Called</Typography>

            <Typography variant="h4">{calledLeadsCount}</Typography>

          </Paper>

        </Grid>

        <Grid item xs={12} sm={6} md={3}>

          <Paper elevation={3} sx={{ p: 2, textAlign: 'center', backgroundColor: tealColor, color: '#fff' }}>

            <Avatar sx={{ bgcolor: '#fff', color: tealColor, mb: 1 }}>

              <HourglassEmptyIcon />

            </Avatar>

            <Typography variant="h6">Leads Waiting</Typography>

            <Typography variant="h4">{leads.length - calledLeadsCount}</Typography>

          </Paper>

        </Grid>

        <Grid item xs={12} sm={6} md={3}>

          <Paper elevation={3} sx={{ p: 2, textAlign: 'center', backgroundColor: tealColor, color: '#fff' }}>

            <Avatar sx={{ bgcolor: '#fff', color: tealColor, mb: 1 }}>

              <AccessTimeIcon />

            </Avatar>

            <Typography variant="h6">Average Wait Time</Typography>

            <Typography variant="h4">{Math.round(averageWaitTime / (1000 * 60 * 60 * 24))} days</Typography>

          </Paper>

        </Grid>

        <Grid item xs={12} sm={6} md={3}>

          <Paper elevation={3} sx={{ p: 2, textAlign: 'center', backgroundColor: tealColor, color: '#fff' }}>

            <Avatar sx={{ bgcolor: '#fff', color: tealColor, mb: 1 }}>

              <AccessTimeIcon />

            </Avatar>

            <Typography variant="h6">New Leads Today</Typography>

            <Typography variant="h4">{newLeadsCount}</Typography>

          </Paper>

        </Grid>

      </Grid>


      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>

        <Typography variant="h4">Calls For Today</Typography>

        <Box display="flex" alignItems="center">

          <FormControlLabel

            control={

              <Switch

                checked={!sortLocked}

                onChange={handleToggleSortLock}

                sx={{ color: tealColor }}

              />

            }

            label={sortLocked ? "Sort Locked" : "Sort Unlocked"}

          />

          <FormControlLabel

            control={

              <Switch

                checked={showHiddenLeads}

                onChange={handleToggleShowHiddenLeads}

                sx={{ color: tealColor }}

              />

            }

            label={showHiddenLeads ? "Show All Leads" : "Hide Active"}

          />

        </Box>

      </Box>


      <Typography variant="h6" sx={{ mb: 2 }}>Current Time: {currentTime.toLocaleString()}</Typography>


      {newLeads.length > 0 && (

        <Paper elevation={3} sx={{ mb: 2, p: 2 }}>

          <Typography variant="h6" color="error">New Leads:</Typography>

          {newLeads.map((newLead) => (

            <Box key={newLead._id} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>

              <Typography>{newLead.FNAME || newLead.first_name} {newLead.LNAME || newLead.last_name} - {newLead.premium_phone || newLead.phone_home || newLead.phone_work || newLead.phone_cell}</Typography>

            </Box>

          ))}

        </Paper>

      )}


      <DragDropContext onDragEnd={handleOnDragEnd}>

        <Droppable droppableId="leads">

          {(provided) => (

            <TableContainer component={Paper} {...provided.droppableProps} ref={provided.innerRef}>

              <Table>

                <TableHead>

                  <TableRow>

                    <TableCell>Lead Name</TableCell>

                    <TableCell>Phone Number</TableCell>

                    <TableCell>Last Disposition</TableCell>

                    <TableCell>Property State</TableCell>

                    <TableCell>Local Time</TableCell>

                    <TableCell>Lead Source</TableCell>

                    <TableCell>Created Date</TableCell>

                    <TableCell>DNC Status</TableCell>

                    <TableCell>Actions</TableCell>

                    <TableCell>Hide</TableCell>

                  </TableRow>

                </TableHead>

                <TableBody>

                  {paginatedLeads.filter(lead => !hiddenLeads.includes(lead._id) || showHiddenLeads).map((lead, index) => (

                    <Draggable key={lead._id} draggableId={lead._id} index={index} isDragDisabled={sortLocked}>

                      {(provided) => (

                        <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                          <TableCell>

                            <Link href="#" onClick={() => onLeadSelect(lead, 'details')} sx={{ color: tealColor }}>

                              {lead.FNAME || lead.first_name} {lead.LNAME || lead.last_name}

                            </Link>

                          </TableCell>

                          <TableCell>

                            <Link href="#" onClick={() => onLeadSelect(lead, 'phone')} sx={{ color: tealColor }}>

                              {lead.premium_phone || lead.phone_home || lead.phone_work || lead.phone_cell}

                            </Link>

                          </TableCell>

                          <TableCell>{lead.callAttempts && lead.callAttempts.length > 0 ? lead.callAttempts[lead.callAttempts.length - 1].disposition : 'New Lead'}</TableCell>

                          <TableCell>{lead.property_state || lead.state}</TableCell>

                          <TableCell>{lead.property_state ? getCurrentTimeForTimeZone(getLeadTimeZone(lead.property_state)).toLocaleTimeString() : '-'}</TableCell>

                          <TableCell>{lead.lead_source || lead.source}</TableCell>

                          <TableCell>{new Date(lead.createdAt).toLocaleString()}</TableCell>

                          <TableCell>

                            <Typography color={lead.isDNC ? 'error' : 'primary'}>

                              {lead.isDNC ? 'DNC' : <span style={{ color: 'green' }}>Clear</span>}

                            </Typography>

                          </TableCell>

                          <TableCell>

                            <Tooltip title="Phone">

                              <IconButton onClick={() => onLeadSelect(lead, 'phone')} sx={{ color: tealColor }}>

                                <PhoneIcon />

                              </IconButton>

                            </Tooltip>

                            <Tooltip title="Email">

                              <IconButton onClick={() => onLeadSelect(lead, 'email')} sx={{ color: tealColor }}>

                                <EmailIcon />

                              </IconButton>

                            </Tooltip>

                            <Tooltip title="SMS">

                              <IconButton onClick={() => onLeadSelect(lead, 'sms')} sx={{ color: tealColor }}>

                                <SmsIcon />

                              </IconButton>

                            </Tooltip>

                            <Tooltip title="Calendar">

                              <IconButton onClick={() => onLeadSelect(lead, 'calendar')} sx={{ color: tealColor }}>

                                <EventIcon />

                              </IconButton>

                            </Tooltip>

                            <Tooltip title="Disposition">

                              <IconButton onClick={() => onLeadSelect(lead, 'disposition')} sx={{ color: tealColor }}>

                                <AssignmentIcon />

                              </IconButton>

                            </Tooltip>

                            <Tooltip title="Details">

                              <IconButton onClick={() => onLeadSelect(lead, 'details')} sx={{ color: tealColor }}>

                                <InfoIcon />

                              </IconButton>

                            </Tooltip>

                          </TableCell>

                          <TableCell>

                            <Checkbox

                              checked={hiddenLeads.includes(lead._id)}

                              onChange={() => handleToggleHideLead(lead._id)}

                              color="primary"

                            />

                          </TableCell>

                        </TableRow>

                      )}

                    </Draggable>

                  ))}

                  {provided.placeholder}

                </TableBody>

              </Table>

            </TableContainer>

          )}

        </Droppable>

      </DragDropContext>


      <TablePagination

        component="div"

        count={leads.length}

        page={currentPage}

        onPageChange={(event, newPage) => setCurrentPage(newPage)}

        rowsPerPage={leadsPerPage}

        rowsPerPageOptions={[leadsPerPage]}

      />

    </Box>

  );

};


export default CallsForToday;

