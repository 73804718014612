import React, { useState } from 'react';

import { Container, Typography, Button, Box, TextField, AppBar, Toolbar, Paper } from '@mui/material';

import renewlogo from './renew-lending-logo.png';

import { useNavigate } from 'react-router-dom';

import { useAuth } from './AuthContext'; // Import useAuth hook


const SignUpComponent = () => {

  const navigate = useNavigate();

  const { signup } = useAuth(); // Destructure signup function from useAuth hook


  const [formData, setFormData] = useState({ aBFirstNm: '', aBLastNm: '', aBEmail: '', aBCellphone: '' });


  const handleChange = (e) => {

    setFormData({ ...formData, [e.target.name]: e.target.value });

  };


  const handleSubmit = async (e) => {

    e.preventDefault();

    try {

      await signup(formData.aBFirstNm, formData.aBLastNm, formData.aBEmail, formData.aBCellphone); // Call signup function with form data

      navigate('/loan-app'); // Navigate to loan-app upon successful signup

    } catch (error) {

      console.error('Error:', error);

      // Handle error

    }

  };


  const handleBackToLogin = () => {

    navigate('/login');

  };


  return (

    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>

      <AppBar position="static" sx={{ bgcolor: '#ffffff' }}>

        <Toolbar>

          <img src={renewlogo} alt="Renew Lending Logo" style={{ width: '136px', margin: 'auto' }} />

        </Toolbar>

      </AppBar>

      <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>

        <Typography variant="h5" align="center" gutterBottom>Sign Up</Typography>

        <Paper elevation={3} sx={{ p: 2, bgcolor: '#f5f5f5', borderRadius: '8px', width: '100%', mb: 2 }}>

          <Box component="form" onSubmit={handleSubmit}>

            <TextField

              fullWidth

              label="Borrower First Name"

              name="aBFirstNm"

              value={formData.aBFirstNm}

              onChange={handleChange}

              margin="normal"

              required

            />

            <TextField

              fullWidth

              label="Borrower Last Name"

              name="aBLastNm"

              value={formData.aBLastNm}

              onChange={handleChange}

              margin="normal"

              required

            />

            <TextField

              fullWidth

              label="Borrower Email"

              type="email"

              name="aBEmail"

              value={formData.aBEmail}

              onChange={handleChange}

              margin="normal"

              required

            />

            <TextField

              fullWidth

              label="Borrower Cell Phone"

              name="aBCellphone"

              value={formData.aBCellphone}

              onChange={handleChange}

              margin="normal"

            />

            <Button type="submit" variant="contained" sx={{ bgcolor: '#48acac', width: '100%', mt: 2 }}>Sign Up</Button>

          </Box>

        </Paper>

        <Button variant="contained" onClick={handleBackToLogin} sx={{ bgcolor: '#48acac', width: '100%', '&:hover': { bgcolor: '#757575' }, '&:active': { bgcolor: '#757575' } }}>

          Back to Login

        </Button>

      </Container>

      <Box sx={{ width: '100%', bgcolor: '#ffffff', p: 2, textAlign: 'center' }}>

        <Typography variant="body1">

          NMLS 187023

        </Typography>

      </Box>

    </Box>

  );

};


export default SignUpComponent;

