import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Container, CssBaseline, Box, Typography, Paper, Link, Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import SignatureSelection from './SignatureSelection';

import SignatureForm from './SignatureForm';

import SignaturePreview from './SignaturePreview';

import SubmissionConfirmation from './SubmissionConfirmation';

import NavigationPanel from './NavigationPanel';

import renewLogo from '../Auth/renew-lending-logo.png';

import {

  Person as PersonIcon,

  House as HouseIcon,

  Description as DescriptionIcon,

} from '@mui/icons-material';

import { Player } from '@lottiefiles/react-lottie-player';

import houseAnimation from '../animations/house-animation.json'; // Make sure the path is correct


import './EmailSignatureOrder.css'; // Import the CSS file


const stepsConfig = [

  { label: 'Select Design', icon: <PersonIcon /> },

  { label: 'Enter Details', icon: <HouseIcon /> },

  { label: 'Preview', icon: <DescriptionIcon /> },

];


const EmailSignatureOrder = () => {

  const [step, setStep] = useState(1);

  const [completedSteps, setCompletedSteps] = useState([]);

  const [selectedDesign, setSelectedDesign] = useState(null);

  const [formData, setFormData] = useState(null);

  const [showIntro, setShowIntro] = useState(true);

  const [showWelcome, setShowWelcome] = useState(false);

  const navigate = useNavigate();

  const touchStartXRef = useRef(null);


  useEffect(() => {

    const timer = setTimeout(() => {

      setShowIntro(false);

      setShowWelcome(true);

    }, 2500); // Total duration of the animation (2s fadeIn + 0.5s moveUp)


    return () => clearTimeout(timer);

  }, []);


  const handleDesignSelect = (design) => {

    setSelectedDesign(design);

    markStepAsCompleted(1);

    setStep(2);

  };


  const handleFormSubmit = (data) => {

    setFormData(data);

    markStepAsCompleted(2);

    setStep(3);

  };


  const handleConfirm = () => {

    console.log('Confirmed Data:', formData);

    alert('Your email signature request has been submitted successfully!');

    setStep(1);

    setCompletedSteps([]);

    setSelectedDesign(null);

    setFormData(null);

  };


  const handleEdit = () => {

    setStep(2);

  };


  const markStepAsCompleted = useCallback((step) => {

    setCompletedSteps((prev) => {

      if (!prev.includes(step)) return [...prev, step];

      return prev;

    });

  }, []);


  const handleNavigateToStep = useCallback((index) => {

    setStep(index);

  }, []);


  const handleTouchStart = (e) => {

    touchStartXRef.current = e.touches[0].clientX;

  };


  const handleTouchMove = (e) => {

    const touchMoveX = e.touches[0].clientX;

    const touchStartX = touchStartXRef.current;

    if (touchStartX && touchMoveX) {

      const diffX = touchMoveX - touchStartX;

      if (diffX > 50) {

        handleNavigateToStep(step - 1);

      } else if (diffX < -50) {

        handleNavigateToStep(step + 1);

      }

    }

  };


  const handleEnterClick = () => {

    setShowWelcome(false);

  };


  return (

    <Container component="main" maxWidth="md" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

      <CssBaseline />

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>

        <img

          src={renewLogo}

          alt="Renew Lending Logo"

          style={{ width: '200px', cursor: 'pointer' }}

          onClick={() => navigate('/')}

        />

      </Box>

      {showWelcome && (

        <Box

          sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}

        >

          <Box sx={{ mb: 2 }}>

            <Player

              autoplay

              loop

              src={houseAnimation}

              style={{ height: '300px', width: '300px' }}

            />

          </Box>

          <Typography variant="h4" align="center" sx={{ mb: 2 }}>

            Welcome to ReNew Lending!

          </Typography>

          <Typography variant="body1" align="center" sx={{ maxWidth: '600px', mb: 2 }}>

            We are thrilled to have you join our family. At ReNew Lending, we strive to create an inclusive and supportive environment where everyone feels at home. This email signature form is here to help you create your professional email signature. We've prepared some examples for you. Please select the version you would like to use, and you can add special requests at the end of this form.

          </Typography>

          <Button variant="contained" onClick={handleEnterClick} sx={{ backgroundColor: '#47a9af', color: '#fff', '&:hover': { backgroundColor: '#358b91' } }}>

            Enter

          </Button>

        </Box>

      )}

      {!showIntro && !showWelcome && (

        <Box

          sx={{ mt: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}

          onTouchStart={handleTouchStart}

          onTouchMove={handleTouchMove}

        >

          {step === 1 && (

            <Paper sx={{ p: 2, mt: 4 }}>

              <Typography variant="h6" align="center" sx={{ mb: 2 }}>

                Here are some examples of email signatures from our team members. Please select the design you prefer.

              </Typography>

              <SignatureSelection onSelect={handleDesignSelect} />

            </Paper>

          )}

          {step === 2 && (

            <Paper sx={{ p: 2, mt: 4 }}>

              <SignatureForm selectedDesign={selectedDesign} onSubmit={handleFormSubmit} />

            </Paper>

          )}

          {step === 3 && (

            <Paper sx={{ p: 2, mt: 4 }}>

              <SignaturePreview formData={formData} selectedDesign={selectedDesign} />

              <SubmissionConfirmation formData={formData} selectedDesign={selectedDesign} onEdit={handleEdit} onConfirm={handleConfirm} />

            </Paper>

          )}

          <NavigationPanel stepsConfig={stepsConfig} currentStep={step} completedSteps={completedSteps} onNavigate={handleNavigateToStep} />

        </Box>

      )}

      <Typography variant="body2" color="textSecondary" align="center" sx={{ pb: 2 }}>

        © {new Date().getFullYear()} ReNew Lending

        <Link

          href="#"

          onClick={(e) => {

            e.preventDefault();

            navigate('/');

          }}

          sx={{ ml: 2, color: '#47a9af' }}

        >

          Terms of Service

        </Link>

        <Link

          href="#"

          onClick={(e) => {

            e.preventDefault();

            navigate('/');

          }}

          sx={{ ml: 2, color: '#47a9af' }}

        >

          Privacy Policy

        </Link>

      </Typography>

    </Container>

  );

};


export default EmailSignatureOrder;

