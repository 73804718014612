// LoanAndPropertyInfo.jsx

import React, { useState } from 'react';

import { TextField, Button, Box, Typography, Container, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';


function LoanAndPropertyInfo() {

    const location = useLocation();

    const navigate = useNavigate();

    const contactInfo = location.state?.contactInfo || {};

    const [loanDetails, setLoanDetails] = useState({

        loanPurpose: '',

        purchasePrice: '',

        downPayment: '',

        mortgageAmount: '',

        occupancy: '',

        propertyType: '',

        propertyAddress: ''

    });


    const handleChange = (e) => {

        const { name, value } = e.target;

        setLoanDetails((prev) => ({

            ...prev,

            [name]: value

        }));

    };


    const handleNext = (e) => {

        e.preventDefault();

        navigate('/final-screen', { state: { contactInfo, loanDetails } });

    };


    return (

        <Container component="main" maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>

            <Typography component="h1" variant="h5">

                Loan and Property Information

            </Typography>

            <Box component="form" onSubmit={handleNext} sx={{ mt: 1, width: '100%', maxWidth: 360, bgcolor: 'background.paper', boxShadow: 3, padding: 4, borderRadius: 2 }}>

                <RadioGroup

                    row

                    name="loanPurpose"

                    value={loanDetails.loanPurpose}

                    onChange={handleChange}

                >

                    <FormControlLabel value="Purchase" control={<Radio />} label="Purchase" />

                    <FormControlLabel value="Refinance" control={<Radio />} label="Refinance" />

                    <FormControlLabel value="Cash-Out Refinance" control={<Radio />} label="Cash-Out Refinance" />

                </RadioGroup>

                <TextField

                    margin="normal"

                    fullWidth

                    label="Purchase Price"

                    name="purchasePrice"

                    value={loanDetails.purchasePrice}

                    onChange={handleChange}

                    type="number"

                />

                <TextField

                    margin="normal"

                    fullWidth

                    label="Down Payment"

                    name="downPayment"

                    value={loanDetails.downPayment}

                    onChange={handleChange}

                    type="number"

                />

                <TextField

                    margin="normal"

                    fullWidth

                    label="Mortgage Amount"

                    name="mortgageAmount"

                    value={loanDetails.mortgageAmount}

                    onChange={handleChange}

                    type="number"

                />

                <TextField

                    margin="normal"

                    fullWidth

                    label="Occupancy"

                    name="occupancy"

                    value={loanDetails.occupancy}

                    onChange={handleChange}

                />

                <TextField

                    margin="normal"

                    fullWidth

                    label="Property Type"

                    name="propertyType"

                    value={loanDetails.propertyType}

                    onChange={handleChange}

                />

                <TextField

                    margin="normal"

                    fullWidth

                    label="Property Address"

                    name="propertyAddress"

                    value={loanDetails.propertyAddress}

                    onChange={handleChange}

                />

                <Button

                    type="submit"

                    fullWidth

                    variant="contained"

                    sx={{ mt: 3, mb: 2 }}

                >

                    Next

                </Button>

            </Box>

        </Container>

    );

}


export default LoanAndPropertyInfo;

