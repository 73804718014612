import React from 'react';

import InputBase from '@mui/material/InputBase';

import SearchIcon from '@mui/icons-material/Search';

import { styled, alpha } from '@mui/material/styles';

import InputAdornment from '@mui/material/InputAdornment';


const Search = styled('div')(({ theme }) => ({

  position: 'relative',

  borderRadius: theme.shape.borderRadius,

  backgroundColor: alpha(theme.palette.common.white, 0.15),

  '&:hover': {

    backgroundColor: alpha(theme.palette.common.white, 0.25),

  },

  marginLeft: 'auto',

  marginRight: 'auto',

  width: '25vw', // Default width for larger screens

  display: 'flex',

  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {

    width: '70vw', // Width on small screens

  },

}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({

  color: 'inherit',

  '& .MuiInputBase-input': {

    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`, // Padding to account for the search icon

    transition: theme.transitions.create('width'),

    width: '100%', // Make sure the input uses the full width of its parent

  },

}));


function HeaderSearch() {

  return (

    <Search>

      <StyledInputBase

        placeholder="Search…"

        inputProps={{ 'aria-label': 'search' }}

        startAdornment={

          <InputAdornment position="start">

            <SearchIcon style={{ color: '#46A9AF' }}/>

          </InputAdornment>

        }

      />

    </Search>

  );

}


export default HeaderSearch;

