import React, { useState } from 'react';

import { Container, Typography, Button, Box, TextField, AppBar, Toolbar, Paper } from '@mui/material';

import renewlogo from '../Auth/renew-lending-logo.png';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '../Auth/AuthContext'; // Import useAuth hook


const NewLoanOfficer = () => {

  const navigate = useNavigate();

  const { signup } = useAuth() || {}; // Destructure signup function from useAuth hook with fallback to empty object


  const [formData, setFormData] = useState({ firstName: '', lastName: '', nmls: '', email: '', cell: '', password: '' });


  const handleChange = (e) => {

    setFormData({ ...formData, [e.target.name]: e.target.value });

  };


  const handleSubmit = async (e) => {

    e.preventDefault();

    try {

      if (signup) {

        await signup(formData.firstName, formData.lastName, formData.email, formData.cell, formData.password, 'Loan Officer', formData.nmls); // Call signup function with form data

        navigate('/loan-app'); // Navigate to loan-app upon successful signup

      } else {

        console.error('Signup function not available');

      }

    } catch (error) {

      console.error('Error:', error);

      // Handle error

    }

  };


  const handleBackToLogin = () => {

    navigate('/login');

  };


  return (

    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>

      <AppBar position="static" sx={{ bgcolor: '#ffffff' }}>

        <Toolbar>

          <img src={renewlogo} alt="Renew Lending Logo" style={{ width: '136px', margin: 'auto' }} />

        </Toolbar>

      </AppBar>

      <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>

        <Typography variant="h5" align="center" gutterBottom>Register New Loan Officer</Typography>

        <Paper elevation={3} sx={{ p: 2, bgcolor: '#f5f5f5', borderRadius: '8px', width: '100%', mb: 2 }}>

          <Box component="form" onSubmit={handleSubmit}>

            <TextField

              fullWidth

              label="First Name"

              name="firstName"

              value={formData.firstName}

              onChange={handleChange}

              margin="normal"

              required

            />

            <TextField

              fullWidth

              label="Last Name"

              name="lastName"

              value={formData.lastName}

              onChange={handleChange}

              margin="normal"

              required

            />

            <TextField

              fullWidth

              label="NMLS #"

              name="nmls"

              value={formData.nmls}

              onChange={handleChange}

              margin="normal"

              required

            />

            <TextField

              fullWidth

              label="Email"

              type="email"

              name="email"

              value={formData.email}

              onChange={handleChange}

              margin="normal"

              required

            />

            <TextField

              fullWidth

              label="Cell Phone"

              name="cell"

              value={formData.cell}

              onChange={handleChange}

              margin="normal"

            />

            <TextField

              fullWidth

              label="New Password"

              type="password"

              name="password"

              value={formData.password}

              onChange={handleChange}

              margin="normal"

              required

            />

            <Button type="submit" variant="contained" sx={{ bgcolor: '#48acac', width: '100%', mt: 2 }}>Create Loan Officer</Button>

          </Box>

        </Paper>

        <Button variant="contained" onClick={handleBackToLogin} sx={{ bgcolor: '#48acac', width: '100%', '&:hover': { bgcolor: '#757575' }, '&:active': { bgcolor: '#757575' } }}>

          Back to Login

        </Button>

      </Container>

      <Box sx={{ width: '100%', bgcolor: '#ffffff', p: 2, textAlign: 'center' }}>

        <Typography variant="body1">

          NMLS 187023

        </Typography>

      </Box>

    </Box>

  );

};


export default NewLoanOfficer;

