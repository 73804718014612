import React, { useEffect } from 'react';

import { Snackbar, Alert, Link, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';


const NewLeadNotification = ({ lead, onClose }) => {

  useEffect(() => {

    const timer = setTimeout(onClose, 30000); // Auto-close after 30 seconds

    return () => clearTimeout(timer);

  }, [onClose]);


  // Add null check before accessing lead properties

  if (!lead) {

    return null; // If lead is undefined, return null to render nothing

  }


  return (

    <Snackbar

      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

      open={Boolean(lead)}

      onClose={onClose}

    >

      <Alert

        severity="info"

        action={

          <IconButton

            aria-label="close"

            color="inherit"

            size="small"

            onClick={onClose}

          >

            <CloseIcon fontSize="inherit" />

          </IconButton>

        }

      >

        <div>

          <strong>New Lead Assigned!</strong><br />

          Source: {lead.lead_source || lead.source}<br />

          Name: {lead.FNAME || lead.first_name} {lead.LNAME || lead.last_name}<br />

          Phone: <Link href="#" onClick={() => onClose()}> {lead.phone_home || lead.phone_cell || lead.phone_work || lead.premium_phone}</Link>

        </div>

      </Alert>

    </Snackbar>

  );

};


export default NewLeadNotification;

