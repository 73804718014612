import React, { useState, useEffect } from 'react';

import { Container, Typography, Button, Box, TextField, Paper, MenuItem, CircularProgress } from '@mui/material';

import axios from 'axios';

import { useAuth } from '../Auth/AuthContext';


const NewUserForm = () => {

  const { authStatus } = useAuth(); // Assuming this might be used for role-based rendering or checks

  const [formData, setFormData] = useState({

    firstName: '',

    lastName: '',

    email: '',

    cell: '',

    role: '',

    nmls: '',

    branch: '',

    username: '',

  });


  const [userCreated, setUserCreated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [branches, setBranches] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');


  // Hardcoded roles

  const roles = ['Admin', 'Manager', 'LoanOfficer', 'Borrower'];


  useEffect(() => {

    const fetchBranches = async () => {

      try {

        const response = await axios.get('/api/branches');

        if (Array.isArray(response.data)) {

          setBranches(response.data);

        } else {

          console.error('Unexpected data structure:', response.data);

          setErrorMessage('Unexpected data structure in response.');

        }

      } catch (error) {

        console.error('Error fetching branches:', error);

        setErrorMessage(`Failed to fetch branches: ${error.message}`);

      }

    };


    fetchBranches();

  }, []);


  const handleChange = (e) => {

    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

  };


  const handleSubmit = async (e) => {

    e.preventDefault();

    setIsLoading(true);


    try {

      // Post request to create a new user

      const userCreationResponse = await axios.post('/api/users', formData, {

        headers: { 'Content-Type': 'application/json' },

        withCredentials: true

      });


      if (userCreationResponse.status === 201) {

        console.log('User created successfully:', userCreationResponse.data);

        // User is created. Now request a password reset link

        const passwordResetResponse = await axios.post('/api/request-reset', { email: formData.email });


        if (passwordResetResponse.status === 200) {

            console.log('Password reset email sent successfully');

            setUserCreated(true);

            setErrorMessage('');

        } else {

            console.error('Failed to send password reset email');

            setErrorMessage('User was created, but failed to send password reset email.');

            setUserCreated(false);

        }

      } else {

        console.error('Failed to create user');

        setErrorMessage('Failed to create user. Please try again.');

        setUserCreated(false);

      }

    } catch (error) {

      console.error('Error during the user creation or reset password process:', error);

      setErrorMessage('Failed to create user. ' + (error.response?.data?.message || error.message));

      setUserCreated(false);

    }


    setIsLoading(false);

  };


  const renderCommonFields = () => (

    <>

      <TextField select fullWidth label="Role" name="role" value={formData.role} onChange={handleChange} margin="normal" required>

        {roles.map((role) => (

          <MenuItem key={role} value={role}>{role}</MenuItem>

        ))}

      </TextField>

      <TextField select fullWidth label="Branch" name="branch" value={formData.branch} onChange={handleChange} margin="normal" required>

        {branches.map((branch) => (

          <MenuItem key={branch._id} value={branch._id}>{branch.branchName}</MenuItem>

        ))}

      </TextField>

      <TextField fullWidth label="First Name" name="firstName" value={formData.firstName} onChange={handleChange} margin="normal" required />

      <TextField fullWidth label="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} margin="normal" required />

      <TextField fullWidth label="Cell Phone" name="cell" value={formData.cell} onChange={handleChange} margin="normal" />

      <TextField fullWidth label="Email" type="email" name="email" value={formData.email} onChange={handleChange} margin="normal" required />

      <TextField fullWidth label="Username" name="username" value={formData.username} onChange={handleChange} margin="normal" required />

      {formData.role === 'LoanOfficer' && (

        <TextField fullWidth label="NMLS #" name="nmls" value={formData.nmls} onChange={handleChange} margin="normal" required />

      )}

    </>

  );


  return (

    <Container component="main" maxWidth="xs">

      <Typography variant="h5" align="center" gutterBottom>Register New User</Typography>

      {!userCreated ? (

        <Paper elevation={3}>

          <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>

            {renderCommonFields()}

            <Button type="submit" variant="contained" sx={{ bgcolor: '#48acac', '&:hover': { bgcolor: 'white', color: '#48acac' }, width: '100%', mt: 2 }} disabled={isLoading}>

              {isLoading ? <CircularProgress size={24} /> : 'Create User'}

            </Button>

          </Box>

          {errorMessage && <Typography color="error">{errorMessage}</Typography>}

        </Paper>

      ) : (

        <Typography>User Created Successfully!</Typography>

      )}

    </Container>

  );

};


export default NewUserForm;

