import React, { useEffect, useState } from 'react';

import {

  Box, Typography, Paper, Divider, MenuItem, Select, Button, CircularProgress, TextField

} from '@mui/material';

import { teal } from '@mui/material/colors';

// import DeleteIcon from '@mui/icons-material/Delete'; // Commented out the import

import axios from 'axios';


const DispositionHistory = ({ leadId }) => {

  const [dispositions, setDispositions] = useState([]);

  const [newDisposition, setNewDisposition] = useState('');

  const [note, setNote] = useState('');

  const [loading, setLoading] = useState(false);

  const [saving, setSaving] = useState(false);


  useEffect(() => {

    const fetchDispositions = async () => {

      setLoading(true);

      try {

        const response = await axios.get(`/api/leads/${leadId}/disposition-history`);

        setDispositions(response.data);

      } catch (error) {

        console.error('Error fetching disposition history:', error);

      }

      setLoading(false);

    };


    if (leadId) {

      fetchDispositions();

    }

  }, [leadId]);


  const handleSaveDisposition = async () => {

    setSaving(true);

    try {

      await axios.post(`/api/leads/${leadId}/disposition-history`, {

        disposition: newDisposition,

        note,

        timestamp: new Date().toISOString(),

      });

      setNewDisposition('');

      setNote('');


      // Fetch updated dispositions

      const response = await axios.get(`/api/leads/${leadId}/disposition-history`);

      setDispositions(response.data);

    } catch (error) {

      console.error('Error saving disposition:', error);

    }

    setSaving(false);

  };


  /*

  const handleDeleteDisposition = async (dispositionId) => {

    try {

      await axios.delete(`/api/leads/${leadId}/disposition/${dispositionId}`);

      const response = await axios.get(`/api/leads/${leadId}/disposition-history`);

      setDispositions(response.data);

    } catch (error) {

      console.error('Error deleting disposition:', error);

    }

  };

  */


  const sectionStyle = {

    padding: 2,

    marginBottom: 2,

    boxShadow: 3,

  };


  const headerStyle = {

    marginBottom: 1,

    color: teal[700],

  };


  const fieldKeyStyle = {

    color: teal[700],

    fontWeight: 'bold',

  };


  const fieldValueStyle = {

    color: 'black',

  };


  return (

    <Box>

      <Paper sx={sectionStyle}>

        <Typography variant="h6" sx={headerStyle}>

          Add New Disposition

        </Typography>

        <Divider />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 2 }}>

          <Select

            value={newDisposition}

            onChange={(e) => setNewDisposition(e.target.value)}

            displayEmpty

          >

            <MenuItem value=""><em>Select Disposition</em></MenuItem>

            <MenuItem value="DNC">DNC</MenuItem>

            <MenuItem value="Not interested">Not interested</MenuItem>

            <MenuItem value="Does not qualify (credit, LTV, BK, late pay)">Does not qualify (credit, LTV, BK, late pay)</MenuItem>

            <MenuItem value="No net benefit at this time">No net benefit at this time</MenuItem>

            <MenuItem value="Just refinanced, 12 months call back">Just refinanced, 12 months call back</MenuItem>

            <MenuItem value="Not Contacted">Not Contacted</MenuItem>

            <MenuItem value="Call Attempt">Call Attempt</MenuItem>

            <MenuItem value="Contacted">Contacted</MenuItem>

            <MenuItem value="Credit Pulled">Credit Pulled</MenuItem>

            <MenuItem value="Application Sent">Application Sent</MenuItem>

            <MenuItem value="Application Started">Application Started</MenuItem>

            <MenuItem value="Application Completed">Application Completed</MenuItem>

            <MenuItem value="Loan In Process">Loan In Process</MenuItem>

            <MenuItem value="Caller Returned Call">Caller Returned Call</MenuItem>

            <MenuItem value="New Lead">New Lead</MenuItem>

          </Select>

          <TextField

            label="Note"

            multiline

            rows={4}

            value={note}

            onChange={(e) => setNote(e.target.value)}

          />

          <Button

            variant="contained"

            color="primary"

            onClick={handleSaveDisposition}

            disabled={saving}

            sx={{ backgroundColor: teal[500] }}

          >

            Save Disposition

          </Button>

        </Box>

      </Paper>


      <Paper sx={sectionStyle}>

        <Typography variant="h6" sx={headerStyle}>

          Disposition History

        </Typography>

        <Divider />

        {loading ? (

          <CircularProgress />

        ) : (

          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>

            {dispositions.map((disposition) => (

              <Box key={disposition._id} sx={{ marginBottom: 2 }}>

                <Typography variant="body1"><span style={fieldKeyStyle}>Disposition:</span> <span style={fieldValueStyle}>{disposition.disposition}</span></Typography>

                <Typography variant="body1"><span style={fieldKeyStyle}>Note:</span> <span style={fieldValueStyle}>{disposition.note}</span></Typography>

                <Typography variant="body1"><span style={fieldKeyStyle}>Timestamp:</span> <span style={fieldValueStyle}>{new Date(disposition.timestamp).toLocaleString()}</span></Typography>

                {/* <IconButton onClick={() => handleDeleteDisposition(disposition._id)} sx={{ color: 'red' }}>

                  <DeleteIcon />

                </IconButton> */}

                <Divider />

              </Box>

            ))}

          </Box>

        )}

      </Paper>

    </Box>

  );

};


export default DispositionHistory;

