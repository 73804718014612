import React from 'react';

import { Button, TextField, Container, Typography, CssBaseline } from '@mui/material';

import renewlogo from './renew-lending-logo.png';

import { styled } from '@mui/material/styles';

import { Link } from 'react-router-dom';


const tealColor = '#46A9AF'; // Keep the color scheme consistent

const darkGrey = '#333333';

const lightBlue = 'lightblue';


const CustomTextField = styled(TextField)({

  '& label.Mui-focused': {

    color: tealColor,

  },

  '& .MuiInput-underline:after': {

    borderBottomColor: tealColor,

  },

  '& .MuiOutlinedInput-root': {

    '& fieldset': {

      borderColor: darkGrey,

    },

    '&:hover fieldset': {

      borderColor: darkGrey,

    },

    '&.Mui-focused fieldset': {

      borderColor: tealColor,

    },

    color: 'white',

  },

  '& .MuiInputLabel-root': {

    color: 'white',

  },

  '& .MuiInputBase-input': {

    color: 'white',

  },

});


const ForgotPassword = () => {

  return (

    <Container component="main" maxWidth="xs" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: darkGrey }}>

      <CssBaseline />

      <style>

        {`

          html, body {

            height: 100%;

            margin: 0;

            background-color: ${darkGrey};

          }

        `}

      </style>

      <img src={renewlogo} alt="Renew Lending Logo" style={{ width: '200px', marginBottom: '20px' }} />

      <Typography component="h1" variant="h5" style={{ color: 'white', textAlign: 'center' }}>

        Forgot Password

      </Typography>

      <form noValidate style={{ width: '100%', marginTop: '8px' }}>

        <CustomTextField

          variant="outlined"

          margin="normal"

          required

          fullWidth

          id="email"

          label="Email Address"

          name="email"

          autoComplete="email"

          autoFocus

        />

        <Button

          type="submit"

          fullWidth

          variant="contained"

          sx={{ mt: 3, mb: 2, backgroundColor: tealColor }}

        >

          Send Reset Link

        </Button>

        <Typography variant="body2" style={{ textAlign: 'center', marginTop: '20px', color: lightBlue }}>

          <Link to="/login" style={{ textDecoration: 'none', color: lightBlue }}>

            Back to login

          </Link>

        </Typography>

      </form>

    </Container>

  );

};


export default ForgotPassword;

