import React, { useState } from 'react';

import {

  Dialog,

  IconButton,

  DialogTitle,

  DialogContent,

  Grid,

  Tooltip,

} from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';

import CloseIcon from '@mui/icons-material/Close';

import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

import ListAltIcon from '@mui/icons-material/ListAlt';

import EmailIcon from '@mui/icons-material/Email';

import GroupIcon from '@mui/icons-material/Group';

import DescriptionIcon from '@mui/icons-material/Description';

import DashboardIcon from '@mui/icons-material/Dashboard';


// Import forms

import NewUserForm from '../Forms/NewUserForm';

import NewBranchForm from '../Forms/NewBranchForm';

import LeadManagement from '../Forms/LeadManagement';

import EmailForm from '../Forms/EmailForm';

import UserManagement from '../Forms/UserManagement';

import LoanApp from '../Forms/LoanApp';

import LoanOfficerDashboard from './LoanOfficerDashboard';


function SettingsMenu() {

  const [open, setOpen] = useState(false);

  const [activeForm, setActiveForm] = useState('');


  const handleClickOpen = () => {

    setOpen(true);

  };


  const handleClose = () => {

    setOpen(false);

    setActiveForm(''); // Reset active form state on close

  };


  return (

    <div className="settings-menu">

      <Tooltip title="Settings">

        <IconButton

          aria-label="settings"

          onClick={handleClickOpen}

          style={{ color: '#46A9AF' }}

        >

          <SettingsIcon fontSize="large" />

        </IconButton>

      </Tooltip>


      <Dialog fullScreen open={open} onClose={handleClose}>

        <DialogTitle>

          <IconButton

            edge="start"

            color="inherit"

            onClick={handleClose}

            aria-label="close"

            style={{ marginRight: 8 }}

          >

            <CloseIcon />

          </IconButton>

          Settings Menu

        </DialogTitle>

        <DialogContent dividers>

          <Grid container spacing={2} justifyContent="center">

            <Grid item>

              <Tooltip title="User Management">

                <IconButton

                  aria-label="User Management"

                  onClick={() => setActiveForm('userManagement')}

                >

                  <GroupIcon fontSize="large" />

                </IconButton>

              </Tooltip>

            </Grid>


            <Grid item>

              <Tooltip title="Lead Management">

                <IconButton

                  aria-label="Lead Management"

                  onClick={() => setActiveForm('leadManagement')}

                >

                  <ListAltIcon fontSize="large" />

                </IconButton>

              </Tooltip>

            </Grid>


            <Grid item>

              <Tooltip title="New User Form">

                <IconButton

                  aria-label="New User Form"

                  onClick={() => setActiveForm('newUser')}

                >

                  <AccountTreeOutlinedIcon fontSize="large" />

                </IconButton>

              </Tooltip>

            </Grid>


            <Grid item>

              <Tooltip title="New Branch Form">

                <IconButton

                  aria-label="New Branch Form"

                  onClick={() => setActiveForm('newBranch')}

                >

                  <DashboardIcon fontSize="large" />

                </IconButton>

              </Tooltip>

            </Grid>


            <Grid item>

              <Tooltip title="Email Form">

                <IconButton

                  aria-label="Email Form"

                  onClick={() => setActiveForm('email')}

                >

                  <EmailIcon fontSize="large" />

                </IconButton>

              </Tooltip>

            </Grid>


            <Grid item>

              <Tooltip title="Loan Application">

                <IconButton

                  aria-label="Loan Application"

                  onClick={() => setActiveForm('loanApp')}

                >

                  <DescriptionIcon fontSize="large" />

                </IconButton>

              </Tooltip>

            </Grid>


            <Grid item>

              <Tooltip title="Loan Officer Dashboard">

                <IconButton

                  aria-label="Loan Officer Dashboard"

                  onClick={() => setActiveForm('loanOfficerDashboard')}

                >

                  <DashboardIcon fontSize="large" />

                </IconButton>

              </Tooltip>

            </Grid>

          </Grid>


          <div className="form-content">

            {activeForm === 'newUser' && <NewUserForm />}

            {activeForm === 'newBranch' && <NewBranchForm />}

            {activeForm === 'leadManagement' && <LeadManagement />}

            {activeForm === 'email' && <EmailForm />}

            {activeForm === 'userManagement' && <UserManagement />}

            {activeForm === 'loanApp' && <LoanApp />}

            {activeForm === 'loanOfficerDashboard' && <LoanOfficerDashboard />}

          </div>

        </DialogContent>

      </Dialog>

    </div>

  );

}


export default SettingsMenu;

