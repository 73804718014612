import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from './AuthContext'; // Adjust the path as necessary

import {

  Button, TextField, Container, Typography, Alert, Box, CssBaseline,

  Grid, Paper, Link as MuiLink, InputAdornment, IconButton

} from '@mui/material';

import { GoogleLogin } from '@react-oauth/google';

import renewlogo from './renew-lending-logo.png';

import Visibility from '@mui/icons-material/Visibility';

import VisibilityOff from '@mui/icons-material/VisibilityOff';

import FacebookIcon from '@mui/icons-material/Facebook';

import TwitterIcon from '@mui/icons-material/Twitter';

import LinkedInIcon from '@mui/icons-material/LinkedIn';


const LoginComponent = () => {

  const navigate = useNavigate();

  const { login, googleLogin } = useAuth(); // Using the login and googleLogin functions from AuthContext

  const [credentials, setCredentials] = useState({ username: '', password: '' });

  const [loginError, setLoginError] = useState('');

  const [showPassword, setShowPassword] = useState(false);


  const handleChange = (event) => {

    const { name, value } = event.target;

    setCredentials(prev => ({ ...prev, [name]: value }));

  };


  const handleClickShowPassword = () => {

    setShowPassword(!showPassword);

  };


  const handleMouseDownPassword = (event) => {

    event.preventDefault();

  };


  const handleSubmit = async (event) => {

    event.preventDefault();


    if (!credentials.username || !credentials.password) {

      setLoginError('Username and password are required.');

      return;

    }


    try {

      await login(credentials.username, credentials.password);

      // Redirect to a general route; AppRouter handles the rest

      navigate('/dashboard');

    } catch (error) {

      setLoginError(error.response?.data?.message || 'Login failed. Please check your credentials.');

    }

  };


  return (

    <Container component="main" maxWidth="xs">

      <CssBaseline />

      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <img src={renewlogo} alt="Renew Lending Logo" style={{ width: '250px', marginBottom: '20px' }} />

        {loginError && <Alert severity="error" sx={{ mt: 2, mb: 1 }}>{loginError}</Alert>}

        <Paper sx={{ p: 2, mt: 2 }}>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <TextField

              margin="normal"

              required

              fullWidth

              id="username"

              label="Username"

              name="username"

              autoComplete="username"

              autoFocus

              onChange={handleChange}

            />

            <TextField

              margin="normal"

              required

              fullWidth

              name="password"

              label="Password"

              type={showPassword ? "text" : "password"}

              id="password"

              autoComplete="current-password"

              onChange={handleChange}

              InputProps={{

                endAdornment: (

                  <InputAdornment position="end">

                    <IconButton

                      aria-label="toggle password visibility"

                      onClick={handleClickShowPassword}

                      onMouseDown={handleMouseDownPassword}

                    >

                      {showPassword ? <VisibilityOff /> : <Visibility />}

                    </IconButton>

                  </InputAdornment>

                ),

              }}

            />

            <Button

              type="submit"

              fullWidth

              variant="contained"

              sx={{ mt: 3, mb: 2, backgroundColor: '#46a9af', '&:hover': { backgroundColor: '#358b91' } }}

            >

              Sign In

            </Button>

            <GoogleLogin

              onSuccess={googleLogin}

              onFailure={() => setLoginError('Google login failed. Please try again.')}

              buttonText="Sign in with Google"

              render={(renderProps) => (

                <Button

                  onClick={renderProps.onClick}

                  fullWidth

                  variant="contained"

                  sx={{ mt: 1, mb: 2, backgroundColor: '#db4437', '&:hover': { backgroundColor: '#c23321' } }}

                >

                  Sign in with Google

                </Button>

              )}

            />

            <Grid container>

              <Grid item xs>

                <MuiLink component={Link} to="/forgot-password" variant="body2" sx={{ color: '#46a9af' }}>

                  Forgot password?

                </MuiLink>

              </Grid>

              <Grid item>

                <MuiLink component={Link} to="/signup" variant="body2" sx={{ color: '#46a9af' }}>

                  {"Don't have an account? Sign Up"}

                </MuiLink>

              </Grid>

            </Grid>

          </Box>

        </Paper>

      </Box>

      <Box sx={{ mt: 5, textAlign: 'center' }}>

        <Typography variant="body2" color="text.secondary">

          <MuiLink href="/terms" sx={{ color: '#46a9af' }}>Terms</MuiLink> | <MuiLink href="/privacy" sx={{ color: '#46a9af' }}>Privacy Policy</MuiLink>

        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>

          NMLS 187023

        </Typography>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>

          <MuiLink href="https://www.facebook.com" target="_blank" sx={{ color: '#46a9af', mx: 1 }}>

            <FacebookIcon />

          </MuiLink>

          <MuiLink href="https://www.twitter.com" target="_blank" sx={{ color: '#46a9af', mx: 1 }}>

            <TwitterIcon />

          </MuiLink>

          <MuiLink href="https://www.linkedin.com" target="_blank" sx={{ color: '#46a9af', mx: 1 }}>

            <LinkedInIcon />

          </MuiLink>

        </Box>

      </Box>

    </Container>

  );

};


export default LoginComponent;

