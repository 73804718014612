import React, { useContext } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuth } from './components/Auth/AuthContext';

import LoginComponent from './components/Auth/LoginComponent';

import SignUpComponent from './components/Auth/SignUpComponent';

import DashboardComponent from './components/Dashboard/CallCenterMode.jsx';

import AdminDashboardComponent from './components/Dashboard/AdminDashboard';

import ForgotPassword from './components/Auth/ForgotPassword';

import MeridianLinkLoginComponent from './components/Auth/MeridianLinkLoginComponent';

import NewLoanOfficer from './components/Forms/NewLoanOfficer';

import LoanApp from './LoanApplication';

import TempPage from './TempLandingPageTemp';

import EmailSignatureOrder from './components/Forms/EmailSignatureOrder';

import IncomingPhone from './components/Dashboard/IncomingPhone';

import AuthContext from './components/Auth/AuthContext';


const AppRouter = () => {

  const { authStatus } = useAuth();

  const { setCurrentLead } = useContext(AuthContext);


  return (

    <>

      <Routes>

        <Route path="/" element={<Navigate to="/dashboard" replace />} />

        <Route path="/login" element={<LoginComponent />} />

        <Route path="/signup" element={<SignUpComponent />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/meridian-link-login" element={<MeridianLinkLoginComponent />} />


        {/* Protect the Dashboard route for logged-in users */}

        <Route

          path="/dashboard"

          element={

            authStatus.isLoggedIn ? (

              <>

                <IncomingPhone setCurrentLead={setCurrentLead} />

                <DashboardComponent />

              </>

            ) : (

              <Navigate to="/login" replace />

            )

          }

        />


        {/* Protect the Admin Dashboard route for Admin users */}

        <Route

          path="/AdminDashboard"

          element={

            authStatus.isLoggedIn && authStatus.role === 'Admin' ? (

              <>

                <IncomingPhone setCurrentLead={setCurrentLead} />

                <AdminDashboardComponent />

              </>

            ) : (

              <Navigate to="/login" replace />

            )

          }

        />


        {/* Make Loan Application Publicly Accessible */}

        <Route path="/loan-app/*" element={<TempPage />} />


        {/* Protect the New Loan Officer route for logged-in users */}

        <Route

          path="/new-lo"

          element={

            authStatus.isLoggedIn ? (

              <>

                <IncomingPhone setCurrentLead={setCurrentLead} />

                <NewLoanOfficer />

              </>

            ) : (

              <Navigate to="/login" replace />

            )

          }

        />


        {/* Add the Email Signature Order route */}

        <Route path="/email-signature-request" element={<EmailSignatureOrder />} />


        {/* Redirect all unmatched paths to the welcome screen */}

        <Route path="*" element={<Navigate to="/" />} />

      </Routes>

    </>

  );

};


export default AppRouter;

