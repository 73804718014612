import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import {

    Box,

    Button,

    Container,

    CssBaseline,

    Grow,

    Paper,

    Typography,

    useMediaQuery,

    useTheme,

    TextField,

    Checkbox,

    FormControlLabel,

    IconButton,

    Link,

} from '@mui/material';

import {

    Home as HomeIcon,

    MonetizationOn as MonetizationOnIcon,

    SyncAlt as SyncAltIcon,

    House as HouseIcon,

    ArrowBack as ArrowBackIcon,

    ArrowForward as ArrowForwardIcon,

    Person as PersonIcon,

    Business as BusinessIcon,

    Money as MoneyIcon,

    HouseSiding as HouseSidingIcon,

    CheckCircle as CheckCircleIcon,

    Description as DescriptionIcon,

    AttachMoney as AttachMoneyIcon,

} from '@mui/icons-material';

import LoanSummary from './LoanSummary';

import renewLogo from './components/Auth/renew-lending-logo.png';


const shimmer = `@keyframes shimmer {

    0% { opacity: 0.5; }

    50% { opacity: 1; }

    100% { opacity: 0.5; }

}`;


const tealColor = '#47a9af';


const buttonStyle = {

    backgroundColor: tealColor,

    '&:hover': { backgroundColor: '#358b91' },

    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',

    textTransform: 'none',

    padding: '12px 24px',

    borderRadius: '8px',

    fontSize: '1rem',

    fontWeight: 'bold',

    minWidth: '160px',

    height: '60px',

    color: 'white',

};


const selectedButtonStyle = {

    ...buttonStyle,

    backgroundColor: '#00796b', // Darker teal for selected state

    '&:hover': { backgroundColor: '#00574b' },

};


const textFieldStyle = {

    backgroundColor: '#f9f9f9',

};


const purchaseStepsConfig = [

    { label: 'Welcome', icon: <HomeIcon /> },

    { label: 'Contact', icon: <PersonIcon /> },

    { label: 'Occupancy', icon: <HouseIcon /> },

    { label: 'Property', icon: <HouseSidingIcon /> },

    { label: 'Contract Info', icon: <DescriptionIcon /> },

    { label: 'Loan Info', icon: <MonetizationOnIcon /> },

    { label: 'Income', icon: <MoneyIcon /> },

    { label: 'Assets', icon: <BusinessIcon /> },

];


const refinanceStepsConfig = [

    { label: 'Welcome', icon: <HomeIcon /> },

    { label: 'Contact', icon: <PersonIcon /> },

    { label: 'Refi Type', icon: <SyncAltIcon /> },

    { label: 'Occupancy', icon: <HouseIcon /> },

    { label: 'Property', icon: <HouseSidingIcon /> },

    { label: 'Cash Out Info', icon: <AttachMoneyIcon /> },

    { label: 'Loan Info', icon: <MonetizationOnIcon /> },

    { label: 'Income', icon: <MoneyIcon /> },

    { label: 'Assets', icon: <BusinessIcon /> },

];


function NavigationPanel({ stepsConfig, currentStep, completedSteps, onNavigate }) {

    const [navIndex, setNavIndex] = useState(0);

    const touchStartXRef = useRef(null);


    useEffect(() => {

        const halfVisibleSteps = Math.floor(5 / 2);

        let visibleIndex = Math.max(0, currentStep - halfVisibleSteps - 1);

        if (visibleIndex + 5 > stepsConfig.length) {

            visibleIndex = stepsConfig.length - 5;

        }

        setNavIndex(visibleIndex);

    }, [currentStep, stepsConfig.length]);


    const visibleSteps = stepsConfig.slice(navIndex, navIndex + 5);


    const handleScrollLeft = () => {

        if (navIndex > 0) setNavIndex(navIndex - 1);

    };


    const handleScrollRight = () => {

        if (navIndex < stepsConfig.length - 5) setNavIndex(navIndex + 1);

    };


    const handleTouchStart = (e) => {

        touchStartXRef.current = e.touches[0].clientX;

    };


    const handleTouchMove = (e) => {

        const touchMoveX = e.touches[0].clientX;

        const touchStartX = touchStartXRef.current;

        if (touchStartX && touchMoveX) {

            const diffX = touchMoveX - touchStartX;

            if (diffX > 50) {

                handleScrollLeft();

            } else if (diffX < -50) {

                handleScrollRight();

            }

        }

    };


    return (

        <Box

            sx={{

                display: 'flex',

                alignItems: 'center',

                mt: 2,

                height: '100px', // Increased height

                background: '#ffffff', // White background for high-class look

                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Box shadow for depth

                borderRadius: '12px', // Rounded corners

                overflow: 'hidden', // Prevent overflow

                transition: 'all 0.3s ease', // Smooth transition for touch movements

            }}

            onTouchStart={handleTouchStart}

            onTouchMove={handleTouchMove}

        >

            <IconButton onClick={handleScrollLeft} disabled={navIndex === 0}>

                <ArrowBackIcon sx={{ color: tealColor }} />

            </IconButton>

            <Box

                sx={{

                    display: 'flex',

                    overflowX: 'hidden',

                    scrollbarWidth: 'none',

                    '&::-webkit-scrollbar': { display: 'none' },

                    transition: 'all 0.3s ease', // Smooth transition for touch movements

                }}

            >

                {visibleSteps.map((step, index) => {

                    const isCompleted = completedSteps.includes(navIndex + index + 1);

                    const icon = isCompleted ? <CheckCircleIcon /> : step.icon;

                    const color = currentStep === navIndex + index + 1 ? tealColor : isCompleted ? 'green' : 'grey';

                    return (

                        <Box key={index} sx={{ textAlign: 'center', mx: 1 }}>

                            <IconButton onClick={() => onNavigate(navIndex + index)} sx={{ color, fontSize: '1.5rem' }}>

                                {icon}

                            </IconButton>

                            <Typography variant="caption" color={color} sx={{ fontSize: '0.9rem' }}>

                                {step.label}

                            </Typography>

                        </Box>

                    );

                })}

            </Box>

            <IconButton onClick={handleScrollRight} disabled={navIndex >= stepsConfig.length - 5}>

                <ArrowForwardIcon sx={{ color: tealColor }} />

            </IconButton>

        </Box>

    );

}


function CashOutInfo({ onNext, cashOutDetails, setCashOutDetails, setStep }) {

    const handleYes = () => {

        setCashOutDetails(prev => ({ ...prev, hasMortgage: 'yes' }));

        onNext();

    };


    const handleNo = () => {

        setCashOutDetails(prev => ({ ...prev, hasMortgage: 'no' }));

        onNext();

    };


    return (

        <Box sx={{ mt: 2 }}>

            <Typography variant="h6" gutterBottom>

                Do you have a mortgage on the property?

            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>

                <Button variant="contained" sx={buttonStyle} onClick={handleYes}>

                    Yes

                </Button>

                <Button variant="contained" sx={buttonStyle} onClick={handleNo}>

                    No

                </Button>

            </Box>

        </Box>

    );

}


function CashOutLoanInfo({ onNext, loanDetails, setLoanDetails, cashOutDetails }) {

    const [requestedAmount, setRequestedAmount] = useState('');


    const handleChange = (e) => {

        const { name, value } = e.target;

        setLoanDetails(prev => ({ ...prev, [name]: formatCurrencyInput(value) }));

    };


    const handleRequestedAmountChange = (e) => {

        setRequestedAmount(formatCurrencyInput(e.target.value));

    };


    const parseCurrency = (value) => {

        return parseFloat(value.replace(/[$,]/g, '')) || 0;

    };


    const formatCurrency = (value) => {

        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });

    };


    const formatCurrencyInput = (value) => {

        const numericValue = parseCurrency(value);

        return formatCurrency(numericValue);

    };


    const calculateLTV = (propertyValue, loanAmount) => {

        return (loanAmount / propertyValue) * 100;

    };


    const calculateCashOut = (propertyValue, mortgageBalance) => {

        const maxLoanAmount = propertyValue * 0.8;

        const potentialCashOut = maxLoanAmount - mortgageBalance;

        return {

            ltv: calculateLTV(propertyValue, mortgageBalance),

            maxLoanAmount,

            potentialCashOut,

        };

    };


    const { ltv, maxLoanAmount, potentialCashOut } = calculateCashOut(

        parseCurrency(loanDetails.propertyValue || '0'),

        parseCurrency(loanDetails.mortgageBalance || '0')

    );


    const requestedLoanAmount = parseCurrency(loanDetails.mortgageBalance || '0') + parseCurrency(requestedAmount);

    const requestedLTV = calculateLTV(parseCurrency(loanDetails.propertyValue || '0'), requestedLoanAmount);


    return (

        <Box component="form" onSubmit={onNext} sx={{ mt: 2 }}>

            <TextField

                margin="normal"

                required

                fullWidth

                label="Estimated Property Value"

                name="propertyValue"

                value={loanDetails.propertyValue}

                onChange={handleChange}

                type="text"

                sx={textFieldStyle}

                helperText="Enter the estimated value of the property."

            />

            {cashOutDetails.hasMortgage === 'yes' && (

                <TextField

                    margin="normal"

                    required

                    fullWidth

                    label="Current Mortgage Balance"

                    name="mortgageBalance"

                    value={loanDetails.mortgageBalance}

                    onChange={handleChange}

                    type="text"

                    sx={textFieldStyle}

                    helperText="Enter the current mortgage balance."

                />

            )}

            <Box sx={{ mt: 2 }}>

                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%', maxWidth: '500px', textAlign: 'center' }}>

                    <Typography variant="h6">Estimated Equity Available</Typography>

                    <Typography variant="h4" sx={{ mt: 1, mb: 2 }}>

                        {formatCurrency(parseCurrency(loanDetails.propertyValue || '0') - parseCurrency(loanDetails.mortgageBalance || '0'))}

                    </Typography>

                    <Typography variant="h6">Max Loan Amount at 80% LTV</Typography>

                    <Typography variant="h4" sx={{ mt: 1, mb: 2 }}>

                        {formatCurrency(maxLoanAmount)}

                    </Typography>

                    <Typography variant="h6">Potential Cash Out at 80% LTV</Typography>

                    <Typography variant="h4" sx={{ mt: 1, mb: 2 }}>

                        {formatCurrency(potentialCashOut)}

                    </Typography>

                    <Typography variant="body1">

                        Based on the information you provided, you have {formatCurrency(parseCurrency(loanDetails.propertyValue || '0') - parseCurrency(loanDetails.mortgageBalance || '0'))} in estimated equity. If you want to take out the maximum loan amount at 80% LTV, you could get a loan of {formatCurrency(maxLoanAmount)}.

                    </Typography>

                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>

                        This cash-out amount could change depending on multiple factors like debt to income ratio and the loan program used and is just an estimate based on the numbers you entered.

                    </Typography>

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Requested Cash Out Amount"

                        value={requestedAmount}

                        onChange={handleRequestedAmountChange}

                        type="text"

                        sx={textFieldStyle}

                        helperText="Enter the amount of cash out you are considering."

                    />

                    {requestedAmount && (

                        <Box sx={{ mt: 2 }}>

                            <Typography variant="h6">Requested Loan Amount</Typography>

                            <Typography variant="h4" sx={{ mt: 1, mb: 2 }}>

                                {formatCurrency(requestedLoanAmount)}

                            </Typography>

                            <Typography variant="h6">Requested Loan to Value (LTV)</Typography>

                            <Typography variant="h4" sx={{ mt: 1, mb: 2 }}>

                                {requestedLTV.toFixed(2)}%

                            </Typography>

                        </Box>

                    )}

                </Paper>

            </Box>

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, ...buttonStyle }}>

                Next

            </Button>

        </Box>

    );

}


function InContractDetails({ onNext, contractDetails, setContractDetails }) {

    const handleChange = (e) => {

        const { name, value } = e.target;

        setContractDetails(prev => ({ ...prev, [name]: value }));

    };


    return (

        <Box component="form" onSubmit={onNext} sx={{ mt: 2 }}>

            <TextField

                margin="normal"

                required

                fullWidth

                label="Property Address"

                name="address"

                value={contractDetails.address}

                onChange={handleChange}

                type="text"

                sx={textFieldStyle}

                helperText="Enter the property address."

            />

            <TextField

                margin="normal"

                required

                fullWidth

                label="Contract Start Date"

                name="contractStartDate"

                value={contractDetails.contractStartDate}

                onChange={handleChange}

                type="date"

                sx={textFieldStyle}

                InputLabelProps={{ shrink: true }}

            />

            <TextField

                margin="normal"

                required

                fullWidth

                label="Closing Date"

                name="closingDate"

                value={contractDetails.closingDate}

                onChange={handleChange}

                type="date"

                sx={textFieldStyle}

                InputLabelProps={{ shrink: true }}

            />

            <TextField

                margin="normal"

                required

                fullWidth

                label="Realtor Information"

                name="realtorInfo"

                value={contractDetails.realtorInfo}

                onChange={handleChange}

                type="text"

                sx={textFieldStyle}

                helperText="Enter the realtor's information."

            />

            <TextField

                margin="normal"

                required

                fullWidth

                label="Title/Escrow Information"

                name="titleEscrowInfo"

                value={contractDetails.titleEscrowInfo}

                onChange={handleChange}

                type="text"

                sx={textFieldStyle}

                helperText="Enter the title/escrow information."

            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, ...buttonStyle }}>

                Next

            </Button>

        </Box>

    );

}


function PreQualifiedDetails({ onNext, preQualifiedDetails, setPreQualifiedDetails }) {

    const handleChange = (e) => {

        const { name, value } = e.target;

        setPreQualifiedDetails(prev => ({ ...prev, [name]: value }));

    };


    return (

        <Box component="form" onSubmit={onNext} sx={{ mt: 2 }}>

            <TextField

                margin="normal"

                required

                fullWidth

                label="Zip Code"

                name="zipCode"

                value={preQualifiedDetails.zipCode}

                onChange={handleChange}

                type="text"

                sx={textFieldStyle}

                helperText="Enter the zip code of the property."

            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, ...buttonStyle }}>

                Next

            </Button>

        </Box>

    );

}


export default function LoanApplication() {

    const [step, setStep] = useState(1);

    const [steps, setSteps] = useState(['Welcome']);

    const [completedSteps, setCompletedSteps] = useState([]);

    const [selectedOptions, setSelectedOptions] = useState({});

    const [selectedButton, setSelectedButton] = useState('');

    const [showMoreOptions, setShowMoreOptions] = useState(false);

    const [showRefinanceOptions, setShowRefinanceOptions] = useState(false);

    const [showButtons, setShowButtons] = useState(false);

    const [showContactForm, setShowContactForm] = useState(false);

    const [showFloatingDashboard, setShowFloatingDashboard] = useState(false);

    const [showNavigationPanel, setShowNavigationPanel] = useState(false);

    const [contactInfo, setContactInfo] = useState({

        firstName: '',

        lastName: '',

        email: '',

        cellphone: '',

        textUpdates: false,

    });

    const [loanType, setLoanType] = useState('');

    const [refinanceType, setRefinanceType] = useState('');

    const [occupancyType, setOccupancyType] = useState('');

    const [propertyType, setPropertyType] = useState('');

    const [propertyStage, setPropertyStage] = useState('');

    const [contractDetails, setContractDetails] = useState({

        address: '',

        contractStartDate: '',

        closingDate: '',

        realtorInfo: '',

        titleEscrowInfo: '',

    });

    const [preQualifiedDetails, setPreQualifiedDetails] = useState({

        zipCode: '',

    });

    const [loanDetails, setLoanDetails] = useState({

        loanPurpose: '',

        purchasePrice: '',

        downPayment: '',

        mortgageAmount: '',

        occupancy: '',

        propertyType: '',

        propertyValue: '',

        mortgageBalance: '',

    });

    const [cashOutDetails, setCashOutDetails] = useState({

        hasMortgage: '',

    });

    const [incomeDetails, setIncomeDetails] = useState({

        employmentStatus: '',

        employerName: '',

        annualIncome: '',

        additionalIncome: '',

    });

    const [assetDetails, setAssetDetails] = useState({

        bankName: '',

        accountType: '',

        balance: '',

        otherAssets: '',

    });

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate();


    const touchStartXRef = useRef(null);


    useEffect(() => {

        const timer = setTimeout(() => {

            setShowButtons(true);

        }, 2000);

        return () => clearTimeout(timer);

    }, []);


    const addStep = useCallback((newStep) => {

        setSteps((prev) => {

            if (!prev.includes(newStep)) return [...prev, newStep];

            return prev.slice(0, prev.indexOf(newStep) + 1);

        });

    }, []);


    const markStepAsCompleted = useCallback((step) => {

        setCompletedSteps((prev) => {

            if (!prev.includes(step)) return [...prev, step];

            return prev;

        });

    }, []);


    const handleContactChange = useCallback((e) => {

        const { name, value } = e.target;

        setContactInfo((prev) => ({

            ...prev,

            [name]: value,

        }));

    }, []);


    const handleContactCheckboxChange = useCallback((e) => {

        const { name, checked } = e.target;

        setContactInfo((prev) => ({

            ...prev,

            [name]: checked,

        }));

    }, []);


    const handleContactNext = useCallback((e) => {

        e.preventDefault();

        addStep('Contact');

        markStepAsCompleted(2);

        if (loanType === 'Purchase a Home') {

            setStep(3);

        } else {

            setStep(3);

        }

    }, [addStep, markStepAsCompleted, loanType]);


    const handleOccupancySelect = useCallback((type) => {

        setOccupancyType(type);

        setSelectedOptions((prev) => ({

            ...prev,

            occupancy: type,

        }));

        setLoanDetails((prev) => ({

            ...prev,

            occupancy: type,

        }));

        addStep('Occupancy');

        markStepAsCompleted(4);

        if (loanType === 'Purchase a Home') {

            setStep(4);

        } else {

            setStep(5);

        }

    }, [addStep, markStepAsCompleted, loanType]);


    const handlePropertyTypeSelect = useCallback((type) => {

        setPropertyType(type);

        setSelectedOptions((prev) => ({

            ...prev,

            propertyType: type,

        }));

        setLoanDetails((prev) => ({

            ...prev,

            propertyType: type,

        }));

        addStep('Property');

        markStepAsCompleted(5);

        if (loanType === 'Purchase a Home') {

            setStep(5);

        } else {

            setStep(6);

        }

    }, [addStep, markStepAsCompleted, loanType]);


    const handleLoanTypeSelection = useCallback((loanType) => {

        setLoanType(loanType);

        setSelectedButton(loanType);

        setShowContactForm(true);

        setShowNavigationPanel(true);

        addStep('Contact');

        setStep(2);

    }, [addStep]);


    const handleRefinanceSelection = useCallback(() => {

        setLoanType('Refinance a Home');

        setSelectedButton('Refinance a Home');

        setShowContactForm(true);

        setShowNavigationPanel(true);

        setShowRefinanceOptions(false);

        addStep('Contact');

        setStep(2);

    }, [addStep]);


    const handleRefinanceTypeSelection = useCallback((type) => {

        setRefinanceType(type);

        setSelectedOptions((prev) => ({

            ...prev,

            refinanceType: type,

        }));

        setShowRefinanceOptions(false);

        addStep('Refi Type');

        markStepAsCompleted(3);

        setStep(4);

    }, [addStep, markStepAsCompleted]);


    const handleExistingUserLogin = () => {

        navigate('/login');

    };


    const handleNavigateToStep = useCallback((index) => {

        const stepsConfig = loanType === 'Purchase a Home' ? purchaseStepsConfig : refinanceStepsConfig;

        const stepName = stepsConfig[index].label;

        setStep(index + 1);

        switch (stepName) {

            case 'Welcome':

                setShowContactForm(false);

                setShowMoreOptions(false);

                setShowRefinanceOptions(false);

                setShowFloatingDashboard(false);

                setShowNavigationPanel(false);

                break;

            case 'Contact':

                setShowContactForm(true);

                setShowRefinanceOptions(false);

                break;

            case 'Refi Type':

                setShowContactForm(false);

                setShowRefinanceOptions(true);

                break;

            case 'Occupancy':

                setShowContactForm(false);

                setShowRefinanceOptions(false);

                break;

            case 'Property':

                setShowContactForm(false);

                setShowRefinanceOptions(false);

                break;

            case 'Contract Info':

                setShowContactForm(false);

                setShowRefinanceOptions(false);

                break;

            case 'Cash Out Info':

                setShowContactForm(false);

                setShowRefinanceOptions(false);

                break;

            case 'Loan Info':

                setShowContactForm(false);

                setShowRefinanceOptions(false);

                break;

            default:

                break;

        }

    }, [loanType]);


    const handlePropertySelection = (selection) => {

        setPropertyStage(selection);

        if (selection === 'inContract') {

            setStep(5);

        } else {

            setStep(5);

        }

    };


    const renderInitialButtons = () => (

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>

            <Button

                variant="contained"

                startIcon={<HomeIcon />}

                sx={selectedButton === 'Purchase a Home' ? selectedButtonStyle : buttonStyle}

                onClick={() => handleLoanTypeSelection('Purchase a Home')}

            >

                Purchase a Home

            </Button>

            <Button

                variant="contained"

                startIcon={<MonetizationOnIcon />}

                sx={selectedButton === 'Refinance a Home' ? selectedButtonStyle : buttonStyle}

                onClick={handleRefinanceSelection}

            >

                Refinance a Home

            </Button>

        </Box>

    );


    const renderContactForm = () => (

        <Grow in={true} timeout={1000}>

            <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '500px', mt: 4 }}>

                <Typography component="h1" variant="h5" align="center">

                    Contact Information

                </Typography>

                <Box component="form" onSubmit={handleContactNext} sx={{ mt: 2 }}>

                    <TextField

                        margin="normal"

                        required

                        fullWidth

                        id="firstName"

                        label="First Name"

                        name="firstName"

                        autoComplete="fname"

                        autoFocus

                        value={contactInfo.firstName}

                        onChange={handleContactChange}

                        sx={textFieldStyle}

                        helperText="Please enter your first name."

                    />

                    <TextField

                        margin="normal"

                        required

                        fullWidth

                        id="lastName"

                        label="Last Name"

                        name="lastName"

                        autoComplete="lname"

                        value={contactInfo.lastName}

                        onChange={handleContactChange}

                        sx={textFieldStyle}

                        helperText="Please enter your last name."

                    />

                    <TextField

                        margin="normal"

                        required

                        fullWidth

                        id="email"

                        label="Email Address"

                        name="email"

                        autoComplete="email"

                        value={contactInfo.email}

                        onChange={handleContactChange}

                        sx={textFieldStyle}

                        helperText="Enter your email address."

                    />

                    <TextField

                        margin="normal"

                        required

                        fullWidth

                        id="cellphone"

                        label="Cell Phone"

                        name="cellphone"

                        autoComplete="tel"

                        value={contactInfo.cellphone}

                        onChange={handleContactChange}

                        sx={textFieldStyle}

                        helperText="Enter your cell phone number."

                    />

                    <FormControlLabel

                        control={

                            <Checkbox

                                checked={contactInfo.textUpdates}

                                onChange={handleContactCheckboxChange}

                                name="textUpdates"

                                color="primary"

                            />

                        }

                        label="Send me text updates about my loan"

                    />

                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, ...buttonStyle }}>

                        Next

                    </Button>

                </Box>

            </Paper>

        </Grow>

    );


    const renderRefinanceOptions = () => (

        <Grow in={true} timeout={1000}>

            <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '500px', mt: 4 }}>

                <Typography component="h1" variant="h5" align="center">

                    Refinance Type

                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, flexWrap: 'wrap' }}>

                    <Button

                        variant="contained"

                        sx={refinanceType === 'Rate Term Refinance' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handleRefinanceTypeSelection('Rate Term Refinance')}

                    >

                        Rate Term Refinance

                    </Button>

                    <Button

                        variant="contained"

                        sx={refinanceType === 'Cash Out Refinance' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handleRefinanceTypeSelection('Cash Out Refinance')}

                    >

                        Cash Out Refinance

                    </Button>

                </Box>

            </Paper>

        </Grow>

    );


    const renderOccupancyOptions = () => (

        <Grow in={true} timeout={1000}>

            <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '500px', mt: 4 }}>

                <Typography component="h1" variant="h5" align="center">

                    Occupancy Type

                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, flexWrap: 'wrap' }}>

                    <Button

                        variant="contained"

                        sx={occupancyType === 'Primary Residence' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handleOccupancySelect('Primary Residence')}

                    >

                        Primary Residence

                    </Button>

                    <Button

                        variant="contained"

                        sx={occupancyType === 'Secondary Home' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handleOccupancySelect('Secondary Home')}

                    >

                        Secondary Home

                    </Button>

                    <Button

                        variant="contained"

                        sx={occupancyType === 'Investment Property' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handleOccupancySelect('Investment Property')}

                    >

                        Investment Property

                    </Button>

                </Box>

            </Paper>

        </Grow>

    );


    const renderPropertyTypeOptions = () => (

        <Grow in={true} timeout={1000}>

            <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '500px', mt: 4 }}>

                <Typography component="h1" variant="h5" align="center">

                    Property Type

                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, flexWrap: 'wrap' }}>

                    <Button

                        variant="contained"

                        sx={propertyType === 'Single Family Home' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handlePropertyTypeSelect('Single Family Home')}

                    >

                        Single Family Home

                    </Button>

                    <Button

                        variant="contained"

                        sx={propertyType === 'Condominium' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handlePropertyTypeSelect('Condominium')}

                    >

                        Condominium

                    </Button>

                    <Button

                        variant="contained"

                        sx={propertyType === 'Townhouse' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handlePropertyTypeSelect('Townhouse')}

                    >

                        Townhouse

                    </Button>

                    <Button

                        variant="contained"

                        sx={propertyType === 'Multi-Family Home' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handlePropertyTypeSelect('Multi-Family Home')}

                    >

                        Multi-Family Home

                    </Button>

                    <Button

                        variant="contained"

                        sx={propertyType === 'Manufactured Home' ? selectedButtonStyle : buttonStyle}

                        onClick={() => handlePropertyTypeSelect('Manufactured Home')}

                    >

                        Manufactured Home

                    </Button>

                </Box>

            </Paper>

        </Grow>

    );


    const renderPropertyStage = () => {

        if (propertyStage === 'inContract') {

            return <InContractDetails onNext={handleNextStep} contractDetails={contractDetails} setContractDetails={setContractDetails} />;

        } else if (propertyStage === 'preQualified') {

            return <PreQualifiedDetails onNext={handleNextStep} preQualifiedDetails={preQualifiedDetails} setPreQualifiedDetails={setPreQualifiedDetails} />;

        }

        return null;

    };


    const renderLoanAndPropertyInfo = () => (

        <Grow in={true} timeout={1000}>

            <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '500px', mt: 4 }}>

                <Typography component="h1" variant="h5" align="center">

                    Loan and Property Info

                </Typography>

                <Box sx={{ mt: 2 }}>

                    {loanType === 'Purchase a Home' && (

                        <>

                            <TextField

                                margin="normal"

                                fullWidth

                                label="Purchase Price"

                                name="purchasePrice"

                                value={loanDetails.purchasePrice}

                                onChange={handleLoanDetailsChange}

                                type="number"

                                sx={textFieldStyle}

                                helperText="Enter the purchase price of the property."

                            />

                            <TextField

                                margin="normal"

                                fullWidth

                                label="Down Payment"

                                name="downPayment"

                                value={loanDetails.downPayment}

                                onChange={handleLoanDetailsChange}

                                type="number"

                                sx={textFieldStyle}

                                helperText="Enter the amount of your down payment."

                            />

                        </>

                    )}

                    {loanType === 'Refinance a Home' && refinanceType === 'Cash Out Refinance' && (

                        <CashOutLoanInfo

                            onNext={handleNextStep}

                            loanDetails={loanDetails}

                            setLoanDetails={setLoanDetails}

                            cashOutDetails={cashOutDetails}

                        />

                    )}

                </Box>

            </Paper>

        </Grow>

    );


    const renderIncomeDetails = () => (

        <Grow in={true} timeout={1000}>

            <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '500px', mt: 4 }}>

                <Typography component="h1" variant="h5" align="center">

                    Income Details

                </Typography>

                <Box sx={{ mt: 2 }}>

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Employment Status"

                        name="employmentStatus"

                        value={incomeDetails.employmentStatus}

                        onChange={handleIncomeDetailsChange}

                        sx={textFieldStyle}

                        helperText="Enter your current employment status."

                    />

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Employer Name"

                        name="employerName"

                        value={incomeDetails.employerName}

                        onChange={handleIncomeDetailsChange}

                        sx={textFieldStyle}

                        helperText="Enter the name of your employer."

                    />

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Annual Income"

                        name="annualIncome"

                        value={incomeDetails.annualIncome}

                        onChange={handleIncomeDetailsChange}

                        type="number"

                        sx={textFieldStyle}

                        helperText="Enter your annual income."

                    />

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Additional Income (if any)"

                        name="additionalIncome"

                        value={incomeDetails.additionalIncome}

                        onChange={handleIncomeDetailsChange}

                        type="number"

                        sx={textFieldStyle}

                        helperText="Enter any additional income."

                    />

                </Box>

            </Paper>

        </Grow>

    );


    const renderAssetDetails = () => (

        <Grow in={true} timeout={1000}>

            <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '500px', mt: 4 }}>

                <Typography component="h1" variant="h5" align="center">

                    Asset Details

                </Typography>

                <Box sx={{ mt: 2 }}>

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Bank Name"

                        name="bankName"

                        value={assetDetails.bankName}

                        onChange={handleAssetDetailsChange}

                        sx={textFieldStyle}

                        helperText="Enter the name of your bank."

                    />

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Account Type"

                        name="accountType"

                        value={assetDetails.accountType}

                        onChange={handleAssetDetailsChange}

                        sx={textFieldStyle}

                        helperText="Enter the type of your account (e.g., checking, savings)."

                    />

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Balance"

                        name="balance"

                        value={assetDetails.balance}

                        onChange={handleAssetDetailsChange}

                        type="number"

                        sx={textFieldStyle}

                        helperText="Enter the current balance of your account."

                    />

                    <TextField

                        margin="normal"

                        fullWidth

                        label="Other Assets (if any)"

                        name="otherAssets"

                        value={assetDetails.otherAssets}

                        onChange={handleAssetDetailsChange}

                        sx={textFieldStyle}

                        helperText="Enter details of any other assets you own."

                    />

                </Box>

            </Paper>

        </Grow>

    );


    const handleIncomeDetailsChange = useCallback((e) => {

        const { name, value } = e.target;

        setIncomeDetails((prev) => ({

            ...prev,

            [name]: value,

        }));

    }, []);


    const handleAssetDetailsChange = useCallback((e) => {

        const { name, value } = e.target;

        setAssetDetails((prev) => ({

            ...prev,

            [name]: value,

        }));

    }, []);


    const handleLoanDetailsChange = useCallback((e) => {

        const { name, value } = e.target;

        setLoanDetails((prev) => ({

            ...prev,

            [name]: value,

        }));

    }, []);


    const handleSubmit = useCallback((e) => {

        e.preventDefault();

        console.log('Loan Details:', loanDetails);

        console.log('Income Details:', incomeDetails);

        console.log('Asset Details:', assetDetails);

    }, [loanDetails, incomeDetails, assetDetails]);


    const handleNextStep = () => {

        markStepAsCompleted(step);

        setStep(prev => prev + 1);

    };


    const handleTouchStart = (e) => {

        touchStartXRef.current = e.touches[0].clientX;

    };


    const handleTouchMove = (e) => {

        const touchMoveX = e.touches[0].clientX;

        const touchStartX = touchStartXRef.current;

        if (touchStartX && touchMoveX) {

            const diffX = touchMoveX - touchStartX;

            if (diffX > 50) {

                handleNavigateToStep(step - 2); // Navigate to the previous step

            } else if (diffX < -50) {

                handleNavigateToStep(step); // Navigate to the next step

            }

        }

    };


    return (

        <Container

            component="main"

            maxWidth="md"

            sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}

            onTouchStart={handleTouchStart}

            onTouchMove={handleTouchMove}

        >

            <CssBaseline />

            <Box sx={{ mt: isSmallScreen ? 2 : 4, display: 'flex', justifyContent: 'center' }}>

                <img

                    src={renewLogo}

                    alt="Renew Lending Logo"

                    style={{

                        width: isSmallScreen ? '200px' : '300px',

                        animation: `${shimmer} 2s linear`,

                        transition: 'margin-bottom 1s ease',

                        cursor: 'pointer',

                    }}

                    onClick={() => navigate('/')}

                />

            </Box>

            {showFloatingDashboard && (

                <LoanSummary steps={steps} contactInfo={contactInfo} loanType={loanType} onNavigate={handleNavigateToStep} />

            )}

            <Box sx={{ mt: isSmallScreen ? 2 : 4, flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                {step === 1 && !showContactForm && (

                    <Grow in={true} timeout={2000}>

                        <Paper sx={{ p: 2, mt: 2, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                            <Typography variant="h5" sx={{ mt: 1, mb: 2, textAlign: 'center' }}>

                                Welcome to <strong>ReNew Lending</strong>

                            </Typography>

                            <Typography sx={{ mb: 4, textAlign: 'center' }}>

                                "Congratulations! You've Found Your New Way Home with ReNew"

                            </Typography>

                            {renderInitialButtons()}

                            <Button

                                variant="outlined"

                                sx={{

                                    mt: 4,

                                    fontSize: '1rem',

                                    padding: '12px 24px',

                                    borderColor: tealColor,

                                    color: tealColor,

                                    '&:hover': { borderColor: '#358b91', color: '#358b91' },

                                }}

                                onClick={handleExistingUserLogin}

                            >

                                Existing User Login

                            </Button>

                        </Paper>

                    </Grow>

                )}

                {showContactForm && step === 2 && renderContactForm()}

                {loanType === 'Refinance a Home' && step === 3 && renderRefinanceOptions()}

                {loanType === 'Purchase a Home' && step === 3 && renderOccupancyOptions()}

                {loanType === 'Purchase a Home' && step === 4 && (

                    <Grow in={true} timeout={1000}>

                        <Paper elevation={3} sx={{ p: 2, width: '100%', maxWidth: '500px', mt: 4 }}>

                            <Typography component="h1" variant="h5" align="center">

                                Property Stage

                            </Typography>

                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, flexWrap: 'wrap' }}>

                                <Button

                                    variant="contained"

                                    sx={propertyStage === 'inContract' ? selectedButtonStyle : buttonStyle}

                                    onClick={() => handlePropertySelection('inContract')}

                                >

                                    In Contract

                                </Button>

                                <Button

                                    variant="contained"

                                    sx={propertyStage === 'preQualified' ? selectedButtonStyle : buttonStyle}

                                    onClick={() => handlePropertySelection('preQualified')}

                                >

                                    Get Pre-Qualified

                                </Button>

                            </Box>

                        </Paper>

                    </Grow>

                )}

                {loanType === 'Purchase a Home' && step === 5 && renderPropertyStage()}

                {loanType === 'Refinance a Home' && step === 4 && renderOccupancyOptions()}

                {loanType === 'Refinance a Home' && step === 5 && renderPropertyTypeOptions()}

                {loanType === 'Refinance a Home' && step === 6 && (

                    <CashOutInfo

                        onNext={handleNextStep}

                        cashOutDetails={cashOutDetails}

                        setCashOutDetails={setCashOutDetails}

                        setStep={setStep}

                    />

                )}

                {loanType === 'Refinance a Home' && step === 7 && renderLoanAndPropertyInfo()}

                {loanType === 'Purchase a Home' && step === 6 && renderLoanAndPropertyInfo()}

                {loanType === 'Purchase a Home' && step === 7 && renderIncomeDetails()}

                {loanType === 'Refinance a Home' && step === 8 && renderIncomeDetails()}

                {loanType === 'Purchase a Home' && step === 8 && renderAssetDetails()}

                {loanType === 'Refinance a Home' && step === 9 && renderAssetDetails()}

                {showNavigationPanel && (

                    <NavigationPanel stepsConfig={loanType === 'Purchase a Home' ? purchaseStepsConfig : refinanceStepsConfig} currentStep={step} completedSteps={completedSteps} onNavigate={handleNavigateToStep} />

                )}

            </Box>

            <Typography variant="body2" color="textSecondary" align="center" sx={{ pb: 2 }}>

                © {new Date().getFullYear()} ReNew Lending

                <Link

                    href="#"

                    onClick={(e) => {

                        e.preventDefault();

                        navigate('/');

                    }}

                    sx={{ ml: 2, color: tealColor }}

                >

                    Terms of Service

                </Link>

                <Link

                    href="#"

                    onClick={(e) => {

                        e.preventDefault();

                        navigate('/');

                    }}

                    sx={{ ml: 2, color: tealColor }}

                >

                    Privacy Policy

                </Link>

            </Typography>

        </Container>

    );

}

