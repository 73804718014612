import React from 'react';

import {

  Paper, Box, TextField, MenuItem, InputAdornment, Button, Collapse, Grid, IconButton

} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import FilterListIcon from '@mui/icons-material/FilterList';

import { styled } from '@mui/material/styles';


const tealColor = '#3AAFA9';


const PaperStyled = styled(Paper)({

  padding: '8px',

  marginBottom: '16px',

  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',

});


const AdvancedFilter = ({

  filter, handleFilterChange, handleResetFilters, handleToggleAdvancedFilters,

  showAdvancedFilters, searchQuery, handleSearchChange, sortConfig, handleSortChange,

  handleCreateLead

}) => {


  const handleDispositionChange = (e) => {

    const { name, value } = e.target;

    let updatedValue = value;


    if (value === 'New Lead') {

      updatedValue = null;

    }


    handleFilterChange({

      target: {

        name,

        value: updatedValue,

      }

    });

  };


  const handleCallAttemptChange = (e) => {

    const { name, value } = e.target;


    handleFilterChange({

      target: {

        name,

        value: value,

      }

    });

  };


  return (

    <PaperStyled>

      <Grid container spacing={1} alignItems="center">

        <Grid item xs={6} md={5}>

          <TextField

            label="Search Leads"

            value={searchQuery}

            onChange={handleSearchChange}

            fullWidth

            InputProps={{

              endAdornment: (

                <InputAdornment position="end">

                  <SearchIcon />

                </InputAdornment>

              ),

            }}

            sx={{ fontSize: '0.875rem' }}

          />

        </Grid>

        <Grid item xs={2} md={1}>

          <IconButton onClick={handleToggleAdvancedFilters} sx={{ color: tealColor }}>

            <FilterListIcon />

          </IconButton>

        </Grid>

        <Grid item xs={4} md={6}>

          <Button variant="contained" color="primary" onClick={handleCreateLead} fullWidth sx={{ backgroundColor: tealColor, fontSize: '0.875rem' }}>

            Create Lead

          </Button>

        </Grid>

      </Grid>


      <Collapse in={showAdvancedFilters}>

        <Box mt={1}>

          <Grid container spacing={1}>

            <Grid item xs={12} md={6}>

              <TextField

                label="Sort Order"

                value={sortConfig.field}

                onChange={(e) => handleSortChange(e.target.value)}

                select

                fullWidth

                sx={{ fontSize: '0.875rem' }}

              >

                <MenuItem value="createdAt">Newest First</MenuItem>

                <MenuItem value="first_name">First Name</MenuItem>

                <MenuItem value="last_name">Last Name</MenuItem>

                <MenuItem value="premium_phone">Phone Number</MenuItem>

                <MenuItem value="callAttempts">Last Disposition</MenuItem>

                <MenuItem value="state">State</MenuItem>

                <MenuItem value="lead_source">Lead Source</MenuItem>

                <MenuItem value="lastDisposition">Status</MenuItem>

                <MenuItem value="assigned_loan_officer">Assigned Loan Officer</MenuItem>

              </TextField>

            </Grid>

            <Grid item xs={12} md={6}>

              <TextField

                label="Lead Source"

                name="leadSource"

                value={filter.leadSource}

                onChange={handleFilterChange}

                select

                fullWidth

                sx={{ fontSize: '0.875rem' }}

              >

                <MenuItem value="">Select Lead Source</MenuItem>

                <MenuItem value="MRC">MRC</MenuItem>

              </TextField>

            </Grid>

            <Grid item xs={12} md={6}>

              <TextField

                label="Disposition"

                name="disposition"

                value={filter.disposition}

                onChange={handleDispositionChange}

                select

                fullWidth

                sx={{ fontSize: '0.875rem' }}

              >

                <MenuItem value="">Select Disposition</MenuItem>

                <MenuItem value="DNC">DNC</MenuItem>

                <MenuItem value="Not interested">Not interested</MenuItem>

                <MenuItem value="Does not qualify (credit, LTV, BK, late pay)">Does not qualify (credit, LTV, BK, late pay)</MenuItem>

                <MenuItem value="No net benefit at this time">No net benefit at this time</MenuItem>

                <MenuItem value="Just refinanced, 12 months call back">Just refinanced, 12 months call back</MenuItem>

                <MenuItem value="Not Contacted">Not Contacted</MenuItem>

                <MenuItem value="Call Attempt">Call Attempt</MenuItem>

                <MenuItem value="Contacted">Contacted</MenuItem>

                <MenuItem value="Credit Pulled">Credit Pulled</MenuItem>

                <MenuItem value="Application Sent">Application Sent</MenuItem>

                <MenuItem value="Application Started">Application Started</MenuItem>

                <MenuItem value="Application Completed">Application Completed</MenuItem>

                <MenuItem value="Loan In Process">Loan In Process</MenuItem>

                <MenuItem value="Caller Returned Call">Caller Returned Call</MenuItem>

                <MenuItem value="New Lead">New Lead</MenuItem>

              </TextField>

            </Grid>

            <Grid item xs={12} md={6}>

              <TextField

                label="Call Attempts"

                name="callAttempts"

                value={filter.callAttempts}

                onChange={handleCallAttemptChange}

                select

                fullWidth

                sx={{ fontSize: '0.875rem' }}

              >

                <MenuItem value="">Select Call Attempts</MenuItem>

                <MenuItem value="yes">Has Call Attempts</MenuItem>

                <MenuItem value="no">No Call Attempts</MenuItem>

              </TextField>

            </Grid>

            <Grid item xs={12} md={6}>

              <TextField

                label="Created Time"

                name="createdTime"

                value={filter.createdTime}

                onChange={handleFilterChange}

                select

                fullWidth

                sx={{ fontSize: '0.875rem' }}

              >

                <MenuItem value="">Select Created Time</MenuItem>

                <MenuItem value={1}>Last 1 Hour</MenuItem>

                <MenuItem value={8}>Last 8 Hours</MenuItem>

                <MenuItem value={24}>Last 1 Day</MenuItem>

                <MenuItem value={168}>Last 7 Days</MenuItem>

                <MenuItem value={336}>Last 14 Days</MenuItem>

                <MenuItem value={720}>Last 30 Days</MenuItem>

                <MenuItem value={2160}>Last 90 Days</MenuItem>

              </TextField>

            </Grid>

            <Grid item xs={12} md={6}>

              <TextField

                label="State"

                name="state"

                value={filter.state}

                onChange={handleFilterChange}

                select

                fullWidth

                sx={{ fontSize: '0.875rem' }}

              >

                <MenuItem value="">Select State</MenuItem>

                <MenuItem value="AZ">Arizona</MenuItem>

                <MenuItem value="NV">Nevada</MenuItem>

                <MenuItem value="CA">California</MenuItem>

                <MenuItem value="TX">Texas</MenuItem>

                <MenuItem value="UT">Utah</MenuItem>

                <MenuItem value="FL">Florida</MenuItem>

                <MenuItem value="CO">Colorado</MenuItem>

                <MenuItem value="MO">Missouri</MenuItem>

                <MenuItem value="SC">South Carolina</MenuItem>

                <MenuItem value="WA">Washington</MenuItem>

              </TextField>

            </Grid>

            <Grid item xs={12} md={6}>

              <Button variant="contained" onClick={handleResetFilters} fullWidth sx={{ backgroundColor: tealColor, color: '#fff', fontSize: '0.875rem' }}>

                Reset Filters

              </Button>

            </Grid>

          </Grid>

        </Box>

      </Collapse>

    </PaperStyled>

  );

};


export default AdvancedFilter;

