import React, { useState } from 'react';

import renewlogo from './renew-lending-logo.png';

import axios from 'axios';

import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { useAuth } from './AuthContext';

import {

  Button,

  TextField,

  InputAdornment,

  Container,

  Typography,

  Alert,

  Box,

  CssBaseline,

  Grid,

  Paper,

  Link as MuiLink,

  IconButton,

} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';


const MeridianLinkLoginComponent = () => {

  const navigate = useNavigate();

  const { login } = useAuth();

  const [credentials, setCredentials] = useState({ username: '', password: '' });

  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState('');


  const handleChange = (event) => {

    const { name, value } = event.target;

    setCredentials(prev => ({ ...prev, [name]: value }));

  };


  const handleClickShowPassword = () => {

    setShowPassword(!showPassword);

  };


  const handleMouseDownPassword = (event) => {

    event.preventDefault();

  };


  const handleSubmit = async (event) => {

    event.preventDefault();

    try {

      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/login`;

      const response = await axios.post(apiUrl, credentials);

      login(response.data.token);

      navigate('/dashboard');

    } catch (error) {

      setLoginError(error.response?.data?.message || 'Login failed. Please check your credentials and try again.');

    }

  };


  return (

    <Container component="main" maxWidth={false} sx={{ backgroundColor: '#333333', minHeight: '100vh', padding: 0 }}>

      <CssBaseline />

      <Box

        sx={{

          display: 'flex',

          flexDirection: 'column',

          alignItems: 'center',

          justifyContent: 'center',

          minHeight: '100vh',

        }}

      >

        <img src={renewlogo} alt="Renew Lending Logo" style={{ marginTop: 20, maxWidth: '80%', height: 'auto' }} />

        <Paper sx={{ p: 2, mt: 2, mb: 2, width: '90%', maxWidth: 400, bgcolor: 'background.paper' }} elevation={2}>

          <Box sx={{ my: 4, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Typography component="h1" variant="h5">

              Meridian Link Login

            </Typography>

            {loginError && <Alert severity="error" sx={{ mt: 1, mb: 1 }}>{loginError}</Alert>}

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

              <Typography variant="subtitle1" sx={{ mt: 1 }}>Username</Typography>

              <TextField

                margin="normal"

                required

                fullWidth

                id="username"

                name="username"

                autoComplete="username"

                autoFocus

                variant="outlined"

                onChange={handleChange}

                sx={{ my: 1 }} // Reduced vertical margin

              />

              <Typography variant="subtitle1" sx={{ mt: 1 }}>Password</Typography>

              <TextField

                margin="normal"

                required

                fullWidth

                name="password"

                type={showPassword ? 'text' : 'password'}

                id="password"

                autoComplete="current-password"

                variant="outlined"

                onChange={handleChange}

                InputProps={{

                  endAdornment: (

                    <InputAdornment position="end">

                      <IconButton

                        aria-label="toggle password visibility"

                        onClick={handleClickShowPassword}

                        onMouseDown={handleMouseDownPassword}

                        edge="end"

                      >

                        {showPassword ? <VisibilityOff /> : <Visibility />}

                      </IconButton>

                    </InputAdornment>

                  ),

                }}

                sx={{ my: 1 }} // Reduced vertical margin

              />

              <Button

                type="submit"

                fullWidth

                variant="contained"

                sx={{ mt: 2, mb: 2, bgcolor: '#48acac' }} // Adjusted button margins

              >

                Sign In

              </Button>

              <Grid container>

                <Grid item xs>

                  <MuiLink component={RouterLink} to="/forgot-password" variant="body2" sx={{ color: 'text.primary' }}>

                    Forgot password?

                  </MuiLink>

                </Grid>

                <Grid item>

                  <MuiLink component={RouterLink} to="/signup" variant="body2" sx={{ color: 'text.primary' }}>

                    Don't have an account? Sign Up

                  </MuiLink>

                </Grid>

              </Grid>

            </Box>

          </Box>

        </Paper>

        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 3, color: 'text.primary' }}>

          NMLS 187023

        </Typography>

      </Box>

    </Container>

  );

};


export default MeridianLinkLoginComponent;

