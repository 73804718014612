import React, { useState, useEffect, useContext } from 'react';

import {

  Box, Typography, Paper, Table, TableBody, TableCell, TableContainer,

  TableHead, TableRow, TextField, MenuItem, TableSortLabel

} from '@mui/material';

import axios from 'axios';

import AuthContext from '../Auth/AuthContext';

import './Manager.css';


const tealColor = '#3AAFA9';


const filterOptions = [

  { label: 'Last 1 hour', value: 1 },

  { label: 'Last 4 hours', value: 4 },

  { label: 'Last 8 hours', value: 8 },

  { label: 'Last 24 hours', value: 24 },

  { label: 'Last 48 hours', value: 48 },

  { label: 'Last 7 days', value: 7 * 24 },

  { label: 'Last 14 days', value: 14 * 24 },

  { label: 'Last 30 days', value: 30 * 24 },

  { label: 'Last 60 days', value: 60 * 24 },

];


const Manager = () => {

  const { user } = useContext(AuthContext);

  const [teamLeads, setTeamLeads] = useState([]);

  const [stats, setStats] = useState({});

  const [filter, setFilter] = useState(filterOptions[0].value);


  useEffect(() => {

    const fetchTeamLeads = async () => {

      try {

        const response = await axios.get(`/api/leads/team-leads?managerId=${user._id}&hours=${filter}`);

        setTeamLeads(response.data);


        const statsResponse = await axios.get(`/api/leads/team-stats?managerId=${user._id}&hours=${filter}`);

        setStats(statsResponse.data);

      } catch (error) {

        console.error('Error fetching team leads:', error);

      }

    };


    fetchTeamLeads();

  }, [user, filter]);


  const handleFilterChange = (event) => {

    setFilter(event.target.value);

  };


  return (

    <Box>

      <Typography variant="h4" gutterBottom>Manager Dashboard</Typography>

      <TextField

        select

        label="Filter"

        value={filter}

        onChange={handleFilterChange}

        variant="outlined"

        margin="normal"

        sx={{ marginBottom: 3, minWidth: 200 }}

      >

        {filterOptions.map((option) => (

          <MenuItem key={option.value} value={option.value}>

            {option.label}

          </MenuItem>

        ))}

      </TextField>

      {teamLeads.map((teamMember) => (

        <Box key={teamMember._id} className="team-member-section">

          <Typography variant="h6" className="team-member-title">{teamMember.name}</Typography>

          <TableContainer component={Paper}>

            <Table>

              <TableHead>

                <TableRow>

                  <TableCell>

                    <TableSortLabel>

                      Lead Name

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel>

                      Phone Number

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel>

                      Last Disposition

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel>

                      State

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel>

                      Lead Source

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel>

                      Created Date

                    </TableSortLabel>

                  </TableCell>

                  <TableCell>

                    <TableSortLabel>

                      DNC Status

                    </TableSortLabel>

                  </TableCell>

                </TableRow>

              </TableHead>

              <TableBody>

                {teamMember.leads.map((lead) => (

                  <TableRow key={lead._id}>

                    <TableCell>

                      <Typography color={tealColor}>

                        {lead.FNAME || lead.first_name} {lead.LNAME || lead.last_name}

                      </Typography>

                    </TableCell>

                    <TableCell>

                      <Typography color={tealColor}>

                        {lead.premium_phone || lead.phone_home || lead.phone_work || lead.phone_cell}

                      </Typography>

                    </TableCell>

                    <TableCell>{lead.callAttempts && lead.callAttempts.length > 0 ? lead.callAttempts[lead.callAttempts.length - 1].disposition : 'New Lead'}</TableCell>

                    <TableCell>{lead.STATE || lead.state}</TableCell>

                    <TableCell>{lead.lead_source || lead.source}</TableCell>

                    <TableCell>{new Date(lead.createdAt).toLocaleString()}</TableCell>

                    <TableCell>

                      <Typography color={lead.isDNC ? 'error' : 'primary'}>

                        {lead.isDNC ? 'DNC' : 'Clear'}

                      </Typography>

                    </TableCell>

                  </TableRow>

                ))}

              </TableBody>

            </Table>

          </TableContainer>

          <Box className="team-member-stats">

            <Typography variant="body2">Number of Leads Contacted: {stats[teamMember._id]?.contacted || 0}</Typography>

            <Typography variant="body2">Number of Leads in Application Completed: {stats[teamMember._id]?.applicationCompleted || 0}</Typography>

          </Box>

        </Box>

      ))}

    </Box>

  );

};


export default Manager;

