import React from 'react';

import { Link } from 'react-router-dom';

import './TempLandingPageTemp.css';

import logo from './Top-Logo.png'; // Make sure the logo file is in the same directory


const TempLandingPageTemp = () => {

  return (

    <div className="landing-container">

      <header className="header">

        <img src={logo} alt="Verify Solutions Logo" className="logo" />

        <a href="tel:888-958-8877" className="contact-number">888-958-8877</a>

      </header>

      <main>

        <section className="intro">

          <h1>More Than Personal Loans, Achieving Your Goals. Borrow Smart. Save Today.</h1>

          <p>

            We Are The Loan Provider, Not A Broker, By Choosing Us, You Save Money Because There Is No Middle Man Involved.

            As A First-party Lender, We Handle Everything Directly, Ensuring You Get The Best Rates And A Seamless Experience.

          </p>

          <ul>

            <li>✔️ Over 30 Years Of Financial Service Excellence</li>

            <li>✔️ 5 Stars Customer Satisfaction On Google</li>

            <li>✔️ Customized Personal Loan Plans Suit Your Financial Needs</li>

            <li>✔️ Over 5000 Family Trust Us To Support Their Financial Aspirations</li>

            <li>✔️ Wide Variety Of Loans Ranging from $7,500 to $100,000 To Help Meet Your Financial Goals</li>

          </ul>

        </section>

        <aside className="consultation-form">

          <h2>Get Your Free Consultation Today</h2>

          <form>

            <input type="text" placeholder="Your name here" required />

            <input type="email" placeholder="Your email here" required />

            <input type="tel" placeholder="Your phone number here" required />

            <textarea placeholder="Comment"></textarea>

            <div className="consent">

              <input type="checkbox" id="consent" required />

              <label htmlFor="consent">

                I consent to join the email list and receive SMS from Renew Lending, with access to our latest offers and services. Message and data rates may apply. Message frequency varies.

                More details on this are in our <Link to="/privacy-policy">Privacy Policy</Link> and <Link to="/terms">Terms of Service</Link>. Text "HELP" for help or contact us at 888-958-8877. Text "STOP" to cancel.

              </label>

            </div>

            <button type="submit">FREE CONSULTATION</button>

          </form>

        </aside>

        <section className="services">

          <h2>Customer Loves Us</h2>

          <div className="reviews">

            <p>⭐⭐⭐⭐⭐ Google</p>

            <p>⭐⭐⭐⭐⭐ Facebook</p>

          </div>

          <h2>Our Services</h2>

          <div className="service-list">

            <div className="service-item">

              <h3>Personal Loan Services</h3>

              <p>Offer competitive rates, fast approvals, and flexible repayment plans to help you maintain financial stability.</p>

            </div>

            <div className="service-item">

              <h3>Medical Expense Financing</h3>

              <p>Tackle unexpected medical bills with our assistance program, designed to create manageable payment plans.</p>

            </div>

            <div className="service-item">

              <h3>Education Funding Options</h3>

              <p>Support your educational aspirations or those of someone you care for with our education funding solutions.</p>

            </div>

          </div>

        </section>

      </main>

      <footer>

        <Link to="/privacy-policy">Privacy Policy</Link>

        <Link to="/terms">Terms of Service</Link>

      </footer>

    </div>

  );

};


export default TempLandingPageTemp;

